/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/07/23 16:15
 * @update 2019/07/23 16:15
 */

import React from 'react';
import {FormGroup, Form} from 'react-bootstrap';
import notify from 'devextreme/ui/notify';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ButtonSave from "../common/button/button-save";
import Config from '../../config/index';
import GridContainer from "../common/grid-container/grid-container";
import {Column, Paging, Pager, Lookup} from 'devextreme-react/data-grid';

import {Col, Input} from "reactstrap";
import EventTracking from "../common/tracking/event";
import * as w18f1050Actions from "../../actions/w18/w18F1050/w18F1050-actions";
import * as userActions from "../../actions/user-actions";
import ButtonCustom from "../common/button/button-custom";
import {browserHistory} from "react-router";
import PopupView from "../popup/popup-view";
import W18F1024 from "./W18F1024";
import Combo from '../common/dropdown/dropdown-new'
import getCboInfo from "../../actions/w18/w18F1050/cbinformation-async";
import getCboCondition from "../../actions/w18/w18F1050/cbcondition-async";
import GridActionBar from "../common/action-bar/grid-action-bar";
import {CheckBox} from "devextreme-react";

class W18F1051Page extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!this.getInfo()) return;
        this.state = {
            iPermission: 0,
            loading: true,
            showW18F1024: false,
            RegionID: '',
            RegionNameU: '',
            dataSalePerson: [],
            tags: [],
            suggestions: [],
            dataGrid: [],
            typeInformation: [],
            codeInformation: this.loadInformationCode.bind(this),
            typeCondition: [],
            codeCondition: [],
            isDisabled: false,
            Disabled: false,
        };
        this.timer = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 3000;
        this.clearEvents = this.clearEvents.bind(this);

        this.timer = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 2000;
    };

    clearEvents() {
        this.setState({events: []});
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    componentDidMount = async () => {
        await this.loadPermission();
        if(this.state.iPermission <= 0) return;
        if (!this.getInfo()) return;
        this.loadProvince();
        this.loadDataGrid();
        this.loadInformationType();
        this.loadInformationCode();
        this.loadConditionType();
        this.loadConditionCode();
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1053",(iPer) => {
            this.setState({iPermission: iPer});
        });
    };

    getInfo = (flag) => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('salesPersonID')) {
            return {regionID: url.get('RegionID'), mode: url.get('mode')};
        } else if (location && location.state) {
            return {
                regionID: location.state.RegionID,
                mode: location.state.mode
            }
        } else {
            if (flag) {
                browserHistory.push(Config.env.url + '/W18F1050');
                return null;
            }
            return false;
        }
    };

    loadDataGrid() {
        const {regionID} = this.getInfo();
        const param = {
            "RegionID": regionID
        };
        this.props.w18f1050Actions.loadDetailW19F1051(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data && data.master && data.master.length > 0) {
                document.getElementById("Remark").value = data.master[0].Remark;
                document.getElementById("RegionID").value = data.master[0].RegionID;
                document.getElementById("RegionNameU").value = data.master[0].RegionNameU;
                this.setState({
                    RegionID: data.master[0].RegionID,
                    RegionNameU: data.master[0].RegionNameU,
                    Disabled: !!data.master[0].Disabled,
                });

            }
            if (data && data.province && data.province.length > 0) {
                const dataProvince = data.province;
                let dataSuggestions = [];
                dataProvince.forEach(item => {
                    const itemNew = {
                        value: item.ProvinceID,
                        label: item.ProvinceNameU,
                    };
                    dataSuggestions.push(itemNew);
                });

                this.setState({
                    tags: dataSuggestions,
                });
            }
            if (data && data.salePerson && data.salePerson.length > 0) {
                this.setState({
                    dataSalePerson: data.salePerson,
                });
            }
            if (data && data.detail && data.detail.length > 0) {
                this.setState({
                    dataGrid: data.detail
                })
            }
        });
    }

    loadProvince() {
        this.props.w18f1050Actions.loadProvinceW19F1051({}, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            } else if (data && data.length > 0) {
                let dataSuggestions = [];
                data.forEach(item => {
                    const itemNew = {
                        value: item.ProvinceID,
                        label: item.ProviceName,
                    };
                    dataSuggestions.push(itemNew);
                });
                this.setState({
                    suggestions: dataSuggestions,
                });
            }
        });
    }

    loadInformationType() {
        this.props.w18f1050Actions.loadTypeInformation({}, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    }

    loadInformationCode(options) {
        const param = {
            "NormID": this.state.normIDInformation
        };
        this.props.w18f1050Actions.loadCodeInformation(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    }

    loadConditionType() {
        this.props.w18f1050Actions.loadTypeCondition({}, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    }

    loadConditionCode() {
        const param = {
            "NormID": ''
        };
        this.props.w18f1050Actions.loadCodeCondition(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    }

    hideW18F1024 = (data) => {
        let person = [data];
        let selectedSalePerson = [...this.state.dataSalePerson];
        if (!data) {
            this.setState({
                showW18F1024: false,
            })
        }
        person.forEach((dataSale) => {
            if (dataSale.IsSalesPerson === 1) {
                if (dataSale.GroupSalesID === selectedSalePerson.SalesPersonID) {
                    return false;
                }
                const itemNew = {
                    SalesPersonID: dataSale.GroupSalesID,
                    SalesPersonNameU: dataSale.GroupSalesNameU,
                    URL: dataSale.URL
                };
                selectedSalePerson.push(itemNew);
                this.setState({
                    dataSalePerson: selectedSalePerson
                });
            }
        });
        this.setState({
            showW18F1024: false,
        })
    };

    deleteSalaPerson = (data) => {
        let selectedSalePerson = [...this.state.dataSalePerson];
        selectedSalePerson.splice(data, 1);
        this.setState({
            dataSalePerson: selectedSalePerson
        });
    };

    handleDelete = (tags) => {
        this.setState({tags})
    };

    handleAddition = (tags) => {
        this.setState({tags})
    };

    renderCboUnit(e) {
        const {row} = e;
        if (e.dataField === 'NormID') {
            e.editorOptions.onValueChanged = (arg) => {
                row.data.ValueNameU = '';
                row.data.ValueID = '';
                row.data[e.dataField] = arg.value;
            }
        } else if (e.dataField === 'ValueID') {
            e.editorOptions.onValueChanged = (arg) => {
                row.data.ValueID = arg.value;
                const dataSource = arg.component.option("dataSource");
                const unitName = dataSource.filter((value) => {
                    return value.ValueID === arg.value
                });
                if (unitName.length > 0) {
                    row.data.ValueNameU = unitName[0].ValueName;
                }
            };
            e.editorOptions.onInitialized = async (arg) => {
                const param = {
                    "NormID": row.data.NormID
                };
                const loadDataSource = await getCboInfo(param);
                if (loadDataSource.code === 200) {
                    let data = loadDataSource.data;
                    row.data.ValueID = '';
                    arg.component.option("dataSource", data);
                }
            }
        } else  if (e.dataField === 'RefNormID') {
            e.editorOptions.onValueChanged = (arg) => {
                row.data.RefValueNameU = '';
                row.data.RefValueID = '';
                row.data[e.dataField] = arg.value;
            }
        } else  if (e.dataField === 'RefValueID') {
            e.editorOptions.onValueChanged = (arg) => {
                row.data.RefValueID = arg.value;
                const dataSource = arg.component.option("dataSource");
                const unitName = dataSource.filter((value) => {
                    return value.RefValueID === arg.value
                });
                if (unitName.length > 0) {
                    row.data.RefValueNameU = unitName[0].RefValueNameU;
                }
            };
            e.editorOptions.onInitialized = async (arg) => {
                const param = {
                    "NormID": row.data.RefNormID
                };
                const loadDataSource = await getCboCondition(param);
                if (loadDataSource.code === 200) {
                    let data = loadDataSource.data;
                    arg.component.option("dataSource", data);
                }
            }
        }
    }

    onAddNew() {
        let dataGrid = this.state.dataGrid || [];
        this.setState({
            dataGrid: [...dataGrid, {ID: dataGrid.length + 1}]
        })
    }

    renderActionActivity(e) {
        if (!e) return null;
        const {iPermission} = this.state;
        const {mode} = this.getInfo();
        return (
            <GridActionBar
                isPer={iPermission}
                tooltipDelete={Config.lang("CRM_Xoa")}
                className={mode === 'detail' ? 'isDisabled' : ''}
                onDeleteAction={() => {
                    if (mode === 'add' || mode === 'edit') {
                        this.deleteRow(e)
                    }
                }}/>
        );
    }

    deleteRow(e) {
        Config.popup.show("YES_NO", Config.lang('CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay'), () => {
            let grid = [...this.state.dataGrid];
            grid.splice(e.rowIndex, 1);
            grid.forEach((value, index) => {
                value.ID = index + 1
            });
            this.setState({
                dataGrid: grid
            });
        })
    }

    saveData() {
        const {dataGrid, dataSalePerson, tags} = this.state;
        let dataProV = [];
        let dataSale = [];
        let dataDetail = [];
        const regionID = document.getElementById("RegionID").value;
        const regionName = document.getElementById("RegionNameU").value;
        const remark = document.getElementById("Remark").value;
        const disabled = !this.state.Disabled ? '0' : '1';
        const {mode} = this.getInfo();

        tags.forEach((dataProvince) => {
            if (dataProvince) {
                const itemNew = {
                    ProvinceID: dataProvince.value,
                    ProvinceNameU: dataProvince.label,
                };
                dataProV.push(itemNew);
            }
        });

        dataSalePerson.forEach((sale) => {
            if (sale) {
                const itemNew = {
                    SalesPersonID: sale.SalesPersonID,
                };
                dataSale.push(itemNew);
            }
        });

        dataGrid.forEach((detail) => {
            if (detail && (detail.NormID || detail.Quantity || detail.RefNormID || detail.RefValueID || detail.ValueID)) {
                dataDetail.push(detail);
            }
        });

        const param = {
            "RegionID": regionID,
            "RegionNameU": regionName,
            "Remark": remark,
            ProvinceID: JSON.stringify(dataProV),
            SalesPersonID: JSON.stringify(dataSale),
            Detail: JSON.stringify(dataDetail),
            "Disabled": disabled,
        };
        if (mode === 'add') {
            if (regionID === "" || regionID === 'undefined') {
                Config.popup.show('INFO', `${Config.lang("CRM_Ma_dia_ban_khong_duoc_de_trong")}`);
                document.getElementById("RegionID").focus();
                return false;
            } else if (regionName === "" || regionName === 'undefined') {
                Config.popup.show('INFO', `${Config.lang("CRM_Ten_dia_ban_khong_duoc_de_trong")}`);
                document.getElementById("RegionNameU").focus();
                return false;
            } else {
                this.props.w18f1050Actions.addW18F1051(param, (error, data) => {
                    if (error) {
                        let errorCode = error.code || null;
                        let message = "";
                        switch (errorCode) {
                            case "F1051E005":
                                message = Config.lang("CRM_Ma_nay_da_ton_tai");
                                Config.popup.show('INFO', message);
                                break;
                            case "F1040E006":
                                message = Config.lang("CRM_Ma_nay_da_ton_tai");
                                Config.popup.show('INFO', message);
                                break;
                            case "F1051E008":
                                message = Config.lang("CRM_Loai_thong_tin_khong_duoc_de_trong");
                                Config.popup.show('INFO', message);
                                break;
                            case "F1051E009":
                                message = Config.lang("CRM_So_luong_khong_duoc_de_trong");
                                Config.popup.show('INFO', message);
                                break;
                            default:
                                message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                                Config.popup.show('INFO', message);
                                break;
                        }
                        this.setState({isSubmit: false, message: message, loading: false});
                        return false;
                    }
                    this.setState({
                        isDisabled: false,
                    });
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                });
            }
        } else if (mode === 'edit') {

            // const mparam = {
            //     "RegionID": regionID,
            //     "RegionNameU": regionName,
            //     "Remark": remark,
            //     "Disabled": disabled,
            //     ProvinceID: JSON.stringify(dataProV),
            //     SalesPersonID: JSON.stringify(dataSale),
            //     Detail: JSON.stringify(dataDetail),
            // };
            this.props.w18f1050Actions.editW18F1051(param, (error, data) => {
                if (error) {

                    let errorCode = error.code || null;
                    let message = "";
                    switch (errorCode) {
                        case "F1051E002":
                            message = Config.lang("CRM_Ma_dia_ban_khong_duoc_de_trong");
                            Config.popup.show('INFO', message);
                            break;
                        case "F1051E003":
                            message = Config.lang("CRM_Ten_dia_ban_khong_duoc_de_trong");
                            Config.popup.show('INFO', message);
                            break;
                        case "F1040E006":
                            message = Config.lang("CRM_Ma_nay_da_ton_tai");
                            Config.popup.show('INFO', message);
                            break;
                        case "F1051E008":
                            message = Config.lang("CRM_Loai_thong_tin_khong_duoc_de_trong");
                            Config.popup.show('INFO', message);
                            break;
                        case "F1051E009":
                            message = Config.lang("CRM_So_luong_khong_duoc_de_trong");
                            Config.popup.show('INFO', message);
                            break;
                        default:
                            message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                            Config.popup.show('INFO', message);
                            break;
                    }
                    this.setState({isSubmit: false, message: message, loading: false});
                    return false;
                }
                this.setState({
                    isDisabled: false,
                });
                notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
            });
        }
    }

    onDisabledChanged(e) {
        this.setState({
            Disabled: e.value
        });
    }


    render() {
        if (!this.getInfo(true)) return null;
        const {mode} = this.getInfo();
        const {dataSalePerson, dataGrid, tags} = this.state;
        const {loadTypeInformation, loadTypeCondition} = this.props;
        return (
            <div className="page-container" style={{margin: 0}}>
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W18F1051"} label={Config.profile.UserID}/>}
                <div style={{marginLeft: '10px'}} className="grid-title">{Config.lang("CRM_Cap_nhat_dia_ban")}</div>
                <Form horizontal={true} id={"W18F1051"} className={'mgt5'} style={{margin: '0 15px'}}>
                    <FormGroup className={"jus-end"}>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <ButtonCustom color={'text-orange'} icon={'fas fa-arrow-alt-left'} isAwesome={true}
                                          name={Config.lang("CRM_Quay_lai")}
                                          onClick={() => {
                                              browserHistory.push(Config.env.url + '/W18F1050')
                                          }}/>
                            <ButtonSave id={'btnSaveW18F1051'} disabled={this.state.isDisabled || mode === 'detail'}
                                        className={'mgl5'}
                                        name={Config.lang("CRM_Luu")}
                                        onClick={() => this.saveData()}/>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                            <label className={'control-label'}>{Config.lang("CRM_Ma_dia_ban")}<i
                                className="error">*</i></label>
                        </Col>
                        <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                            <Input id={'RegionID'}
                                   onPaste={(e) => {
                                       document.getElementById('RegionID').value = Config.replaceSymbol(e.target.value);
                                   }}
                                   onChange={(e) => {
                                       document.getElementById('RegionID').value = Config.replaceSymbol(e.target.value);
                                   }}
                                   disabled={mode === 'detail' || mode === 'edit'}
                            />
                        </Col>
                        <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                            <label className={'control-label'}>{Config.lang("CRM_Ten_dia_ban")}<i
                                className="error">*</i></label>
                        </Col>
                        <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                            <Input id={'RegionNameU'}
                                   disabled={mode === 'detail'}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                            <label className={'control-label'}>{Config.lang("CRM_Tinh_Thanh_pho")}</label>
                        </Col>
                        <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                            {mode !== 'detail' && <Combo
                                defaultValue={tags}
                                dataSource={this.state.suggestions}
                                placeholder={Config.lang("CRM_Chon_tinh")}
                                handleDelete={this.handleDelete}
                                onActionWhenSelectChange={this.handleAddition}
                                displayExpr={'label'}
                                valueExpr={'value'}
                                isMulti={true}
                            />}
                            {mode === 'detail' && <div>
                                {
                                    tags && tags.map((value, index) => {
                                        return (
                                            <button key={index}
                                                    className={'react-tags__selected-tag not-remove'}
                                            >
                                                <span> {value.label}</span>
                                            </button>
                                        )
                                    })
                                }
                            </div>}
                        </Col>

                        <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                            <label className={'control-label employeeHref'}>
                                <div className={mode === 'detail' ? 'isDisabled' : ''}
                                     onClick={() => {
                                         if (mode === "detail") {
                                             this.setState({showW18F1024: false})
                                         } else {
                                             this.setState({showW18F1024: true})
                                         }
                                     }}
                                >
                                    {Config.lang("CRM_Nhan_vien")}
                                </div>
                            </label>
                        </Col>
                        <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                            <div id={'employeeW18F1051'}>
                                {
                                    dataSalePerson && dataSalePerson.map((value, index) => {
                                        return (
                                            <span key={index}
                                                  className={'mgr5 mgb5 employeeListW18F1051'}
                                            >
                                                <img alt={''}
                                                     src={value.URL ? value.URL : require('./../../images/icon-user-default.png')}/>
                                                <span> {value.SalesPersonNameU}
                                                    <i className={mode === "detail" ? '' : 'fal fa-times employeeDelW18F1051'}
                                                       onClick={() => {
                                                           if (mode === "add" || mode === "edit") {
                                                               this.deleteSalaPerson(index)
                                                           }
                                                       }}
                                                    />
                                                </span>
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                            {/*<label className={'control-label'}>{Config.lang("CRM_Ghi_chu")}</label>*/}
                        </Col>
                        <Col md={10} sm={10} xl={10} xs={10} lg={10}>
                            <CheckBox
                                elementAttr={{
                                    class: "control-label text-bold"
                                }}
                                width={200}
                                text={Config.lang("CRM_Khong_su_dung")}
                                id={"Disabled"}
                                disabled={mode === 'detail'}
                                onValueChanged={(e) => this.onDisabledChanged(e)}
                                value={this.state.Disabled}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                            <label className={'control-label'}>{Config.lang("CRM_Ghi_chu")}</label>
                        </Col>
                        <Col md={10} sm={10} xl={10} xs={10} lg={10}>
                            <Input id={'Remark'} type={'textarea'} rows={4}
                                   disabled={mode === 'detail'}
                            />
                        </Col>
                    </FormGroup>

                    <GridContainer
                        isPer={this.state.iPermission}
                        onAddNew={() => {
                            if (mode === 'add' || mode === 'edit') {
                                this.onAddNew()
                            }
                        }}
                        allowAdding={mode === 'detail' ? 0 : 4}
                        allowSearch={'disabledSearch'}
                        onSearch={() => this.onSearch()}
                        dataSource={dataGrid}
                        showColumnLines={true}
                        allowColumnReordering={true}
                        showBorders={false}
                        onEditorPreparing={(e) => {
                            this.renderCboUnit(e);
                        }}
                        editing={{
                            mode: 'cell',
                            allowUpdating: mode === 'detail' ? false : true,
                        }}
                    >
                        <Paging enabled={true}/>
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[10, 15, 20]}
                            showInfo={true}
                        />
                        <Column
                            cellRender={(e) => this.renderActionActivity(e)}
                            caption={Config.lang("CRM_Hanh_dong")}
                            alignment={'center'}
                            width={100}/>
                        <Column width={40}
                                caption={Config.lang("STT")}
                                dataField={'ID'}
                                alignment={'center'}
                                cellRender={(data) => data.rowIndex + 1}
                        />
                        <Column
                            dataField={'NormID'}
                            caption={Config.lang("CRM_Loai_thong_tin_quan_ly")}
                            width={400}>
                            <Lookup
                                dataSource={loadTypeInformation} displayExpr={'NormNameU'} valueExpr={'NormID'}/>
                        </Column>
                        <Column
                            dataField={'ValueID'}
                            caption={Config.lang("CRM_Du_lieu")}
                            cellRender={(e) => {
                                const {data} = e;
                                const ValueName = data.ValueNameU;
                                return ValueName;
                            }}
                            width={300}>
                            <Lookup displayExpr={'ValueName'} valueExpr={'ValueID'}/>
                        </Column>
                        <Column dataField={'Quantity'} caption={Config.lang("CRM_So_luong")}
                                width={300}
                                cellRender={(e) => {
                                    const {data} = e;
                                    return data.Quantity
                                }}
                                dataType={'number'}/>
                        <Column
                            dataField={'RefNormID'}
                            caption={Config.lang("CRM_Loai_thong_tin_tham_chieu")}
                            width={400}
                        >
                            <Lookup dataSource={loadTypeCondition} displayExpr={'RefNormNameU'}
                                    valueExpr={'RefNormID'}/>
                        </Column>
                        <Column
                            dataField={'RefValueID'}
                            caption={Config.lang("CRM_Du_lieu_tham_chieu")}
                            cellRender={(e) => {
                                const {data} = e;
                                return data.RefValueNameU
                            }}
                            width={300}
                        >
                            <Lookup displayExpr={'RefValueNameU'} valueExpr={'RefValueID'}/>
                        </Column>
                    </GridContainer>
                </Form>
                {
                    this.state.showW18F1024 && <PopupView
                        title={Config.lang("CRM_Co_cau_kinh_doanh")}
                        show={true}
                        animation={true}
                        onHide={() => this.hideAddNewPopup()}>
                        <W18F1024 data={this.state.dataSalePerson} onHide={(data) => this.hideW18F1024(data)}/>
                    </PopupView>
                }
            </div>
        )
    }
}

export default connect(state => ({
        loadDetailW19F1051: state.w18f1050.loadDetailW19F1051,
        loadProvinceW19F1051: state.w18f1050.loadProvinceW19F1051,
        loadTypeInformation: state.w18f1050.loadTypeInformation,
        loadCodeInformation: state.w18f1050.loadCodeInformation,
        loadTypeCondition: state.w18f1050.loadTypeCondition,
        loadCodeCondition: state.w18f1050.loadCodeCondition,
        addW18F1051: state.w18f1050.addW18F1051,
        editW18F1051: state.w18f1050.editW18F1051,
    }),
    (dispatch) => ({
        w18f1050Actions: bindActionCreators(w18f1050Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    })
)(W18F1051Page);