/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/02/21 08:57
 * @update 2019/02/21 08:57
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w18f1025Actions from "../../actions/w18F1025-actions";
import Config from "../../config";
import {Row, Col, FormGroup} from "react-bootstrap";
import DropDownSalePerson from "../common/dropdown/template/dropdown-sale-person";
import ButtonSave from "../common/button/button-save";
import EventTracking from "../common/tracking/event";
import {CheckBox} from 'devextreme-react';
import notify from "devextreme/ui/notify";
import 'moment/locale/vi'
import * as userActions from "../../actions/user-actions";

const cssLable = {
    width:'calc(100% - 20px)',
    margin: "10px",
    padding: "0 10px",
    border:'1px solid lightgrey'
};

class W18F2015Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state={
            onSave: false
        };
        this.vlWork=false;
        this.isSubmit=false;
    }


    loadPermission() {
        this.props.userActions.getPermission(Config.profile.UserID, "D17F1025");
    }

    componentDidMount() {
        this.loadPermission();
    }

    onSave = () =>{
        if(this.isSubmit) return;
        this.isSubmit = true;
        if(!this.vlOldPerson){
            Config.popup.show("INFO", Config.lang("CRM_Phai_chon_nguoi_ban_giao"));
            this.isSubmit = false;
            return;
        }
        if(!this.vlNewPerson){
            Config.popup.show("INFO", Config.lang("CRM_Phai_chon_nguoi_duoc_ban_giao"));
            this.isSubmit = false;
            return;
        }
        if(this.vlOldPerson && this.vlOldPerson === this.vlNewPerson){
            Config.popup.show("INFO", Config.lang("CRM_Nguoi_ban_giao_va_nguoi_duoc_ban_giao_phai_khac_nhau"));
            this.isSubmit = false;
            return;
        }
        const params = {
            SalesPersonIDNew: this.vlNewPerson,
            SalesPersonIDOld: this.vlOldPerson,
            IsTask: this.vlWork ? 1 : 0
        };

        this.props.w18f1025Actions.handOver(params, (error, data)=>{
            if(error){
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO',message);
                return false;
            }
            else if(data){
                notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
            }
            this.isSubmit = false;
        })
    };

    render() {
        return (
            <div className="page-container"
                 style={cssLable}>
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W18F2015"} label={Config.profile.UserID}/>}

                <div className="lb-cus-title"
                     style={{
                         borderBottom: '1px solid lightgrey',
                         paddingBottom: 10
                     }}>
                    {Config.lang("CRM_Ban_giao")}
                </div>
                <FormGroup>
                    <br/>
                    <Row>
                        <Col md={12} sm={12} xs={12} lg={12}>
                            <CheckBox
                                text={Config.lang("CRM_Khach_hang")}
                                disabled={true}
                                defaultValue={true}
                                className={'control-label'}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={12} sm={12} xs={12} lg={12}>
                            <CheckBox
                                text={Config.lang("CRM_Cong_viec")}
                                className={'control-label'}
                                onValueChanged={(e) => {
                                    this.vlWork = e.value;
                                }}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row className="display_row align-right wrap">
                        <Col md={5} sm={5} xs={12} lg={5}>
                            <label className={'control-label-above'}>{Config.lang("CRM_Nhan_vien_ban_giao")}</label>
                            <DropDownSalePerson placeholder={""}
                                                value={""}
                                                onChange={(val) => {
                                                    this.vlOldPerson = val.SalesPersonID;
                                                    if(this.vlNewPerson){
                                                        this.setState({
                                                            onSave: true
                                                        })
                                                    }
                                                }}/>
                        </Col>
                        <Col md={2} sm={2} xs={2} lg={2}
                             className="display_row valign-middle"
                        >
                            <label className={'control-label-above'}
                                   style={{marginTop:10,whiteSpace:'pre'}}
                            >
                                {Config.lang("CRM_Ban_giao_cho")}
                            </label>
                        </Col>
                        <Col md={5} sm={5} xs={12} lg={5}>
                            <label className={'control-label-above'}>{Config.lang("CRM_Nhan_vien_nhan_ban_giao")}</label>
                            <DropDownSalePerson placeholder={""}
                                                value={""}
                                                onChange={(val) => {
                                                    this.vlNewPerson = val.SalesPersonID;
                                                    if(this.vlOldPerson){
                                                        this.setState({
                                                            onSave: true
                                                        })
                                                    }
                                                }}/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col sm={12} md={12} lg={12} className='display_row valign-bottom'>
                            <ButtonSave
                                disabled={!this.state.onSave}
                                onClick={this.onSave}
                                className={"mgr5"}
                                name={Config.lang("CRM_Dong_y")}/>
                        </Col>
                    </Row>
                </FormGroup>
            </div>
        )
    }
}

export default connect(state => ({

    }),
    (dispatch) => ({
        w18f1025Actions: bindActionCreators(w18f1025Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)

    })
)(W18F2015Page);