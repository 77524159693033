/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/29/03 09:45
 * @update 2019/29/03 09:45
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w18f1022Actions from "../../actions/w18f1022-actions";
import GridContainer from "../common/grid-container/grid-container";
import {Column} from 'devextreme-react/data-grid';
import {
    Row,
    FormGroup,
    Button,
    Col,
} from 'react-bootstrap';
import Combo from "../common/dropdown/combo";
import {DateBox} from 'devextreme-react';
import {
    Validator,
    CompareRule,
} from 'devextreme-react/validator';
import Config from '../../config/index';
import EventTracking from "../common/tracking/event";
import CustomStore from "devextreme/data/custom_store";
import getCreateUserID from "../../actions/async/w18f1022/create-user-async";
import getUserReceiveID from "../../actions/async/w18f1022/create-receive-async";

import moment from "moment";
import 'moment/locale/vi';

const createUserIDCustomStore = {
    store: new CustomStore({
        key: "CreateUserID",
        load: async (loadOptions) => {
            const param = {
                "skip": loadOptions.skip,
                "limit": loadOptions.take,
                "search": loadOptions.searchValue,
            };
            if (loadOptions.filter) {
                param.where = "T2.UserNameU LIKE N'%" + loadOptions.filter.filterValue + "%'";
            }
            const data = await getCreateUserID(param);
            const result = {
                'data': data.rows,
                'totalCount': data.total
            };
            return result;
        },
        byKey: async (key) => {
            const param = {
                "where": "T1.UserID='" + key + "'"
            };
            const data = await getCreateUserID(param);
            let result = '';
            if (data.rows.length > 0) result = data.rows[0];
            return result;
        }
    })
};

const createUserReceiveCustomStore = {
    store: new CustomStore({
        key: "ReceiveUserID",
        load: async (loadOptions) => {
            const param = {
                "skip": loadOptions.skip,
                "limit": loadOptions.take,
                "search": loadOptions.searchValue,
                // "where": "T1.Disabled = 0",
                "where": JSON.stringify({
                    fieldName:'T1.Disabled',
                    operator:'=',
                    value:0
                })
            };
            if (loadOptions.filter) {
                param.where = JSON.stringify({
                    fieldName:'SalesPersonNameU',
                    operator:'LIKE',
                    value:loadOptions.filter.filterValue,
                })
                // param.where = "SalesPersonNameU LIKE N'%" + loadOptions.filter.filterValue + "%'";
            }
            const data = await getUserReceiveID(param);
            const result = {
                'data': data.rows,
                'totalCount': data.total
            };
            return result;
        },
        byKey: async (key) => {
            const param = {
                // "where": "SalesPersonID='" + key + "'"
                where:JSON.stringify({
                    fieldName:'SalesPersonID',
                    operator:'LIKE',
                    value:key
                })
            };
            const data = await getUserReceiveID(param);
            let result = '';
            if (data.rows.length > 0) result = data.rows[0];
            return result;
        }
    })
};

class W18F1022Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            CreateDateFrom: '',
            CreateDateTo: '',
            createUserID: '',
            receiveUserID: '',
        };
        this.timer = null;
        this.filter = {
            skip: 0,
            limit: 15,
            search: '',
            createUserID: '',
            transTypeID:'',
            receiveUserID:'',
            CreateDateTo:'',
            CreateDateFrom:'',
        };

        this.dataTranType = [];

        this.transTypeID = '';
        this.CreateDateTo = '';
        this.CreateDateFrom = '';
    };

    componentDidMount() {
        this.loadTranType();
        this.loadGrid();
    }

    loadTranType() {
        this.props.w18f1022Actions.loadCbTranType({}, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
            } else {
                if (data && data.length > 0) {
                    this.dataTranType = data.map(o => {
                        const groupSales = {
                            TransType: o.TransType
                            , TransTypeName: o.TransTypeName
                        };
                        return groupSales;
                    });
                }
            }
        });
    }

    formatDate(date) {
        return moment(date).format('MM/DD/YYYY')
    }

    loadGrid(flag) {
        const uName = Config.profile.UserID;
        const uLanguage = Config.language || '84';

        if(flag){
            this.filter.createUserID = this.state.createUserID;
            this.filter.receiveUserID = this.state.receiveUserID;
            this.filter.transTypeID = this.transTypeID;
            this.filter.CreateDateTo = this.CreateDateTo;
            this.filter.CreateDateFrom = this.CreateDateFrom;
        }

        const paramGrid = {
            "UserID": uName,
            "Language": uLanguage,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
        };

        if(this.filter.createUserID){
            paramGrid.CreateUserID = this.filter.createUserID;
        }

        if(this.filter.receiveUserID){
            paramGrid.ReceiveUserID = this.filter.receiveUserID;
        }

        if(this.filter.transTypeID){
            paramGrid.TransType = this.filter.transTypeID;
        }

        if(this.filter.CreateDateFrom){
            paramGrid.CreateDateFrom = this.formatDate(this.filter.CreateDateFrom);
        }

        if(this.filter.CreateDateTo){
            paramGrid.CreateDateTo = this.formatDate(this.filter.CreateDateTo);
        }

        this.props.w18f1022Actions.loadW18F1022(paramGrid, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                if (errorCode === 'F1022E002') {
                    message = Config.lang("CRM_Ban_chua_chon_ngay_den");
                    Config.popup.show('INFO', message);
                }
                else{
                    message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
        });
    }

    onChangeComboTransType(data) {
        this.transTypeID = data && data.TransType ? data.TransType : '';
    }

    onChangeComboCreateUserID(data) {
        this.setState({
            createUserID : data ? data : ''
        });
    }

    onChangeComboReceiveUserID(data) {
        this.setState({
            receiveUserID: data ? data : ''
        });
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    onSearch = () => {
        this.filter.skip = 0;
        this.loadGrid(true)
    };

    render() {
        const {loadW18F1022} = this.props;
        let totalItems = 0;
        let dataSource = [];
        if (loadW18F1022 && loadW18F1022.data && loadW18F1022.data.length > 0) {
            loadW18F1022.data.map(o => {
                const row = {
                    TransType: o.TransType,
                    TransTypeNameU: o.TransTypeNameU,
                    CompanyNameU: o.CompanyNameU,
                    CreateUserNameU: o.CreateUserNameU,
                    ReceiveUserNameU: o.ReceiveUserNameU,
                    CreateDate: o.CreateDate,
                    Event: o.Event,
                    EventNameU: o.EventNameU,
                    CompanyNewStatus: o.CompanyNewStatus,
                    ReasonTransfer: o.ReasonTransfer,
                };
                totalItems = o.TotalRecord;
                dataSource.push(row);
                return o;
            });
        }
        return (
            <div className="page-container">
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W18F1022"} label={Config.profile.UserID}/>}

                <div className="grid-title">{Config.lang("CRM_Danh_sach_lich_su_thay_doi")}</div>
                <FormGroup>
                    <Row className={"list_padding mgbt5"}>
                        <Col sm={2}>
                            <Combo
                                dataSource={this.dataTranType}
                                showClearButton={true}
                                placeholder={Config.lang("CRM_Hanh_dong")}
                                valueExpr={'TransType'}
                                displayExpr={'TransTypeName'}
                                onActionWhenSelectChange={(value) => {
                                    this.onChangeComboTransType(value)
                                }}
                            >
                                <Column dataField={'TransType'} caption={Config.lang("Ma")} visible={false}
                                        dataType={'string'}/>
                                <Column dataField={'TransTypeName'} caption={Config.lang("Ten")} dataType={'string'}/>
                            </Combo>
                        </Col>
                        <Col sm={2}>
                            <Combo
                                showHeader={false}
                                showClearButton={true}
                                // required={true}
                                virtual={true}
                                dataSource={createUserIDCustomStore}
                                placeholder={Config.lang("CRM_Nguoi_chuyen")}
                                valueExpr={'CreateUserID'}
                                value={this.state.createUserID}
                                displayExpr={'CreateUserName'}
                                onActionWhenSelectChange={(data) => {
                                    this.onChangeComboCreateUserID(data)
                                }}
                            >
                                <Column dataField={'CreateUserID'} caption={Config.lang("Ma")} visible={false}
                                        dataType={'string'}/>
                                <Column dataField={'CreateUserName'} caption={Config.lang("Ten")} dataType={'string'}/>
                            </Combo>
                        </Col>
                        <Col sm={2}>
                            <Combo
                                showHeader={false}
                                dataSource={createUserReceiveCustomStore}
                                showClearButton={true}
                                // required={true}
                                virtual={true}
                                value={this.state.receiveUserID}
                                placeholder={Config.lang("CRM_Nguoi_nhan")}
                                valueExpr="ReceiveUserID"
                                displayExpr="ReceiveUserName"
                                onActionWhenSelectChange={(data) => {
                                    this.onChangeComboReceiveUserID(data)
                                }}
                            >
                                <Column dataField={'ReceiveUserID'} caption={Config.lang("Ma")} visible={false}
                                        dataType={'string'}/>
                                <Column dataField={'ReceiveUserName'} caption={Config.lang("Ten")} dataType={'string'}/>
                            </Combo>
                        </Col>
                        <Col sm={2}>
                            <DateBox
                                width={"100%"}
                                placeholder={Config.lang("CRM_Tu_ngay")}
                                id={'CreateDateFrom'}
                                openOnFieldClick={true}
                                useMaskBehavior={true}
                                showClearButton={true}
                                value={''}
                                type={'date'}
                                pickerType={"calendar"}
                                showAnalogClock={false}
                                displayFormat={'dd/MM/y'}
                                onValueChanged={(e) => {this.CreateDateFrom= e.value}}
                            />
                        </Col>
                        <Col sm={2}>
                            <DateBox
                                width={"100%"}
                                id={'CreateDateTo'}
                                placeholder={Config.lang("CRM_Den_ngay")}
                                openOnFieldClick={true}
                                useMaskBehavior={true}
                                showClearButton={true}
                                value={this.state.CreateDateTo}
                                type={'date'}
                                pickerType={"calendar"}
                                showAnalogClock={false}
                                displayFormat={'dd/MM/y'}
                                onValueChanged={(e) => {this.CreateDateTo= e.value}}
                            >
                                <Validator>
                                    <CompareRule ignoreEmptyValue={true}
                                                 message={Config.lang("CRM_Ngay_den_phai_lon_hon_ngay_tu")}
                                                 comparisonType={'>='}
                                                 reevaluate={false} comparisonTarget={() => {
                                        return this.state.CreateDateFrom
                                    }}
                                    />
                                </Validator>
                            </DateBox>
                        </Col>
                        <Col sm={1}>
                            <Button type="button" className={'pull-left'} onClick={this.onSearch}>
                                <i className="fas fa-search mgr5"/>
                                {Config.lang("CRM_Tim_kiem")}
                            </Button>
                        </Col>
                    </Row>
                </FormGroup>
                {dataSource && <GridContainer

                    dataSource={dataSource}
                    totalItems={totalItems}
                    columnResizingMode={'widget'}
                    itemPerPage={this.filter.limit}
                    listPerPage={[15, 20, 30, 50]}
                    onChangePage={(page) => {
                        this.onChangePage(page)
                    }}
                    onChangePerPage={(per) => {
                        this.onChangePerPage(per)
                    }}
                >
                    <Column dataField={'TransType'} visible={false} allowSorting={true}
                            allowReordering={false} fixed={true}/>
                    <Column dataField={'TransTypeNameU'} caption={Config.lang("CRM_Loai_chia_se")} allowSorting={true}
                            allowReordering={false} width={300}/>
                    <Column dataField={'CompanyNameU'} caption={Config.lang("CRM_Khach_hang")} allowSorting={true}
                            allowReordering={false} width={400}/>
                    <Column alignment='center' dataField={'CreateUserNameU'} caption={Config.lang("CRM_Nguoi_chuyen")}
                            allowSorting={true}
                            allowReordering={false} width={300}/>
                    <Column dataField={'ReceiveUserNameU'} caption={Config.lang("CRM_Nguoi_nhan")} allowSorting={true}
                            allowReordering={false} width={350}/>
                    <Column alignment='center' format="dd/MM/yyyy" dataType={'date'} dataField={'CreateDate'}
                            caption={Config.lang("CRM_Ngay_chuyen")} allowSorting={true} allowReordering={false}
                            width={150}/>
                    <Column alignment='center' dataField={'EventNameU'} width={150}
                            caption={Config.lang("CRM_Hanh_dong")}/>
                    <Column alignment='center' dataField={'CompanyNewStatus'} width={180}
                            caption={Config.lang("CRM_Trang_thai")}/>
                    <Column dataField={'ReasonTransfer'} width={180}
                            caption={Config.lang("CRM_Ghi_chu")}/>
                </GridContainer>}
            </div>
        )
    }
}

export default connect(state => ({
        loadW18F1022: state.w18f1022.loadW18F1022,
        loadCbTranType: state.w18f1022.loadCbTranType,
    }),
    (dispatch) => ({
        w18f1022Actions: bindActionCreators(w18f1022Actions, dispatch)
    })
)(W18F1022Page);
