/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import GridContainer from "../common/grid-container/grid-container";
import Config from "../../config";
import {Column} from "devextreme-react/data-grid";
import * as w18f1050Actions from "../../actions/w18/w18F1050/w18F1050-actions";
import * as userActions from "../../actions/user-actions";
import {browserHistory} from "react-router";
import {Form, FormGroup, Col, Button,Row} from 'react-bootstrap'
import PropTypes from "prop-types";
import EventTracking from "../common/tracking/event";
import GridActionBar from "../common/action-bar/grid-action-bar";
import notify from "devextreme/ui/notify";

import moment from "moment";
import 'moment/locale/vi'
import Combo from "../common/dropdown/combo";
import ButtonPrint from "../common/button/button-print";

moment.locale('vi');

class W18F1050 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            NormInfoType: '',
            NormInfoValue: '',
            NormRefType: '',
            NormRefValue: '',
            QuantityFrom: 0,
            QuantityTo: 0,
            resetComboInfoValue: false,
            loadingExcel:false,
            showGrid: false,
        };
        this.filter = {
            skip: 0,
            limit: 10,
            search: ''
        };
        this.dataSourceW18F1050 = [];
        this.dataInfoType = [];
        this.dataInfoValue = [];
        this.dataRefType = [];
        this.dataRefValue = [];
        this.resetComboInfoValue = false;
        this.resetComboRefValue = false;
        this.totalRecord = 0;
    };

    onSearch(text) {
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadGrid();
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1053",(iPer)=>{
            this.setState({iPermission: iPer});
        });
    };

    componentWillMount = async () => {
        await this.loadPermission();
        if(this.state.iPermission <= 0) return;
        this.loadGrid();
        this.loadInformationType();
        this.loadInformationValue();
        this.loadRefType();
        this.loadRefValue();
    };

    loadInformationType() {
        this.props.w18f1050Actions.cbInfoTypeW18F1050({}, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            } else if (data && data.length > 0) {
                this.dataInfoType = data.map(o => {
                    const cmbInfoType = {
                        ControlType: o.ControlType,
                        NormIDM: o.NormIDM,
                        NormNameUM: o.NormNameUM,
                    };
                    return cmbInfoType;
                });
            } else {
                this.dataInfoType = [];
            }
        });
    }

    loadInformationValue() {
        const param = {
            "NormID": this.state.NormInfoType
        };
        this.props.w18f1050Actions.cbInfoValueW18F1050(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            } else if (data && data.length > 0) {
                this.dataInfoValue = data.map(o => {
                    const cmbInfoValue = {
                        NormID: o.NormID,
                        ValueIDM: o.ValueIDM,
                        ValueNameM: o.ValueNameM,
                    };
                    return cmbInfoValue;
                });
            } else {
                this.dataInfoValue = [];
            }
        });
    }

    loadRefType() {
        this.props.w18f1050Actions.cbRefInfoW18F1050({}, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            } else if (data && data.length > 0) {
                this.dataRefType = data.map(o => {
                    const cmbRefType = {
                        ControlType: o.ControlType,
                        NormID: o.NormID,
                        NormNameU: o.NormNameU,
                    };
                    return cmbRefType;
                });
            } else {
                this.dataRefType = [];
            }
        });
    }

    loadRefValue() {
        const param = {
            "NormID": this.state.NormRefType
        };
        this.props.w18f1050Actions.cbRefValueW18F1050(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            } else if (data && data.length > 0) {
                this.dataRefValue = data.map(o => {
                    const cmbRefValue = {
                        NormID: o.NormID,
                        ValueID: o.ValueID,
                        ValueName: o.ValueName,
                    };
                    return cmbRefValue;
                });
            } else {
                this.dataRefValue = [];
            }
        });
    }

    loadGrid() {
        const disabled = !this.state.showGrid ? '0' : '1';
        const param = {
            "strSearch": this.filter.search,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
            "NormIDM": this.state.NormInfoType,
            "ValueIDM": this.state.NormInfoValue,
            "NormID": this.state.NormRefType,
            "ValueID": this.state.NormRefValue,
            "QuantityFrom": this.state.QuantityFrom,
            "QuantityTo": this.state.QuantityTo,
            "Disabled": disabled,
        };
        this.props.w18f1050Actions.loadGrid(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            } else if (data && data.rows && data.rows.length > 0) {
                this.dataSourceW18F1050 = data.rows.map(o => {
                    const cmbRegion = {
                        RegionID: o.RegionID,
                        RegionNameU: o.RegionNameU,
                        Remark: o.Remark,
                        ProvinceNameU: o.ProvinceNameU,
                        Province: o.Province,
                        SalesPerson: o.SalesPerson,
                        SalesPersonNameU: o.SalesPersonNameU,
                        CreateUserID: o.CreateUserID,
                        CreateDate: o.CreateDate,
                        LastModifyUserID: o.LastModifyUserID,
                        LastModifyDate: o.LastModifyDate,
                        Disabled: o.Disabled,
                    };
                    return cmbRegion;
                });
                this.totalRecord = data.total;
            } else {
                this.dataSourceW18F1050 = [];
                this.totalRecord = 0;
            }
        })
    }

    editRow = (e) => {
        const regionID = e && e.data && e.data.RegionID ? e.data.RegionID : '';
        browserHistory.push({
            pathname: Config.getRootPath() + 'W18F1051',
            state: {mode: 'edit', RegionID: regionID}
        })
    };

    renderActionActivity(e) {
        if (!e) return null;
        const {iPermission} = this.state;
        return (
            <GridActionBar
                isPer={iPermission}
                tooltipEdit={Config.lang("CRM_Sua")}
                onEditAction={() => {
                    this.editRow(e)
                }}

                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.deleteRow(e)
                }}/>
        );
    }

    onAddNew() {
        browserHistory.push({
            pathname: Config.getRootPath() + 'W18F1051',
            state: {mode: 'add'}
        })
    }

    deleteRow(row) {
        const regionID = row && row.data && row.data.RegionID ? row.data.RegionID : '';
        Config.popup.show("YES_NO", `${Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay")}`, () => {
            const param = {
                "RegionID": regionID
            };
            this.props.w18f1050Actions.deleteW18F1050(param, (error) => {
                if (error) {
                    let errorCode = error.code || null;
                    let message = "";
                    switch (errorCode) {
                        case "F1050E001":
                            message = Config.lang("Khu_vuc_nay_da_co_nhan_vien_so_huu");
                            Config.popup.show('INFO', message);
                            break;
                        default:
                            message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                            Config.popup.show('INFO', message);
                            break;
                    }
                    this.setState({isSubmit: false, message: message, loading: false});
                    return false;
                } else {
                    notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                    this.loadGrid();
                }
            });
        });
    }

    onDetail = (e) => {
        const regionID = e && e.RegionID ? e.RegionID : '';
        browserHistory.push({
            pathname: Config.getRootPath() + 'W18F1051',
            state: {mode: 'detail', RegionID: regionID}
        })
    };

    renderRegionNameU(data) {
        // if (!data) return null;
        const name = data && data.value ? data.value : '';

        /* eslint-disable */
        return (
            <div><a onClick={() => this.onDetail(data.data)}>{name}</a></div>
        );
        /* eslint-enable */
    }

    onChangeComboInfoType(data) {
        if (data) {
            this.setState({
                NormInfoType: data.NormIDM,
                NormInfoValue: '',
                resetComboInfoValue: true,
            });
        } else {
            this.setState({
                NormInfoType: '',
                NormInfoValue: '',
                resetComboInfoValue: true,
            });
        }
        this.loadInformationValue();
    }

    onChangeComboInfoValue(data) {
        if (data) {
            this.setState({
                NormInfoValue: data.ValueIDM,
                resetComboInfoValue: false,
            });
        } else {
            this.setState({
                NormInfoValue: '',
                resetComboInfoValue: false,
            });
        }
    }

    onChangeComboRefType(data) {
        if (data) {
            this.setState({
                NormRefType: data.NormID,
                NormRefValue: '',
                resetComboRefValue: true,
            });
        } else {
            this.setState({
                NormRefType: '',
                NormRefValue: '',
                resetComboRefValue: true,
            });
        }
        this.loadRefValue();
    }

    onChangeComboRefValue(data) {
        if (data) {
            this.setState({
                NormRefValue: data.ValueID,
                resetComboRefValue: false,
            });
        } else {
            this.setState({
                NormRefValue: '',
                resetComboRefValue: false,
            });
        }
    }

    validateDisplayOrder(e) {
        // khong cho nhap so am
        if (e.which === 189 || e.which === 109 || e.which === 69) {
            e.preventDefault()
        }
    }

    changeQuantityFrom = (e) => {

        const valueQuantityTo = this.state.QuantityTo;
        const valueQuantityFrom = e.target.value;

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.setState({
                QuantityFrom: valueQuantityFrom
            },()=>{
                if (valueQuantityTo && valueQuantityTo < this.state.QuantityFrom) {
                    Config.popup.show('INFO', Config.lang("CRM_So_luong_tu_phai_nho_hon_so_luong_den"));
                    document.getElementById('quantityFromW18F1050').value = '';
                    this.setState({
                        QuantityFrom: 0
                    });
                    document.getElementById("quantityFromW18F1050").focus();
                }
            });
        }, 500);
    };

    changeQuantityTo = (e) => {

        const valueQuantityTo = e.target.value;
        const valueQuantityFrom = this.state.QuantityFrom;

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
        this.setState({
            QuantityTo: valueQuantityTo
        },()=>{
            if (this.state.QuantityTo && !valueQuantityFrom) {
                    Config.popup.show('INFO', Config.lang("CRM_So_luong_tu_khong_duoc_de_trong"));
                    document.getElementById("quantityFromW18F1050").focus();
                return;
            }
            if (valueQuantityFrom && valueQuantityFrom > this.state.QuantityTo) {
                    Config.popup.show('INFO', Config.lang("CRM_So_luong_den_phai_lon_hon_so_luong_tu"));
                    document.getElementById('quantityToW18F1050').value = '';
                    this.setState({
                        QuantityTo: 0
                    });
                    document.getElementById("quantityToW18F1050").focus();
            }
        });
        }, 500);
    };

    render() {
        if(this.state.iPermission <= 0) return null;
        const {loadGrid} = this.props;
        const paramPrint = [
            {type: "Varchar", id: "UserID", value: Config.profile.UserID},
            {type: "Varchar", id: "Language", value: Config.language || '84'},
            {type: "NVarChar", id: "StrSearch", value: this.filter.search},
            {type: "Varchar", id: "NormIDM", value: this.state.NormInfoType},
            {type: "Varchar", id: "ValueIDM", value: this.state.NormInfoValue},
            {type: "DECIMAL", id: "QuantityFrom", value: this.state.QuantityFrom || 0},
            {type: "DECIMAL", id: "QuantityTo", value: this.state.QuantityTo || 0},
            {type: "Varchar", id: "NormID", value: this.state.NormRefType},
            {type: "Varchar", id: "ValueID", value: this.state.NormRefValue},
        ];
        return (
            <div className="page-container" style={{margin: 0, position:'relative', top:0, left:0}}>
                <EventTracking category={"TRACKING_USER"} action={"W18F1051"} label={Config.profile.UserID}/>
                <div style={{marginLeft: '10px'}} className="grid-title">{Config.lang("CRM_Danh_sach_dia_ban")}</div>
                <Form id='frmW18F1050' horizontal={true} >
                    <FormGroup>
                        <Col lg={2} md={2} xl={2} sm={2} xs={12}>
                            <label className={'control-label'}>{Config.lang("CRM_Loai_thong_tin_quan_ly")}</label>
                        </Col>
                        <Col lg={5} md={5} xl={5} sm={5} xs={12}>
                            <Combo
                                id={'normIDM'}
                                showHeader={false}
                                showClearButton={true}
                                dataSource={this.dataInfoType}
                                placeholder={"--Chọn--"}
                                value={this.state.NormInfoType}
                                valueExpr="NormIDM"
                                displayExpr="NormNameUM"
                                onActionWhenSelectChange={(data) => {
                                    this.onChangeComboInfoType(data)
                                }}
                            >
                                <Column dataField={'ControlType'} caption="" width={'100px'} visible={false}
                                        dataType={'string'}/>
                                <Column dataField={'NormIDM'} caption="" width={'100px'} visible={false}
                                        dataType={'string'}/>
                                <Column dataField={'NormNameUM'} caption="" width={'100%'}
                                        dataType={'string'}/>
                            </Combo>
                        </Col>
                        <Col lg={5} md={5} xl={5} sm={5} xs={12}>
                            <Combo
                                reset={this.state.resetComboInfoValue}
                                id={'valueIDM'}
                                showHeader={false}
                                showClearButton={true}
                                dataSource={this.dataInfoValue}
                                placeholder={"--Chọn--"}
                                // disabled={mode === 'edit' || mode === 'detail'}
                                value={this.state.NormInfoValue}
                                valueExpr="ValueIDM"
                                displayExpr="ValueNameM"
                                onActionWhenSelectChange={(data) => {
                                    this.onChangeComboInfoValue(data)
                                }}
                            >
                                <Column dataField={'NormID'} caption="" width={'100px'} visible={false}
                                        dataType={'string'}/>
                                <Column dataField={'ValueIDM'} caption="" width={'100px'} visible={false}
                                        dataType={'string'}/>
                                <Column dataField={'ValueNameM'} caption="" width={'100%'}
                                        dataType={'string'}/>
                            </Combo>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col lg={2} md={2} xl={2} sm={2} xs={12}>
                            <label className={'control-label'}>{Config.lang("CRM_Loai_thong_tin_tham_chieu")}</label>
                        </Col>
                        <Col lg={5} md={5} xl={5} sm={5} xs={12}>
                            <Combo
                                id={'normID'}
                                showHeader={false}
                                showClearButton={true}
                                dataSource={this.dataRefType}
                                placeholder={"--Chọn--"}
                                value={this.state.NormRefType}
                                valueExpr="NormID"
                                displayExpr="NormNameU"
                                onActionWhenSelectChange={(data) => {
                                    this.onChangeComboRefType(data)
                                }}
                            >
                                <Column dataField={'ControlType'} caption="" width={'100px'} visible={false}
                                        dataType={'string'}/>
                                <Column dataField={'NormID'} caption="" width={'100px'} visible={false}
                                        dataType={'string'}/>
                                <Column dataField={'NormNameU'} caption="" width={'100%'}
                                        dataType={'string'}/>
                            </Combo>
                        </Col>
                        <Col lg={5} md={5} xl={5} sm={5} xs={12}>
                            <Combo
                                reset={this.state.resetComboRefValue}
                                id={'valueID'}
                                showHeader={false}
                                showClearButton={true}
                                dataSource={this.dataRefValue}
                                placeholder={"--Chọn--"}
                                value={this.state.NormRefValue}
                                valueExpr="ValueID"
                                displayExpr="ValueName"
                                onActionWhenSelectChange={(data) => {
                                    this.onChangeComboRefValue(data)
                                }}
                            >
                                <Column dataField={'NormID'} caption="" width={'100px'} visible={false}
                                        dataType={'string'}/>
                                <Column dataField={'ValueID'} caption="" width={'100px'} visible={false}
                                        dataType={'string'}/>
                                <Column dataField={'ValueName'} caption="" width={'100%'}
                                        dataType={'string'}/>
                            </Combo>
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col lg={2} md={2}  sm={2}>
                            <label className={'control-label'}>{Config.lang("CRM_So_luong")}</label>
                        </Col>
                        <Col lg={3} md={3} sm={10}>
                            <Row>
                                <Col lg={6} md={6} sm={6}>
                                    <input type={'number'} min={0} step={1} onKeyDown={(e) => {
                                        this.validateDisplayOrder(e)
                                    }}
                                           onChange={(e) => {
                                               this.changeQuantityFrom(e);
                                           }}
                                           id={'quantityFromW18F1050'} className={'form-control'}/>
                                </Col>
                                <Col lg={6} md={6} sm={6}>
                                    <input type={'number'} min={0} step={1} id={'quantityToW18F1050'} onKeyDown={(e) => {
                                        this.validateDisplayOrder(e)
                                    }}
                                           onChange={(e) => {
                                               this.changeQuantityTo(e);
                                           }}
                                           className={'form-control'}/>
                                </Col>
                            </Row>

                        </Col>
                        <Col id={'buttonGroupW18F1050'} lg={2} md={2} xs={12} >
                            <Button type="button"
                                    onClick={() => {
                                        const valueQuantityTo = this.state.QuantityTo;
                                        const valueQuantityFrom = this.state.QuantityFrom;
                                        if (valueQuantityFrom && !valueQuantityTo) {
                                            Config.popup.show('INFO', Config.lang("CRM_So_lung_den_khong_duoc_de_trong"));
                                            document.getElementById("quantityToW18F1050").focus();
                                            return;
                                        }
                                        this.loadGrid()
                                    }}
                            > <i className="fas fa-search "/>{Config.lang("CRM_Tim_kiem")}</Button>
                            <ButtonPrint name={Config.lang('CRM_Xuat_du_lieu')}
                                         reportTypeID={'W18F1050'} transTypeID={'ExportQuotation'}
                                         param={paramPrint} className={'mgr5 '}/>
                        </Col>
                    </FormGroup>


                    <FormGroup>
                        <Col xs={12} lg={12} md={12} sm={12}>
                            {loadGrid && <GridContainer
                                id={'gridW18F1050'}
                                isPer={this.state.iPermission}
                                columnResizingMode={'widget'}
                                dataSource={this.dataSourceW18F1050}
                                totalItems={this.totalRecord}
                                itemPerPage={this.filter.limit}
                                onSearch={(text) => {
                                    this.onSearch(text)
                                }}
                                onChangePage={(page) => {
                                    this.onChangePage(page)
                                }}
                                onChangePerPage={(per) => {
                                    this.onChangePerPage(per)
                                }}
                                onAddNew={() => this.onAddNew()}
                                buttonCRM={[
                                    {
                                        buttonType: "check",
                                        dataFieldLb: '',
                                        buttonName: `${Config.lang("CRM_Hien_thi_khong_su_dung")}`,
                                        className: 'pdr10',
                                        align: 'right',
                                        onValueChanged:(e)=>{
                                            this.setState({
                                                showGrid:e.value
                                            },()=>{this.loadGrid()})
                                        }
                                    },
                                ]}
                            >
                                <Column
                                    cellRender={(e) => this.renderActionActivity(e)}
                                    caption={Config.lang("CRM_Hanh_dong")}
                                    alignment={'center'}
                                    width={100}/>
                                <Column
                                    dataField={'RegionID'}
                                    width={200}
                                    alignment={'center'}
                                    caption={Config.lang("CRM_Ma_dia_ban")}/>
                                <Column
                                    dataField={'RegionNameU'}
                                    width={350}
                                    cellRender={(data) => this.renderRegionNameU(data)}
                                    caption={Config.lang("CRM_Ten_dia_ban")}/>
                                <Column
                                    dataField={'Province'}
                                    caption={Config.lang("CRM_Tinh")}
                                    width={350}
                                />
                                <Column
                                    dataField={'SalesPerson'}
                                    caption={Config.lang("CRM_Nhan_vien")}
                                    width={350}
                                />
                                <Column
                                    dataField={'Remark'}
                                    caption={Config.lang("CRM_Ghi_chu")}
                                    width={300}
                                />
                                <Column
                                    dataField={'CreateUserID'}
                                    caption={Config.lang("CRM_Nguoi_tao")}
                                    width={200}
                                    alignment='center'
                                />
                                <Column
                                    dataField={'CreateDate'}
                                    caption={Config.lang("CRM_Ngay_tao")}
                                    dataType={'date'}
                                    format={'dd-MM-yyyy'}
                                    width={200}
                                    alignment='center'
                                />
                                <Column
                                    dataField={'LastModifyUserID'}
                                    caption={Config.lang("CRM_Nguoi_cap_nhat")}
                                    width={200}
                                    alignment='center'
                                />
                                <Column
                                    dataField={'LastModifyDate'}
                                    caption={Config.lang("CRM_Ngay_cap_nhat")}
                                    dataType={'date'}
                                    format={'dd-MM-yyyy'}
                                    width={200}
                                    alignment='center'
                                />
                                <Column
                                    dataField={'Disabled'}
                                    caption={Config.lang("CRM_Khong_su_dung")}
                                    dataType={'boolean'}/>
                            </GridContainer>}
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        )
    }

}

W18F1050.propTypes = {
    companyID: PropTypes.string,
};
export default connect(state => ({
        loadGrid: state.w18f1050.loadGrid,
        cbInfoTypeW18F1050: state.w18f1050.cbInfoTypeW18F1050,
        cbInfoValueW18F1050: state.w18f1050.cbInfoValueW18F1050,
        cbRefInfoW18F1050: state.w18f1050.cbRefInfoW18F1050,
        cbRefValueW18F1050: state.w18f1050.cbRefValueW18F1050,
        deleteW18F1050: state.w18f1050.deleteW18F1050,
    }),
    (dispatch) => ({
        w18f1050Actions: bindActionCreators(w18f1050Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W18F1050);
