/**
 * @copyright 2019 @ DigiNet
 * @author ThanhTram
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {InputGroup, Tab, Tabs} from 'react-bootstrap';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Config from '../../config/index';
import * as w18Actions from "../../actions/w18-actions";
import ButtonAdd from "../common/button/button-add";
import {browserHistory} from "react-router";
import UserContainer from "../common/user-view-container/user-container";
import notify from 'devextreme/ui/notify';
import InputGroupAddon from "react-bootstrap/es/InputGroupAddon";
import PagingCrm from "../common/paging/paging-crm";
import EventTracking from "../common/tracking/event";
import * as userActions from "../../actions/user-actions";

class W18F1030Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            listPerPage: [10, 15, 20, 30, 50],
            totalDataF:0,
            key:1,
            disabled:0,
            totalDisable1:0,
            totalDisable2:0,
            iPermission: 0
        };
        this.filter = {
            skip: 0,
            limit: 20,
            search: '',
            searchID: '',
            CompanyStatus: ''
        };
        this.timer = null;
    };

    loadPermission() {
        this.props.userActions.getPermission(Config.profile.UserID, "D17F1110", (iPer) => {
            this.setState({ iPermission: iPer });
        })
    }

    componentDidMount() {
        this.loadPermission();
        this.loadDataW18F1030();
        this.loadQuantity();
    }

    loadDataW18F1030() {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        const mnParam = {
            "UserID": uName,
            "Language": uLanguage,
            "SalesPersonID": '',
            "Disabled": this.state.disabled,
            "StrSearch": this.filter.search,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
        };
        this.props.w18Actions.loadW18F1030(mnParam, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }

                this.setState({isSubmit: false, message: message});
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    treeData: data,
                    totalDataF: data[0].TotalRecord
                });
            }
            else {
                this.setState({
                    treeData: [],
                    totalDataF: 0
                });
            }
        });
    }

    loadQuantity() {
        this.props.w18Actions.loadQuantityW18F1030({}, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
            } else if (data && data.data && data.data.length>0) {
                data.data.forEach((value) => {
                    if(value.Disabled === 0){
                        this.setState({
                            totalDisable1: value.CountSalesPerson
                        })
                    } else {
                        this.setState({
                            totalDisable2: value.CountSalesPerson
                        })
                    }
                });
            }
        });
    }

    onAddNew() {
        browserHistory.push({
            pathname: Config.getRootPath() + 'W18F1031',
            state: {mode:"add"}
        });
    }

    onCheckStore(query, cb) {
        this.props.w18Actions.checkStoreW18F1030(query, (error, data) => {
            let message = "";
            if (error) {
                message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
            }
            else if(data && data.data && data.data.length > 0){
                let status = data.data[0].Status;
                let msgAsk = data.data[0].MsgAsk;
                message = data.data[0].Message;
                if (status === 0) {
                    cb && cb();
                } else {
                    if (msgAsk === 0) {
                        Config.popup.show('INFO', message);
                    } else {
                        Config.popup.show("YES_NO", message,
                            () => {
                                cb && cb();
                            }
                        );
                    }
                }
            }
            else {
                Config.popup.show('INFO', `${Config.lang("CRM_Store_kiem_tra_bi_loi")}`);
            }
        });
    };

    onDeleteSalesPerson(m) {
        Config.popup.show("YES_NO", `${Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay")}`, () => {
            const query = {
                "SalesPersonID": m.SalesPersonID,
                "Language": Config.language,
            };
            this.onCheckStore(query, () => {
                const param = {
                    "SalesPersonID": m.SalesPersonID
                };
                this.props.w18Actions.deleteW18F1031(param, (error, data) => {
                    if (error) {
                        let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        return false;
                    } else {
                        notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                        this.loadDataW18F1030();
                    }
                });
            });

        });
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadDataW18F1030();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadDataW18F1030();
    }

    onSearch(text) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.filter.skip = 0;
            this.filter.search = text;
            this.loadDataW18F1030();
        }, 700);
    }

    handleSelect(key) {
        this.filter.skip = 0;
        this.setState({key: key},()=>{
            if(this.state.key === 1){
                this.setState({
                    disabled: 0,
                },()=>{this.loadDataW18F1030()})
            }
            else {
                this.setState({
                    disabled: 1,
                },()=>{this.loadDataW18F1030()})
            }
        });
    }

    render() {
        const {iPermission} = this.state;
        return (
            <div id={'user-profile-component'} className="page-container">
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W18F1030"} label={Config.profile.UserID}/>}

                <div className="grid-title">{Config.lang("CRM_Danh_sach_nhan_vien_kinh_doanh")}</div>

                <div className={"mgb15 valign-top group-dropdown"}>
                        <ButtonAdd className="mgl20" name={Config.lang("CRM_Them_moi1")}
                                   disabled={iPermission < 2}
                                   onClick={() => this.onAddNew()}/>
                        <div style={{width: '600px', marginRight:'20px'}}>
                            <InputGroup style={{width: '100%'}}>
                                <input id={'search-user-profile'} autoComplete={'off'}
                                       placeholder={Config.lang("CRM_Nhap_noi_dung_tim_kiem")}
                                       onKeyUp={(e) => this.onSearch(e.target.value)} type="text"
                                       className="form-control" style={{borderRadius:'5px'}}/>
                                <InputGroupAddon id={'search-button-user-profile'} style={{backgroundColor:'#EEEDED',color:'#0094ec', width:'50px', height:'34px', borderRadius:'5px'}} >
                                    <i className="fas fa-search"/>
                                </InputGroupAddon>
                            </InputGroup>
                    </div>
                </div>
                <Tabs
                    // defaultActiveKey={this.state.key === 1 ? 1 : 2}
                    id="tabMain"
                    activeKey={this.state.key}
                    animation={true}
                    unmountOnExit={true}
                    mountOnEnter={true}
                    onSelect={(key) => this.handleSelect(key)}
                >
                    {this.state.key >0 &&
                    <Tab eventKey={1} title={Config.lang("CRM_Dang_lam_viec") + ' (' + this.state.totalDisable1 + ')'}>
                        <div className="form-group mgr0 display_col">
                            <div style={{height: '100%'}}>
                                {this.state.treeData && <UserContainer
                                    iPermission={iPermission}
                                    onDelete={(m) => this.onDeleteSalesPerson(m)}
                                    data={this.state.treeData}/>}
                            </div>
                            <div className="user_paging_employee">
                                {this.state.totalDataF > 0 && <PagingCrm
                                    listPerPage={this.state.listPerPage}
                                    totalItems={this.state.totalDataF}
                                    itemPerPage={this.filter.limit}
                                    onChangePage={(page) => {
                                        this.onChangePage(page)
                                    }}
                                    onChangePerPage={(per) => {
                                        this.onChangePerPage(per)
                                    }}
                                />}
                            </div>
                        </div>
                    </Tab>}
                    {this.state.key >0 &&
                    <Tab eventKey={2} title={Config.lang("CRM_Da_nghi_viec") + ' (' + this.state.totalDisable2 + ')'}>
                        <div className="form-group mgr0 display_col">
                            <div style={{height: '100%'}}>
                                {this.state.treeData && <UserContainer
                                    iPermission={iPermission}
                                    onDelete={(m) => this.onDeleteSalesPerson(m)}
                                    data={this.state.treeData}/>}
                            </div>
                            <div className="user_paging_employee">
                                {this.state.totalDataF > 0 && <PagingCrm
                                    listPerPage={this.state.listPerPage}
                                    totalItems={this.state.totalDataF}
                                    itemPerPage={this.filter.limit}
                                    onChangePage={(page) => {
                                        this.onChangePage(page)
                                    }}
                                    onChangePerPage={(per) => {
                                        this.onChangePerPage(per)
                                    }}
                                />}
                            </div>
                        </div>
                    </Tab>}
                </Tabs>
            </div>
        )
    }
}

export default connect(state => ({
        loadW18F1030: state.w18.loadW18F1030,
        deleteW18F1031: state.w18.deleteW18F1031,
        checkStoreW18F1030: state.w18.checkStoreW18F1030,
        loadQuantityW18F1030: state.w18.loadQuantityW18F1030
    }),
    (dispatch) => ({
        w18Actions: bindActionCreators(w18Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    })
)(W18F1030Page);
