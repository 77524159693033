/**
 * @copyright 2019 @ DigiNet
 * @author THANH TRAM
 * @create 2019/07/19 13:00
 * @update 2019/07/19 13:00
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import * as w18F1026Actions from "../../actions/w18f1026-actions";
import Config from "../../config";
import {Form, FormGroup, Row} from "react-bootstrap";
import notify from "devextreme/ui/notify";
import EventTracking from "../common/tracking/event";
import {Col, Input} from "reactstrap";
import {Column} from "devextreme-react/data-grid";
import Combo from "../common/dropdown/combo";
import ButtonApprove from "../common/button/button-approve";

import moment from "moment";
import 'moment/locale/vi'
moment.locale('vi');

moment.locale('vi');

class W18F1026Page extends Component {
    constructor(props) {
        super(props);
        this.allowLoad = true;
        this.state = {
            loading: false,
            itemID: '',
            CompanyID: this.props.data.CompanyIDW19F1026,
            CompanyNameU: this.props.data.companyNameW18F1026,
            CompanyStatusName: this.props.data.CompanyStatusNameW18F1026,
        };
        this.dataComboStatus = [];
        this.timer = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 3000;
    }

    componentDidMount() {
        this.loadComboStatus();
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    loadComboStatus() {
        this.props.w18F1026Actions.loadComboW18F1026({}, (error, data) => {
            if (error) {
                Config.popup.show('INFO', `${Config.lang("CRM_Loi_chua_xac_dinh")}`);
                return false;
            } else if (data && data.length > 0) {
                this.dataComboStatus = data.map(o => {
                    const dataCombo = {
                        ItemID: o.ItemID
                        , DescriptionU: o.DescriptionU
                    };
                    return dataCombo;
                });
            }
        });
    }

    onChangeComboTransType(data) {
        if (data) {
            this.setState({
                itemID: data.ItemID
            });
        } else {
            this.setState({
                itemID: ''
            });
        }
    }


    saveData() {
        const {CompanyID, itemID} = this.state;
        const reasonTransfer = document.getElementById("ReasonTransfer").value;
        const paramStatus = {
            'TransType': 'ChangeStatus',
            'CompanyID': CompanyID,
            'CompanyStatus': itemID,
            'ReasonTransfer': reasonTransfer,
        };
        this.props.w18F1026Actions.saveW18F1026(paramStatus, (error, data) => {
            if (error) {
                Config.popup.show('INFO', `${Config.lang("CRM_Loi_chua_xac_dinh")}`);
                return false;
            } else if (data.code === 200) {
                notify(Config.lang("CRM_Luu_thanh_cong"), "success", 200);
                // Auto close when nothing to do
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.onHide();
                }, this.timeDeplay);
            }
        })
    }

    onHide() {
        this.setState({
            showGrid: false
        });
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    render() {
        const {CompanyNameU, CompanyStatusName} = this.state;
        return (
            <div className="page-container" id="ClsStatus-switch">
                <EventTracking category={"TRACKING_USER"} action={"W18F1019"} label={Config.profile.UserID}/>

                <Form id={'W18F1019'} horizontal={true}>
                    <FormGroup className="pdt10">
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <ButtonApprove name={`${Config.lang("CRM_Dong_y")}`} className={'pull-left'}
                                           onClick={() => this.saveData()}/>
                        </Col>
                    </FormGroup>
                    <div className={'PageStatusSwitch'}>
                        <FormGroup>
                            <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                <label className={'control-label'}>{Config.lang("CRM_Ten_khach_hang")}</label>
                            </Col>
                            <Col className={'pdSwitch control-label'} md={8} sm={8} xl={8} xs={8} lg={8}>
                                {CompanyNameU}
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                <label className={'control-label'}>{Config.lang("CRM_Trang_thai_cu")}</label>
                            </Col>
                            <Col className={'pdSwitch control-label'} md={8} sm={8} xl={8} xs={8} lg={8}>
                                {CompanyStatusName}
                            </Col>
                        </FormGroup>

                        <Row className="form-group">
                            <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                <label className={'control-label'}>{Config.lang("CRM_Trang_thai_moi")}</label>
                            </Col>
                            <Col md={8} sm={8} xl={8} xs={8} lg={8}>
                                <Combo
                                    dataSource={this.dataComboStatus}
                                    showClearButton={true}
                                    placeholder={Config.lang("CRM_Chon_combo")}
                                    valueExpr={'ItemID'}
                                    displayExpr={'DescriptionU'}
                                    onActionWhenSelectChange={(value) => {
                                        this.onChangeComboTransType(value)
                                    }}
                                >
                                    <Column dataField={'ItemID'} caption={Config.lang("Ma")} visible={false}
                                            dataType={'string'}/>
                                    <Column dataField={'DescriptionU'} caption={Config.lang("Ten")}
                                            dataType={'string'}/>
                                </Combo>
                            </Col>
                        </Row>

                        <FormGroup>
                            <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                <label className={'control-label'}>{Config.lang("CRM_Ghi_chu")}</label>
                            </Col>
                            <Col md={8} sm={8} xl={8} xs={8} lg={8}>
                                <Input placeholder={Config.lang("CRM_Mo_ta")} id={'ReasonTransfer'} type={'textarea'}
                                       rows={6}/>
                            </Col>
                        </FormGroup>
                    </div>
                </Form>

            </div>
        )
    }
}

W18F1026Page.propTypes = {
    onHide: PropTypes.func,
    onReloadGrid: PropTypes.func
};

export default connect(state => ({
        loadComboW18F1026: state.w18f1026.loadComboW18F1026,
        saveW18F1026: state.w18f1026.saveW18F1026,
    }),
    (dispatch) => ({
        w18F1026Actions: bindActionCreators(w18F1026Actions, dispatch)
    })
)(W18F1026Page);