/**
 * @copyright 2020 @ DigiNet
 * @author CANHTOAN
 * @create 2020/07/29 13:47
 * @update 2020/07/29 13:47
 */

import React from 'react';
import { FormGroup, Row, } from 'react-bootstrap';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w18f1027Actions from "../../actions/w18f1027-actions";
import { Col, Input, Label } from "reactstrap";
import { CheckBox } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import Config from '../../config/index';
import PropTypes from 'prop-types';
import EventTracking from "../common/tracking/event";
import ButtonSave from "../common/button/button-save";
import ButtonNotSave from "../common/button/button-notsave";

import moment from "moment";
import 'moment/locale/vi'
moment.locale('vi');

const cssForm = {margin: '0 15px 0 15px'};

class W18F1027Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            CompanyID : this.props.data.CompanyID,
            disabled  : false,
            Disabled  : 0,
            isSubmit  : false
        };
        // this.timer = null;
    };

    // componentWillUnmount() {
    //     if (this.timer) clearTimeout(this.timer);
    // }

    componentDidMount() {
        const { data } = this.props;
        this.setValueOnForm(data);
    }

    setValueOnForm = data => {
        if (!data) return;
        this.setState({
            ObjectAddressU : data.ObjectAddressU,
            ContactPersonU : data.ContactPersonU,
            TelNo          : data.TelNo,
            NoteU          : data.NoteU,
            Disabled       : data.Disabled,
            disabled       : data.mode === 'view' ? true : false
        })
    }

    onSave() {
        if (this.state.isSubmit) {
            return false
        }
        const { data }        = this.props;
        const { CompanyID, AddressID, ObjectID, ObjectTypeID } = data;
        const ObjectAddressU  = document.getElementById("ObjectAddressU").value.trim();
        const ContactPersonU  = document.getElementById("ContactPersonU").value;
        const TelNo           = document.getElementById("TelNo").value;
        const NoteU           = document.getElementById("NoteU").value;
        const Disabled        = this.state.Disabled;

        if(ObjectAddressU === "" || ObjectAddressU === undefined){
            Config.popup.show('INFO',`${Config.lang("CRM_Dia_chi_khong_duoc_de_trong")}`);
            document.getElementById("ObjectAddressU").focus();
            return false;
        }
        if(TelNo && !/^[+0]([0-9]{9,15})$/.test(TelNo.replace(/\s/g, ''))){
            Config.popup.show('INFO',`${Config.lang("CRM_So_dien_thoai_khong_hop_le")}`);
            document.getElementById("TelNo").focus();
            return false;
        }
        const param = {
            CompanyID     : CompanyID,
            AddressLine1U : '',
            ObjectAddressU: ObjectAddressU,
            ContactPersonU: ContactPersonU,
            TelNo         : TelNo,
            NoteU         : NoteU,
            Disabled      : Disabled,
        };
        let apiMode = 'addW18F1027';
        if (data.mode === 'edit') {
            param.AddressID = AddressID;
            apiMode = 'editW18F1027';
        } else if (data.mode === 'add') {
            param.ObjectID     = ObjectID;
            param.ObjectTypeID = ObjectTypeID;
        }
        this.setState({isSubmit: true});
        this.props.w18f1027Actions[apiMode](param, (error, data) => {
            if (error) {
                this.setState({isSubmit: false});
                const message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                this.onReloadGrid();
                this.onHide();
            }
        });
    }

    onDisabledChange(e) {
        this.setState({
            Disabled: e.value ? 1 : 0
        });
    }

    onHide() {
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    onReloadGrid() {
        if (this.props.onReloadGrid) {
            this.props.onReloadGrid();
        }
    }

    render() {
        const { disabled } = this.state;
        return (
            <form style={cssForm}>
                {Config && Config.profile && <EventTracking category={"TRACKING_USER"} action={"W18F1027"} label={Config.profile.UserID} /> }

                <div className="page-container">
                    <FormGroup className="mgt15" controlId="formBasicText">
                        <Row className="form-group">
                            <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                <Label>{Config.lang("CRM_Dia_chi")}<i className="error">*</i></Label>
                            </Col>
                            <Col md={10} sm={10} xl={10} xs={10} lg={10}>
                                <Input id={'ObjectAddressU'}
                                    placeholder={Config.lang("CRM_Dia_chi")}
                                    disabled={disabled}
                                    defaultValue={this.state.ObjectAddressU}/>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                <Label>{Config.lang("CRM_Nguoi_lien_he")}</Label>
                            </Col>
                            <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                <Input id={'ContactPersonU'}
                                    placeholder={Config.lang("CRM_Nguoi_lien_he")}
                                    disabled={disabled}
                                    defaultValue={this.state.ContactPersonU}/>
                            </Col>
                            <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                <Label>{Config.lang("CRM_So_dien_thoai")}</Label>
                            </Col>
                            <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                <Input id={'TelNo'}
                                    placeholder={Config.lang("CRM_So_dien_thoai")} disabled={disabled}
                                    defaultValue={this.state.TelNo}
                                    // onBlur={this.checkExistMobileNo}
                                />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                <Label>{Config.lang("CRM_Ghi_chu")}</Label>
                            </Col>
                            <Col md={10} sm={10} xl={10} xs={10} lg={10}>
                                <Input id={'NoteU'} type={'textarea'} rows={4}
                                    disabled={disabled}
                                    defaultValue={this.state.NoteU}/>
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <Col md={2} sm={2} xl={2} xs={2} lg={2}></Col>
                            <Col md={10} sm={10} xl={10} xs={10} lg={10}>
                                <CheckBox
                                    width={200}
                                    text={Config.lang("CRM_Khong_su_dung")}
                                    id={"Disabled"}
                                    disabled={disabled}
                                    onValueChanged={(e) => this.onDisabledChange(e)}
                                    value={!this.state.Disabled || this.state.Disabled === 0 ? false : true}
                                />
                            </Col>
                        </Row>
                        <FormGroup className={"jus-end"}>
                            <ButtonSave disabled={disabled || this.state.isSubmit} 
                                    onClick={() => !this.state.isSubmit && this.onSave()}
                                    className={"mgr5"} 
                                    name={Config.lang("CRM_Luu")} />
                            <ButtonNotSave disabled={disabled} 
                                    name={Config.lang("CRM_Khong_luu")} 
                                    onClick={() => {this.props.onHide()}} />
                        </FormGroup>
                    </FormGroup>
                </div>
            </form>
        )
    }
}

W18F1027Page.propTypes = {
    onHide       : PropTypes.func,
    onReloadGrid : PropTypes.func,
    data         : PropTypes.object
};

export default connect(() => ({}),
    dispatch => ({
        w18f1027Actions: bindActionCreators(w18f1027Actions, dispatch)
    })
)(W18F1027Page);
