/**
 * @copyright 2019 @ DigiNet
 * @author THANH TRAM
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import 'devextreme/dist/css/dx.common.css';
import {FormGroup, Row, Tab, Tabs,} from 'react-bootstrap';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w18f1031Actions from "../../actions/w18f1031-actions";
import {Col, Input, Label} from "reactstrap";
import ButtonSave from "../common/button/button-save";
import ButtonCustom from "../common/button/button-custom";
import {browserHistory} from "react-router";
import {CheckBox, DateBox} from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import Config from '../../config';
import Attachment from "../common/attachment/attachment";
import DropDownSaleGroup from "../common/dropdown/dropdown-sale-group";
import PopupView from "../popup/popup-view";
import TreeList, {Column, HeaderFilter, SearchPanel, Selection} from 'devextreme-react/tree-list';
import FileUploadAsync from "../../actions/file-upload-new-async";
// import FileUploadAsync from "../../actions/file-upload-async";
import FileDeleteAsync     from "../../actions/file-delete-async";
import FileRemoveAsync     from "../../actions/file-remove-async";
import * as fileActions    from "../../actions/file-actions";
import W18F1031AddAsync    from "../../actions/w18F1031-add-async";
import W18F1031DeleteAsync from "../../actions/w18F1031-delete-async";
import {Loading}           from "../common/loading/loading";
import EventTracking       from "../common/tracking/event";
import Combo               from "../common/dropdown/combo";
import moment              from "moment";
import 'moment/locale/vi'
import * as userActions    from "../../actions/user-actions";
import W17F0060Page        from "../../components/W17/W17F0060";

moment.locale('vi');

class W18F1031Page extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!this.getInfo()) return;
        this.person = '';
        this.state = {
            dataAttach: '',
            uploadedAttach: '',
            attached: '', //Du lieu da dinh kem
            loading: false,
            selectedTab: 1,
            SalesPersonName: '',
            JobPositionU: '',
            selectedGroupSalesID: '',
            BirthDate: '',
            Gender: '',
            Disabled: '',
            RetiredDate: null,
            IsManager: false,
            ContactStatus: '',
            MobileNo: '',
            OfficeTelNo: '',
            HomeAddress: '',
            Email: '',
            Email2: '',
            Skype: '',
            uploadingAttach: '',
            userID: '',
            inputValue: '',
            treeBoxValue: '1_1',
            imageW18F1031: '',
            gridSelectedRowKeys: [],
            isDisabled: false,
            isRetiredDate: false,
            person: '',
            iPermission: 0,
            iPermissionD17F1320: 0,
            showPopupW17F0060: false,
        };
        this.dataStatus = [];
        this.salesPersonName = '';
        this.selectedRowKeys = [];
        this.dataUser = [];
        this.timer = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 2000;
        this.treeView_onContentReady = this.treeView_onContentReady.bind(this);
    };

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    loadPermission() {
        this.props.userActions.getPermission(Config.profile.UserID, ["D17F1115", "D17F1320"], (arrPer) => {
            if (arrPer !== 0) {
                const iPerD17F1115 = arrPer.filter(data => data.ScreenID === 'D17F1115');
                const iPerD17F1320 = arrPer.filter(data => data.ScreenID === 'D17F1320');
                this.setState({
                    iPermission: iPerD17F1115 && iPerD17F1115.length > 0 && iPerD17F1115[0] ? iPerD17F1115[0].Permission : 0,
                    iPermissionD17F1320: iPerD17F1320 && iPerD17F1320.length > 0 && iPerD17F1320[0] ? iPerD17F1320[0].Permission : 0,
                });
            }
        })
    }

    getInfo = (flag) => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('salesPersonID')) {
            return {
                salesPersonID: url.get('salesPersonID'),
                getUserID: url.get('getUserID'),
                mode: url.get('mode'),
                disabled: url.get('disabled')
            };
        } else if (location && location.state) {
            return {
                salesPersonID: location.state.SalesPersonID,
                getUserID: location.state.UserID,
                mode: location.state.mode,
                disabled: location.state.Disabled,
            }
        } else {
            if (flag) {
                browserHistory.push(Config.env.url + '/W18F1030');
                return null;
            }
            return false;
        }
    };

    componentDidMount() {
        if (!this.getInfo()) return;
        this.loadPermission();
        this.setState({loading: true});
        this.timer = setTimeout(() => {
            this.setState({loading: false});
        }, this.timeDeplay);
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        const {salesPersonID, mode, getUserID, disabled} = this.getInfo();
        const mParam = {
            "UserID": uName,
            "Language": uLanguage,
            "SalesPersonID": salesPersonID,
            "Disabled": disabled,
        };
        this.props.w18f1031Actions.detailW18F1031(mParam, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("Tai_khoan_dang_nhap_khong_chinh_xac");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                return false;
            }
            if ((mode === 'edit' || mode === 'detail') && data && data.data && data.data.length > 0) {
                if (this.person === '') this.person = data.data[0];
                this.setState({
                    userID: data.data[0].UserID,
                    salesPersonID: data.data[0].SalesPersonID,
                    salesPersonNameU: data.data[0].SalesPersonNameU,
                    BirthDate: data.data[0].BirthDate,
                    RetiredDate: data.data[0].RetiredDate,
                    Gender: data.data[0].Gender,
                    Disabled: data.data[0].Disabled,
                    IsManager: data.data[0].IsManager,
                    selectedGroupSalesID: data.data[0].GroupSalesID
                });
                if (this.state.Disabled === 1) {
                    this.setState({
                        isRetiredDate: true
                    })
                }
            }
        });
        this.loadcomboGroupSales();
        this.loadComboStatus();

        this.loadDataAttach(salesPersonID);
        if (mode === 'edit' || mode === 'detail') {
            this.loadGroupSalesID();
        }
        this.loadComboUser(getUserID);
        this.setState({loading: false});

    }

    loadComboStatus() {
        this.props.w18f1031Actions.getStatusW18F1031({}, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data && data.data.length > 0) {
                this.dataStatus = data.data.map(o => {
                    return {
                        StatusType: o.StatusType,
                        StatusTypeName: o.StatusTypeName
                    };
                });
                const DisableID = data.data;
                if (!this.getInfo()) return;
                const {mode} = this.getInfo();
                if (mode === 'add') {
                    this.setState({Disabled: DisableID[0].StatusType});
                }

            }
        });
    }

    loadComboUser() {
        this.props.w18f1031Actions.actionW18F1031({}, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data.data.rows) {
                if (data.data.rows.length > 0) {
                    this.dataUser = data.data.rows.map(o => {
                        return {
                            UserID: o.UserID,
                            UserNameU: o.UserNameU
                        };
                    });
                    this.UserID = data.data.rows.UserID;
                } else {
                    return false;
                }
            } else {
                this.dataUser = [];
            }
            // if (data.data[0].length > 0) {
            //     this.UserID = data.data[0][0].UserID;
            // }
        });
    }


    loadcomboGroupSales() {
        const uName = Config.profile.UserID || '';
        const salesParam = {
            "UserID": uName,
        };
        this.props.w18f1031Actions.comboGroupSalesW18F1031(salesParam, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("Tai_khoan_dang_nhap_khong_chinh_xac");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            } else if (data && data.data && data.data.length > 0 && data.data[0] && data.data[0].length > 0 && data.data[0][0].GroupSalesID) {
                this.GroupSalesID = data.data[0][0].GroupSalesID;
            } else {
                return null;
            }
        });
    }

    onSaveW18F1031 = () => {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        const {mode} = this.getInfo();
        const gridSelectedRowKeys = [...this.state.gridSelectedRowKeys];

        const groupSalesID = this.state.selectedGroupSalesID;
        const userID = this.state.userID;
        let disabled = this.state.Disabled.toString();

        const salesPersonID = document.getElementById("SalesPersonID").value;
        const salesPersonNameU = document.getElementById("SalesPersonNameU").value;
        const jobPositionU = document.getElementById("JobPositionU").value;
        const gender = document.getElementById("Gender").value;
        const mobileNo = document.getElementById("MobileNo").value;
        const officeTelNo = document.getElementById("OfficeTelNo").value;
        const homeAddressU = document.getElementById("HomeAddressU").value;
        const email = document.getElementById("Email").value;
        const isManager = !this.state.IsManager || this.state.IsManager === "0" ? "0" : "1";
        const email2 = document.getElementById("Email2").value;
        const facebook = document.getElementById("Facebook").value;
        const Skype = document.getElementById("Skype").value;

        let birthdate = this.birthdate.instance.option('value') ? moment(this.birthdate.instance.option('value')).format('YYYY-MM-DD') : '';
        let retiredDate = this.retiredDate.instance.option('value') ? moment(this.retiredDate.instance.option('value')).format('YYYY-MM-DD') : '';
        if (mode === 'add') {
            if (!salesPersonID || salesPersonID === 'undefined') {
                Config.popup.show('INFO', `${Config.lang("CRM_Ma_nhan_vien_khong_duoc_de_trong")}`);
                document.getElementById("SalesPersonID").focus();
                return false;
            }
            if (!salesPersonNameU || salesPersonNameU === 'undefined') {
                Config.popup.show('INFO', `${Config.lang("CRM_Ho_va_ten_khong_duoc_de_trong")}`);
                document.getElementById("SalesPersonNameU").focus();
                return false;
            }
            if (!gender) {
                Config.popup.show('INFO', `${Config.lang("CRM_Gioi_tinh_khong_duoc_de_trong")}`);
                document.getElementById("Gender").focus();
                return false;
            }
            if (!userID) {
                Config.popup.show('INFO', `${Config.lang("CRM_Nguoi_dung_khong_duoc_de_trong")}`);
                document.getElementById("userID").focus();
                return false;
            }
            if (!groupSalesID) {
                Config.popup.show('INFO', `${Config.lang("CRM_Nhom_kinh_doanh_khong_duoc_de_trong")}`);
                document.getElementById("GroupSalesID").focus();
                return false;
            }

            this.setState({loading: true});
            this.timer = setTimeout(() => {
                this.setState({loading: false});
            }, this.timeDeplay);

            const params = {
                "SalesPersonID": salesPersonID,
                "UserID": userID,
                "SalesPersonNameU": salesPersonNameU,
                "JobPositionU": jobPositionU,
                "GroupSalesID": groupSalesID,
                "BirthDate": birthdate,
                "RetiredDate": retiredDate,
                "Gender": gender,
                "Disabled": disabled,
                "MobileNo": mobileNo,
                "OfficeTelNo": officeTelNo,
                "HomeAddressU": homeAddressU,
                "Email": email,
                "IsManager": isManager,
                "Email2": email2,
                "Facebook": facebook,
                "Skype": Skype,
            };
            this.props.w18f1031Actions.addW18F1031(params, async (error) => {
                if (error) {
                    let errorCode = error.code || null;
                    let message;
                    if (errorCode === "F1030E008") {
                        message = Config.lang("CRM_Ma_nhan_vien_nay_da_ton_tai");
                        Config.popup.show('INFO', message);
                    } else {
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                    }
                    this.setState({isSubmit: false, message: message, loading: false});
                    return false;
                }
                const file = document.querySelector('input[type=file]').files[0];
                if (file) {
                    const exp = file.name.split('.');
                    const uploadedAttach = {
                        uploading: false,
                        KeyID: document.getElementById('SalesPersonID').value,
                        FileName: file.name,
                        FileSize: file.size,
                        FileExp: exp[exp.length - 1],
                        TableName: 'D17T1110'
                    };
                    const upload = await FileUploadAsync(file, uploadedAttach);
                    this.setState({loading: false});
                    if (upload.code !== 200) {
                        let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        return false;
                    }
                }
                if (gridSelectedRowKeys.length > 0) {
                    for (let i = 0; i < gridSelectedRowKeys.length; i++) {
                        if (gridSelectedRowKeys[i]) {
                            const paramSale = {
                                "GroupSalesID": gridSelectedRowKeys[i],
                                "SalesPersonID": salesPersonID,
                            };
                            const addSalesGroup = await W18F1031AddAsync(paramSale);
                            if (addSalesGroup.code !== 200) {
                                Config.popup.show('INFO', `${Config.lang("CRM_Co_loi_trong_qua_trinh_xu_ly_du_lieu")}`);
                                return false
                            }
                        }
                    }
                }
                if (salesPersonID) {
                    const param = {
                        "UserID": uName,
                        "Language": uLanguage,
                        "SalesPersonID": salesPersonID,
                    };
                    this.props.w18f1031Actions.storeW18F1031(param, (error) => {
                        if (error) {
                            let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                            Config.popup.show('INFO', message);
                            return false;
                        }
                    });
                }
                this.setState({
                    isDisabled: true,
                });
                notify('Lưu thành công', "success", 2000);
            });
        } else {
            const groupSalesID = this.state.selectedGroupSalesID;
            const userID = this.state.userID;
            const gridSelectedRowKeys = [...this.state.gridSelectedRowKeys];
            const salesPersonID = document.getElementById("SalesPersonID").value;
            if (!salesPersonID || salesPersonID === 'undefined') {
                Config.popup.show('INFO', `${Config.lang("CRM_Ma_nhan_vien_khong_duoc_de_trong")}`);
                document.getElementById("SalesPersonID").focus();
                return false;
            }
            if (!salesPersonNameU || salesPersonNameU === 'undefined') {
                Config.popup.show('INFO', `${Config.lang("CRM_Ho_va_ten_khong_duoc_de_trong")}`);
                document.getElementById("SalesPersonNameU").focus();
                return false;
            }
            if (!gender) {
                Config.popup.show('INFO', `${Config.lang("CRM_Gioi_tinh_khong_duoc_de_trong")}`);
                document.getElementById("Gender").focus();
                return false;
            }
            if (!userID) {
                Config.popup.show('INFO', `${Config.lang("CRM_Nguoi_dung_khong_duoc_de_trong")}`);
                document.getElementById("userID").focus();
                return false;
            }
            if (!groupSalesID) {
                Config.popup.show('INFO', `${Config.lang("CRM_Nhom_kinh_doanh_khong_duoc_de_trong")}`);
                document.getElementById("GroupSalesID").focus();
                return false;
            }


            const params = {
                "SalesPersonID": salesPersonID,
                "UserID": userID,
                "SalesPersonNameU": salesPersonNameU,
                "JobPositionU": jobPositionU,
                "GroupSalesID": groupSalesID,
                "BirthDate": birthdate,
                "RetiredDate": retiredDate,
                "Gender": gender,
                "Disabled": disabled,
                "MobileNo": mobileNo,
                "OfficeTelNo": officeTelNo,
                "HomeAddressU": homeAddressU,
                "Email": email,
                "IsManager": isManager,
                "Email2": email2,
                "Facebook": facebook,
                "Skype": Skype,
            };
            this.setState({isSubmit: true, loading: true});
            this.timer = setTimeout(() => {
                this.setState({loading: false});
            }, this.timeDeplay);
            this.props.w18f1031Actions.editW18F1031(params, async (error) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    this.setState({isSubmit: false});
                    Config.popup.show("INFO", message);
                    return false;
                }
                if (JSON.stringify(this.state.gridSelectedRowKeys.sort()) !== JSON.stringify(this.selectedRowKeys.sort()) && this.selectedRowKeys.length) {
                    const {code} = await W18F1031DeleteAsync({"SalesPersonID": salesPersonID});
                    if (code !== 200) {
                        Config.popup.show('INFO', 'Có lỗi trong quá trình xử lý dữ liệu');
                        return false
                    }
                }
                for (let i = 0; i < gridSelectedRowKeys.length; i++) {
                    if (gridSelectedRowKeys[i]) {
                        const paramSale = {
                            "GroupSalesID": gridSelectedRowKeys[i],
                            "SalesPersonID": salesPersonID,
                        };
                        const addSalesGroup = await W18F1031AddAsync(paramSale);
                        if (addSalesGroup.code !== 200) {
                            Config.popup.show('INFO', `${Config.lang("CRM_Co_loi_trong_qua_trinh_xu_ly_du_lieu")}`);
                            return false
                        }
                    }
                }

                if (salesPersonID) {
                    const param = {
                        "UserID": uName,
                        "Language": uLanguage,
                        "SalesPersonID": salesPersonID,
                    };
                    this.props.w18f1031Actions.storeW18F1031(param, (error) => {
                        if (error) {
                            let message = error.message || "Lỗi chưa xác định";
                            Config.popup.show('INFO', message);
                            return false;
                        }
                    });
                }
                const file = document.querySelector('input[type=file]').files[0];
                let attached = [];
                if (file) {
                    const exp = file.name.split('.');
                    const uploadedAttach = {
                        uploading: false,
                        KeyID: document.getElementById('SalesPersonID').value,
                        FileName: file.name,
                        FileSize: file.size,
                        FileExp: exp[exp.length - 1],
                        TableName: 'D17T1110'
                    };
                    const upload = await FileUploadAsync(file, uploadedAttach);
                    if (upload.code !== 200) {
                        let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        return false;
                    }
                    const oldData = this.state.attached;
                    if (oldData.length > 0) {
                        const paramRemoveAttach = {
                            'path': oldData[0].URL
                        };
                        const paramDeleteAttach = {
                            'AttachmentID': oldData[0].AttachmentID
                        };
                        await FileDeleteAsync(paramDeleteAttach);

                        this.setState({loading: false});
                        // if (resDelete.code !== 200) {
                        //     Config.popup.show('INFO', `${Config.lang("CRM_Co_loi_khi_luu")}`);
                        //     return false;
                        // }
                        await FileRemoveAsync(paramRemoveAttach);
                        // if (resRemove.code !== 200) {
                        //     Config.popup.show('INFO', `${Config.lang("CRM_Co_loi_khi_luu")}`);
                        //     return false;
                        // }
                        this.setState({attached: attached});
                        // document.querySelector('input[type=file]').value = '';

                    }
                }
                this.selectedRowKeys = [...this.state.gridSelectedRowKeys];
                this.loadDataAttach(salesPersonID);
                notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
            });
        }
    };

    handleSelect(key) {

        this.setState({key: key});
    }

    syncTreeViewSelection(e) {
        this.setState({
            treeBoxValue: e.value
        });
        if (!this.treeView) return;
        if (!e.value) {
            this.treeView.instance.unselectAll();
        } else {
            this.treeView.instance.selectItem(e.value);
        }
    }

    treeView_onContentReady(e) {
        e.component.selectItem(this.state.treeBoxValue);
    }

    onIsManagerChanged(e) {
        this.setState({
            IsManager: e.value
        });
    }

    hideTabDocumentPopup() {
        this.setState({showAttachment: false});
    }

    loadGridTabDocument() {
        const companyID = window.location.search.split('CompanyID=')[1];
        const where = "KeyID='" + companyID + "' AND TableName='D17T1010'";
        const param = {
            "where": where,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
            "search": this.filter.search
        };
        this.props.w17Actions.loadW17F0001(param, (error) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });

    }

    onChangeComboUserID(keyID) {
        this.UserID = keyID;
        //this.loadGrid();
    }

    onChangeGroupSalesID(data) {
        this.GroupSalesID = data.GroupSalesID;
        //this.loadGrid();
    }

    upLoadAttach = (e) => {
        const fileData = e.target.files;
        const sizeLimit = 20;

        if (fileData.length === 0) {
            return null;
        } else if (this.checkFileType(fileData[0].type) === false) {
            Config.popup.show("INFO", `${Config.lang("CRM_Dinh_dang_file_khong_hop_le")}`);
        } else if ((fileData[0].size / 1024 / 1024) > Number(sizeLimit)) {
            Config.popup.show("INFO", `${Config.lang("CRM_Dung_luong_File_khong_duoc_lon_hon")}` + sizeLimit + ' MB');
        } else {
            let file = e.target.files[0];
            this.setState({
                dataAttach: file
            });
            this.previewFile();

        }
    }

    checkFileType(filetype) {
        const allowedExtensions = [
            'image/jpeg',
            'image/png',
            'image/jpg',
        ];
        if (filetype.length) {
            return allowedExtensions.indexOf(filetype) !== -1;
        } else {
            return false;
        }
    }

    previewFile() {
        const preview = document.getElementById('previewImgW18F1031');
        const reader = new FileReader();
        const file = document.querySelector('input[type=file]').files[0];
        reader.onloadend = function () {
            preview.src = reader.result;
        };
        if (file) {
            reader.readAsDataURL(file);
        } else {
            preview.src = "";
        }
    }

    treeView_itemSelectionChanged(e) {
        let newValue = e.component.getSelectedRowKeys("all").length > 0 ? e.component.getSelectedRowKeys("all") : null;
        if (newValue) {
            newValue = this.unique(newValue);
            // if (newValue) {
            this.setState({
                gridSelectedRowKeys: !newValue ? [] : newValue
            });
            // }
        } else {
            this.setState({
                gridSelectedRowKeys: []
            });
        }
    }

    unique(a) {
        return a.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
    }

    loadDataAttach(salesPersonID) {
        const param = {
            "salesPersonID": salesPersonID,
        };
        this.props.w18f1031Actions.loadDataAttchW18F1031(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show('INFO', message);
            } else {
                if (data.data) {
                    if (data.data.length > 0) {
                        this.setState({
                            attached: data.data
                        });
                    }
                } else {

                }
            }
        })
    }

    loadGroupSalesID() {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        const {salesPersonID} = this.getInfo();
        const paramGroup = {
            "UserID": uName,
            "Language": uLanguage,
            "SalesPersonID": salesPersonID,
        };
        this.props.w18f1031Actions.loadGroupSalesW18F1031(paramGroup, (error, res) => {
            if (error) {
                let errorCode = error.code || null;
                let message;
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            } else if (res && res.data && res.data.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                    if (this.selectedRowKeys.indexOf(res.data[i].GroupSalesID) < 0) {
                        this.selectedRowKeys.push(res.data[i].GroupSalesID);
                    }
                }
            }
        });
    }


    onChangeComboGroup(data) {
        if (data.length > 0) {
            this.setState({
                selectedGroupSalesID: data
            })
        }
    }

    onChangeComboUser(data) {
        const {mode} = this.getInfo();
        if(mode !== 'add') return;
        if (data) {
            this.salesPersonName = data.UserNameU;
            this.setState({
                salesPersonNameU: data.UserNameU,
                salesPersonID: data.UserID,
                userID: data.UserID,
            });
        }
    }

    checkRetiredDate() {
        const newDate = moment().toDate();

        if (this.state.Disabled === 1) {
            this.setState({
                RetiredDate: newDate,
                isRetiredDate: true,
            });
        } else {
            this.setState({
                RetiredDate: null,
                isRetiredDate: false,
            });
        }
    }

    showPopup = (FormID) => {
        if (FormID === "W17F0060") this.setState({showPopupW17F0060: true});
    };


    render() {
        if (!this.getInfo(true)) return null;
        const {loading, iPermission, iPermissionD17F1320, userID} = this.state;
        const {mode} = this.getInfo();
        const {comboGroupSalesW18F1031, loadDataAttchW18F1031} = this.props;
        const isLoginUser = userID === Config.profile.UserID;
        const isDetail = mode === "edit" || mode === "detail";

        return (
            <div id={'frmW18F1031'} className="page-container">
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W18F1031"} label={Config.profile.UserID}/>}

                {loading && <Loading/>}
                <div className="lb-cus-title">{Config.lang("CRM_Cap_nhat_nhan_vien_kinh_doanh1")}</div>
                <FormGroup>
                    <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                        <ButtonCustom color={'text-orange'} className={"mgr5"} icon={'fas fa-arrow-alt-left'}
                                      isAwesome={true}
                                      name={Config.lang("CRM_Quay_lai")} onClick={() => {
                            browserHistory.push(Config.env.url + '/W18F1030')
                        }}/>
                        <ButtonSave name={Config.lang("CRM_Luu")} className={"mgr5"}
                                    disabled={
                                        this.state.isDisabled
                                        || (iPermission <= 2 && isDetail && !isLoginUser)
                                    }
                                    onClick={() => this.onSaveW18F1031()}/>
                    </Col>

                </FormGroup>
                {this.state.showPopupW17F0060 &&
                <W17F0060Page
                    data={{
                        SalesPersonID: document.getElementById("SalesPersonID").value
                    }}
                    onHide={() => this.setState({showPopupW17F0060: false})}
                />}
                <div className="toolbar">
                    <Col md={2} sm={12} xl={12} xs={12} lg={2}>
                        <Row className="form-group" style={{width: "auto !important"}}>
                            <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                                <img id={'previewImgW18F1031'} className="imgSales"
                                     src={(loadDataAttchW18F1031 ? (loadDataAttchW18F1031[0] ? loadDataAttchW18F1031[0].URL : require('../../images/avatar-staff-default.png')) : require('../../images/avatar-staff-default.png'))}
                                     height="200" alt={"avatar"}/>
                                {/*<img id={'previewImgW18F1031'} className="imgSales"*/}
                                {/*     src={(this.state.dataAttach ? (loadDataAttchW18F1031[0] ? loadDataAttchW18F1031[0].URL : require('../../images/hinh3.png')) : require('../../images/hinh3.png'))}*/}
                                {/*     height="200" alt={"avatar"}/>*/}
                                <label
                                    className="fas fa-spinner fa-spin hide">{this.state.dataAttach && this.state.dataAttach.name}</label>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                                <ButtonCustom icon={'fas fa-cloud-upload'} isAwesome={true} className={'mgl5 mgdl'}
                                              color={'text-green'} name={Config.lang("CRM_Chon_hinh")}
                                              onClick={() => {
                                                  document.getElementById('attachmentW18F1031').click();
                                              }}
                                />
                                {this.state.showAttachment && <PopupView
                                    title={Config.lang("CRM_Mau_dinh_kem")}
                                    show={true}
                                    animation={true}
                                    onHide={() => this.setState({showAttachment: false})}>
                                    <Attachment data={this.state.row}/>
                                </PopupView>}
                                <input id={'attachmentW18F1031'} type="file"
                                       className="fas fa-spinner fa-spin media_upload_input hide"
                                       onChange={this.upLoadAttach}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={10} sm={12} xl={12} xs={12} lg={10}>
                        <Tabs
                            activeKey={this.state.key}
                            onSelect={(key) => this.handleSelect(key)}
                            id="controlled-tab-example"
                            style={{minWidth:'auto'}}
                        >

                            <Tab className="pd5" eventKey={1} title={Config.lang("CRM_Thong_tin_chung")}>
                                <Row className="form-group pdt10">
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                        <Label>{Config.lang("CRM_Nguoi_dung")}<i className="error">*</i></Label>
                                    </Col>
                                    <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                        <Combo
                                            id={'userID'}
                                            showHeader={false}
                                            showClearButton={true}
                                            dataSource={this.dataUser}
                                            placeholder={"--Chọn--"}
                                            disabled={isDetail}
                                            value={this.state.userID}
                                            valueExpr="UserID"
                                            displayExpr="UserNameU"
                                            //onChange={(e) => {this.onChangeUserID(e)}}
                                            onActionWhenSelectChange={(data) => {
                                                this.onChangeComboUser(data)
                                            }}
                                        >
                                            <Column dataField={'UserID'} caption="" width={'100px'} visible={false}
                                                    dataType={'string'}/>
                                            <Column dataField={'UserNameU'} caption="" width={'100%'}
                                                    dataType={'string'}/>
                                        </Combo>
                                        {/*<select id={'userID'}*/}
                                        {/*disabled={mode === 'detail'}*/}
                                        {/*className={'form-control'}*/}
                                        {/*value={this.state.userID}*/}
                                        {/*//onChange={(e) => {this.onChangeUserID(e)}}*/}
                                        {/*>*/}
                                        {/*<option value={''}>-- Chọn --</option>*/}
                                        {/*{cmbPerson && cmbPerson.map((v, i) => {*/}
                                        {/*return (*/}
                                        {/*<option key={i}*/}
                                        {/*value={v.UserID}>{v.UserNameU}</option>)*/}
                                        {/*})}*/}
                                        {/*</select>*/}
                                    </Col>
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                        <Label>{Config.lang("CRM_Ma_nhan_vien")}<i className="error">*</i></Label>
                                    </Col>
                                    <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                        <Input id={'SalesPersonID'}
                                               onPaste={(e) => {
                                                   document.getElementById('SalesPersonID').value = Config.replaceSymbol(e.target.value);
                                               }}

                                               onChange={(e) => {
                                                   document.getElementById('SalesPersonID').value = Config.replaceSymbol(e.target.value);
                                               }}
                                               onKeyDown={(e) => {
                                                   if (e.which === 32) {
                                                       e.preventDefault();
                                                   }
                                               }}
                                               disabled={isDetail}
                                               defaultValue={this.state.salesPersonID}
                                        >
                                        </Input>
                                    </Col>
                                </Row>

                                <Row className="form-group pdt5">
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                        <Label>{Config.lang("CRM_Ho_va_ten")}<i className="error">*</i></Label>
                                    </Col>
                                    <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                        <Input id={'SalesPersonNameU'}
                                               disabled={isDetail && iPermission <= 2 && !isLoginUser}
                                               defaultValue={this.state.salesPersonNameU}/>
                                    </Col>
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                        <Label>{Config.lang("CRM_Chuc_danh")}</Label>
                                    </Col>
                                    <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                        <Input id={'JobPositionU'}
                                               disabled={isDetail && iPermission < 3}
                                               defaultValue={this.person.JobPositionU}/>
                                    </Col>
                                </Row>

                                <Row className="form-group pdt5">
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                        <Label>{Config.lang("CRM_Nhom_kinh_doanh")}<i className="error">*</i></Label>
                                    </Col>
                                    <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                        <DropDownSaleGroup
                                            disabled={isDetail && iPermission < 3}
                                            id={"GroupSalesID"}
                                            defaultValue={this.state.selectedGroupSalesID}
                                            placeholder={Config.lang("CRM_Nhom_kinh_doanh")}
                                            value={this.state.selectedGroupSalesID}
                                            onChange={(value) => {
                                                this.onChangeComboGroup(value)
                                            }}
                                        />
                                    </Col>
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                        <CheckBox
                                            width={200}
                                            text={Config.lang("CRM_Quan_ly")}
                                            id={"IsManager"}
                                            disabled={isDetail && iPermission < 3}
                                            onValueChanged={(e) => this.onIsManagerChanged(e)}
                                            value={!(!this.state.IsManager || this.state.IsManager === "0")}
                                        />
                                    </Col>
                                </Row>

                                <Row className="form-group pdt5">
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                        <Label>{Config.lang("CRM_Ngay_sinh")}</Label>
                                    </Col>
                                    <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                        <DateBox
                                            width={"100%"}
                                            ref={(ref) => this.birthdate = ref}
                                            id={'BirthDate'}
                                            openOnFieldClick={true}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            value={this.state.BirthDate}
                                            type={'date'}
                                            pickerType={"calendar"}
                                            showAnalogClock={false}
                                            displayFormat={'d/MM/y'}
                                            disabled={isDetail && iPermission <= 2 && !isLoginUser}
                                            onValueChanged={(e) => {
                                                this.setState({
                                                    BirthDate: e.value
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                        <Label>{Config.lang("CRM_Gioi_tinh")}<i className="error">*</i></Label>
                                    </Col>
                                    <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                        <Input type={'select'}
                                               id={'Gender'}
                                               value={this.state.Gender}
                                               disabled={isDetail && iPermission <= 2 && !isLoginUser}
                                               onChange={(e) => {
                                                   this.setState({Gender: e.target.value});
                                               }}
                                        >
                                            <option value="">-- Chọn --</option>
                                            <option value="0">{Config.lang("CRM_Nu")}</option>
                                            <option value="1">{Config.lang("CRM_Nam1")}</option>
                                            <option value="2">{Config.lang("CRM_Khac")}</option>
                                        </Input>
                                    </Col>
                                </Row>

                                <Row className="form-group pdt5">
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                        <Label>{Config.lang("CRM_Email_cong_ty")}</Label>
                                    </Col>
                                    <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                        <Input id={'Email'}
                                               disabled={isDetail && iPermission <= 2 && !isLoginUser}
                                               placeholder="xdir@diginet.com.vn"
                                               defaultValue={this.person.Email}/>
                                    </Col>
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                        <Label>{Config.lang("CRM_Email_ca_nhan")}</Label>
                                    </Col>
                                    <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                        <Input id={'Email2'}
                                               disabled={isDetail && iPermission <= 2 && !isLoginUser}
                                               placeholder="xdir@gmail.com.vn"
                                               defaultValue={this.person.Email2}/>
                                    </Col>
                                </Row>

                                <Row className="form-group pdt5">
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                        <Label>{Config.lang("CRM_Dien_thoai_di_dong")}</Label>
                                    </Col>
                                    <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                        <Input id={'OfficeTelNo'}
                                               disabled={isDetail && iPermission <= 2 && !isLoginUser}
                                               placeholder="0798 456 248"
                                               defaultValue={this.person.OfficeTelNo}/>
                                    </Col>
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                        <Label>{Config.lang("CRM_Dien_thoai_cong_ty")}</Label>
                                    </Col>
                                    <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                        <Input id={'MobileNo'}
                                               disabled={isDetail && iPermission <= 2 && !isLoginUser}
                                               placeholder="038 7895 012"
                                               defaultValue={this.person.MobileNo}/>
                                    </Col>
                                </Row>

                                <Row className="form-group pdt5">
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                        <Label>{Config.lang("CRM_Dia_chi")}</Label>
                                    </Col>
                                    <Col md={10} sm={10} xl={10} xs={10} lg={10}>
                                        <Input id={'HomeAddressU'}
                                               disabled={isDetail && iPermission <= 2 && !isLoginUser}
                                               defaultValue={this.person.HomeAddressU}/>
                                    </Col>
                                </Row>

                                <Row className="form-group pdt5">
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                        <Label>{Config.lang("CRM_Facebook")}</Label>
                                    </Col>
                                    <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                        <Input id={'Facebook'}
                                               disabled={isDetail && iPermission <= 2 && !isLoginUser}
                                               placeholder="https://www.facebook.com/utybfj"
                                               defaultValue={this.person.Facebook}/>
                                    </Col>
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                        <Label>{Config.lang("Skype")}</Label>
                                    </Col>
                                    <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                        <Input id={'Skype'}
                                               disabled={isDetail && iPermission <= 2 && !isLoginUser}
                                               placeholder="live:nguyenvanb1206"
                                               defaultValue={this.person.Skype}/>
                                    </Col>
                                </Row>


                                <Row className={mode === 'detail' ? 'hide' : 'form-group pdt'}>
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                        <Label>{Config.lang("CRM_Trang_thai")}</Label>
                                    </Col>
                                    <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                        <Combo
                                            id={'Disabled'}
                                            showHeader={false}
                                            showClearButton={true}
                                            dataSource={this.dataStatus}
                                            height={'110px'}
                                            placeholder={"--Chọn--"}
                                            // disabled={mode === 'edit' || mode === 'detail'}
                                            value={this.state.Disabled}
                                            valueExpr="StatusType"
                                            displayExpr="StatusTypeName"
                                            disabled={isDetail && iPermission < 3}
                                            //onChange={(e) => {this.onChangeUserID(e)}}
                                            onActionWhenSelectChange={(data) => {
                                                this.setState({Disabled: data.StatusType});
                                                this.checkRetiredDate();
                                            }}
                                        >
                                            <Column dataField={'StatusType'} caption="" width={'100px'} visible={false}
                                                    dataType={'string'}/>
                                            <Column dataField={'StatusTypeName'} caption="" width={'100%'}
                                                    dataType={'string'}/>
                                        </Combo>
                                    </Col>
                                    <div className={this.state.isRetiredDate === false ? "hide" : ''}>
                                        <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                                            <Label>{Config.lang("CRM_Ngay_nghi_viec")}</Label>
                                        </Col>
                                        <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                                            <DateBox
                                                width={"100%"}
                                                ref={(ref) => this.retiredDate = ref}
                                                id={'RetiredDate'}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                value={this.state.RetiredDate}
                                                type={'date'}
                                                pickerType={"calendar"}
                                                showAnalogClock={false}
                                                displayFormat={'d/MM/y'}
                                                disabled={isDetail && iPermission < 3}
                                                onValueChanged={(e) => {
                                                    this.setState({
                                                        RetiredDate: e.value
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </div>
                                </Row>

                            </Tab>
                            <Tab eventKey={2} title={Config.lang("CRM_Nhom_phan_quyen")} disabled={!iPermissionD17F1320}>
                                <div className={'form'}>
                                    {comboGroupSalesW18F1031 && <TreeList
                                        dataSource={comboGroupSalesW18F1031.data}
                                        disabled={iPermissionD17F1320 <= 1}
                                        showBorders={true}
                                        columnAutoWidth={true}
                                        wordWrapEnabled={true}
                                        keyExpr={'GroupSalesID'}
                                        defaultSelectedRowKeys={this.selectedRowKeys}
                                        parentIdExpr={'GroupSalesParentID'}
                                        autoExpandAll={true}
                                        showColumnHeaders={false}
                                        id={'GroupSalesID'}
                                        // onContentReady={(e) => {this.defaultSelected(e)}}
                                        onSelectionChanged={(e) => {
                                            this.treeView_itemSelectionChanged(e)
                                        }}
                                        onCellPrepared={(e) => {
                                            if (iPermissionD17F1320 === 2 && e.data?.Disabled === 1) {
                                                e.cellElement.classList.add("dx-state-disabled");
                                            }
                                        }}
                                    >
                                        <SearchPanel placeholder={Config.lang("CRM_Noi_dung_can_tim")} visible={false}
                                                     width={250}/>
                                        <HeaderFilter visible={false}/>
                                        <Selection mode={'multiple'}
                                                   allowSelectAll={false}
                                                   recursive={true}
                                        />
                                        <Column caption={Config.lang("")} allowSorting={false} showColumnHeaders={false}
                                                dataField={'GroupSalesNameU'}/>
                                    </TreeList>}
                                </div>
                            </Tab>
                        </Tabs>
                    </Col>
                    <Col xs={12} sm={12}>
                        <FormGroup>
                            <ButtonCustom color={'text-orange'}
                                          className={"pull-right mgt15"}
                                          icon={'fa fa-envelope'}
                                          isAwesome={true}
                                          name={Config.lang("Thiet_lap_mail")}
                                          onClick={() => this.showPopup("W17F0060")}
                            />
                        </FormGroup>
                    </Col>
                </div>
            </div>
        )
    }
}

export default connect(state => ({
        detailW18F1031: state.w18f1031.detailW18F1031,
        storeW18F1031: state.w18f1031.storeW18F1031,
        addW18F1031: state.w18f1031.addW18F1031,
        editW18F1031: state.w18f1031.editW18F1031,
        actionW18F1031: state.w18f1031.actionW18F1031,
        comboGroupSalesW18F1031: state.w18f1031.comboGroupSalesW18F1031,
        loadDataAttchW18F1031: state.w18f1031.loadDataAttchW18F1031,
        loadGroupSalesW18F1031: state.w18f1031.loadGroupSalesW18F1031,
        getStatusW18F1031: state.w18f1031.getStatusW18F1031,
    }),
    (dispatch) => ({
        w18f1031Actions: bindActionCreators(w18f1031Actions, dispatch),
        fileActions: bindActionCreators(fileActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    })
)(W18F1031Page);
