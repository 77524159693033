/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/27/02 17:00
 * @update 2019/27/02 17:00
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w18f1040Actions from "../../actions/w18f1040-actions";
import * as userActions from "../../actions/user-actions";
import Config from '../../config/index';
import notify from 'devextreme/ui/notify';
import DetailW18F1041 from "../W18/W18F1041";
import GridContainer from "../common/grid-container/grid-container";
import GridActionBar from "../common/action-bar/grid-action-bar";
import {Column} from 'devextreme-react/data-grid';
import PopupView from "../popup/popup-view";
import EventTracking from "../common/tracking/event";

class W18F1040Page extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
            iPermission: 0,
            // showAttachment:false,
            columns: [
                {name: 'NormID', title: 'Mã loại thông tin'},
                {name: 'Description', title: 'Mô tả'},
                {name: 'Disabled', title: 'Ngừng hoạt động'},
            ],
            rows: [],
            callAdd: false,
            row: {
                NormID: '',
                NormNameU: '',
                ControlType: '',
                Disabled: false,
                Description: '',
                ControlTypeName: '',
                NormType: '',
                mode: '',
            }
        };
        this.dataSoureW = [];
        this.totalItems = 0;
        this.timer = null;
        this.filter = {
            skip: 0,
            limit: 20,
            search: ''
        };
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if(this.state.iPermission <= 0) return;
        this.loadGrid();
    };

    hideAddNewPopup() {
        this.setState({showPopup: false});
        this.loadGrid();
    }

    loadGrid() {
        let param = {
            "skip": this.filter.skip,
            "limit": this.filter.limit,
            "search": this.filter.search
        };
        this.props.w18f1040Actions.loadW18F1040(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                if (data.data.length > 0) {
                    this.dataSoureW = data.data.map(o => {
                        const row = {
                            NormID: o.NormID,
                            NormNameU: o.NormNameU,
                            ControlType: o.ControlType,
                            Disabled: o.Disabled,
                            IsCustomer: o.IsCustomer,
                            IsRegion: o.IsRegion,
                            ControlTypeName: o.ControlTypeName,
                            Description: o.Description,
                            NormType: o.NormType,
                        };
                        this.totalItems = o.TotalRecord;
                        return row;
                    });
                    this.NormID = data.data.NormID;
                } else {
                    return false;
                }
            }
        });
    }

    onEdit(row) {
        const rowEdit = {
            NormID: row.NormID,
            NormNameU: row.NormNameU,
            ControlType: row.ControlType,
            NormType: row.NormType,
            Description: row.Description,
            Disabled: row.Disabled,
            ControlTypeName: row.ControlTypeName,
            IsCustomer: row.IsCustomer,
            IsRegion: row.IsRegion,
            mode: 'edit'
        };
        this.setState({row: rowEdit, showPopup: true});
    }

    onAddNew() {
        const rowNew = {
            NormID: '',
            NormNameU: '',
            ControlType: '',
            Disabled: false,
            Description: '',
            ControlTypeName: '',
            NormType: '',
            IsCustomer: true,
            IsRegion: '',
            mode: 'add'
        };
        this.setState({row: rowNew, showPopup: true});

    }

    onDetail(row) {
        const rowDetail = {
            NormID: row.NormID,
            NormNameU: row.NormNameU,
            ControlType: row.ControlType,
            NormType: row.NormType,
            Description: row.Description,
            ControlTypeName: row.ControlTypeName,
            Disabled: row.Disabled,
            IsCustomer: row.IsCustomer,
            IsRegion: row.IsRegion,
            mode: 'detail'
        };
        this.setState({row: rowDetail, showPopup: true});
    }

    onCheckDelete(query, cb) {
        this.props.w18f1040Actions.checkDeleteW18F1040(query, (error, data) => {
            let message = "";
            if (error) {
                message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
            } else {
                if (data) {
                    Config.popup.show('INFO', `${Config.lang("CRM_Ma_nay_da_duoc_su_dung_ban_khong_duoc_phep_xoa")}`);
                } else {
                    cb && cb();
                }
            }
        });
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F0072",(iPer)=>{
            this.setState({iPermission: iPer});
        });
    }

    renderAction(e) {
        if (!e) return null;
        return (
            <GridActionBar
                isPer={this.state.iPermission}
                tooltipEdit={"Sửa"}
                onEditAction={() => {
                    this.onEdit(e.row.data)
                }}

                tooltipDelete={"Xóa"}
                onDeleteAction={() => {
                    this.onDelete(e.row.data)
                }}/>
        );
    }

    onDelete(row) {
        Config.popup.show("YES_NO", `${Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay")}`, () => {
            const normID = row.NormID;
            const query = {
                "normID": normID
            };
            // let sqlCheck = `SELECT TOP 1 1 FROM D17T1011 WHERE  NormID = '${normID}'`;
            // query.sql = sqlCheck;
            this.onCheckDelete(query, () => {
                const param = {
                    "normID": normID,
                };
                this.props.w18f1040Actions.deleteW18F1040(param, (error, data) => {
                    if (error) {
                        let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        return false;
                    } else {
                        notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                        this.loadGrid();
                    }
                });
            });
        });

    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    onSearch(text) {
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadGrid();
    }

    renderNormNameU(data) {
        if (!data) return null;
        const name = data && data.value ? data.value : '';

        /* eslint-disable */
        return (
            <div><a onClick={() => this.onDetail(data.data)}>{name}</a></div>
        );
        /* eslint-enable */
    }

    render() {

        return (
            <div className="page-container">
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W18F1040"} label={Config.profile.UserID}/>}

                {this.dataSoureW && <GridContainer
                    title={Config.lang("CRM_Danh_sach_thong_tin_bo_sung")}
                    dataSource={this.dataSoureW}
                    onAddNew={() => {
                        this.onAddNew()
                    }}
                    isPer={this.state.iPermission}
                    totalItems={this.totalItems}
                    itemPerPage={this.filter.limit}
                    onSearch={(text) => {
                        this.onSearch(text)
                    }}
                    onChangePage={(page) => {
                        this.onChangePage(page)
                    }}
                    onChangePerPage={(per) => {
                        this.onChangePerPage(per)
                    }}
                >
                    <Column
                        cellRender={(data) => this.renderAction(data)}
                        dataField={'Action'} caption={Config.lang("CRM_Hanh_dong")}
                        alignment={'center'}
                        allowSorting={false}
                        showInColumnChooser={false}
                        width={120}
                        fixed={true}
                    />
                    <Column dataField={'NormID'} visible={true} width={240} dataType={'string'} fixed={true}/>
                    <Column dataField={'NormNameU'} caption={Config.lang("CRM_Ten_loai_thong_tin")}
                            cellRender={(data) => this.renderNormNameU(data)} minWidth={300} width={"100%"}/>
                    <Column dataField={'ControlTypeName'} caption={Config.lang("CRM_Kieu_du_lieu")} width={200}
                            dataType={'string'}/>
                    <Column dataField={'Disabled'} caption={Config.lang("CRM_Khong_su_dung")} width={160}
                            dataType={'boolean'}/>
                </GridContainer>}
                {this.state.showPopup && <PopupView
                    title={Config.lang("CRM_Cap_nhat_thong_tin_bo_sung")}
                    className={'popup-size-90'}
                    show={true}
                    animation={true}
                    onHide={() => this.hideAddNewPopup()}>
                    <DetailW18F1041 data={this.state.row} onHide={() => this.hideAddNewPopup()}
                                    onReloadGrid={() => this.loadGrid()}/>
                </PopupView>}
            </div>
        )
    }
}

export default connect(state => ({
        loadW18F1040: state.w18f1040.loadW18F1040,
        deleteW18F1040: state.w18f1040.deleteW18F1040,
    }),
    (dispatch) => ({
        w18f1040Actions: bindActionCreators(w18f1040Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W18F1040Page);