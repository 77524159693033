/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 2021/12/10 08:08
 * @update 2021/12/10 08:08
 */

import _ from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import TextareaAutosize from 'react-autosize-textarea';
import { Image } from 'react-bootstrap';
import { connect } from "react-redux";
import { Col } from "reactstrap";
import { bindActionCreators } from "redux";
import * as templateDropdownActions from "../../actions/template-dropdown-actions";
import * as w18f1061Actions from "../../actions/w18F1061-actions";
import Config from '../../config/index';
class W18F1061_ItemComment extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            idEdit: "",
            showComment: false,
            dataComment: [],
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { CodeID = "" } = this.props;
        if (prevProps.CodeID !== CodeID) this.loadComment();
    }

    loadComment = () => {
        const { CodeID } = this.props;
        if (_.isEmpty(CodeID)) return;
        const params = {
            FormID: "W18F1061",
            CodeID
        };
        this.props.w18f1061Actions.getListComment(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                if (!_.isEmpty(data)) this.setState({ dataComment: data });
            }
        });
    };

    onEnterPress = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            this.addComment(_.get(e, "target.value", ""));
        }
    };

    addComment = (txt = "") => {
        const { formID: FormAction = "", FormID, CodeID } = this.props;
        const { dataComment } = this.state;
        const params = {
            FormID,
            FormAction,
            CodeID,
            Message: txt
        };
        this.props.w18f1061Actions.addComment(params, (err, data) => {
            if (err) {
                Config.popup.show("INFO", err.message || Config.lang("Loi_chua_xac_dinh"));
            } else if (data) {
                if (!_.isEmpty(data)) {
                    const dataTemp = [...dataComment, data];
                    this.setState({ dataComment: dataTemp });
                    document.querySelector('#inputComment').value = "";
                }
            }
        });
    };

    editComment = (item = {}, text = "") => {
        this.setState({ idEdit: "" });
        const { formID: FormAction = "", FormID, CodeID } = this.props;
        const { dataComment } = this.state;
        const params = {
            FormID,
            FormAction,
            CodeID,
            CommentID: _.get(item, "CommentID", ""),
            Message: text
        };
        this.props.w18f1061Actions.editComment(params, (error, data) => {
            if (error) {
                Config.popup.show("INFO", error.message || Config.lang("Loi_chua_xac_dinh"));
            } else if (data) {
                if (!_.isEmpty(data)) {
                    let dataTemp = [...dataComment];
                    dataTemp.forEach((i, idx) => {
                        if (i.CommentID === data[0].id) {
                            dataTemp[idx].updatedAt = data[0].updatedAt;
                            dataTemp[idx].Message = data[0].Message;
                            this.setState({ dataComment: dataTemp });
                        }
                    });
                }
            }
        });
    };

    removeComment = (item = {}) => {
        const { dataComment } = this.state;
        this.props.w18f1061Actions.removeComment({ CommentID: _.get(item, "CommentID", "") }, (error, data) => {
            if (error) {
                Config.popup.show("INFO", error.message || Config.lang("Loi_chua_xac_dinh"));
            } else if (data) {
                if (!_.isEmpty(data)) {
                    let dataTemp = [...dataComment];
                    dataTemp.forEach((i, idx) => {
                        if (i.CommentID === data.id) {
                            dataTemp.splice(idx, 1);
                            this.setState({ dataComment: dataTemp });
                        }
                    });
                }
            }
        });
    };

    render() {
        const { showComment, idEdit, dataComment } = this.state;

        return (
            <>
                <label className={"control-label-above"}
                    style={{ cursor: 'pointer', color: '#0073b7' }}
                    onClick={() => {
                        this.setState({ showComment: !showComment });
                    }}
                >
                    <i className={showComment ? "fas fa-chevron-circle-up" : "fas fa-chevron-circle-down"}
                        style={{ marginRight: 10, color: '#0073b7' }}
                    />
                    {Config.lang("Thao_luan")} ({dataComment.length})
                </label>
                <AnimateHeight
                    duration={500}
                    height={showComment ? 'auto' : 0} // see props documentation below
                >
                    {!_.isEmpty(dataComment) && dataComment.map((item, idx) => {
                        return <ItemComment
                            idEdit={idEdit}
                            onChangeIdEdit={(id) => {
                                this.setState({ idEdit: id });
                            }}
                            data={item}
                            key={idx}
                            createdAt={item.createdAt}
                            editComment={(txtEdit) => this.editComment(item, txtEdit)}
                            addComment={(txtEdit) => this.addComment(txtEdit)}
                            removeComment={() => this.removeComment(item)}
                        />
                    })}
                    <Col md={12} sm={12} xs={12} lg={12} className={"display_row"}
                        style={{ margin: '0 -15px', width: 'calc(100% + 30px)' }}>
                        <Image src={Config.profile.URL || require('../../images/user3.jpg')}
                            style={{
                                width: 34,
                                height: 34,
                                borderRadius: 100,
                                marginRight: 10
                            }}
                        />
                        <TextareaAutosize className={"form-control"}
                            id={"inputComment"}
                            onKeyDown={this.onEnterPress}
                            style={{ width: '100%', minHeight: 35, borderRadius: 4 }}
                            placeholder={Config.lang("Viet_binh_luan")}
                        />
                    </Col>
                </AnimateHeight>
            </>
        )
    }
}

class ItemComment extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            isTooltip: false,
        }
    }

    cancelEdit = () => {
        const { onChangeIdEdit } = this.props;
        this.setState({ isEdit: false });
        if (onChangeIdEdit) onChangeIdEdit(null);
    };

    showEditComment = () => {
        const { onChangeIdEdit, data } = this.props;
        this.setState({ isEdit: true });
        this.hideDropdownMenu();
        if (onChangeIdEdit) onChangeIdEdit(_.get(data, "CommentID", ""));
    };

    hideDropdownMenu = () => {
        this.setState({ isTooltip: false }, () => {
            document.removeEventListener("click", this.hideDropdownMenu);
        });
    };

    editComment = (value) => {
        const { editComment } = this.props;
        this.setState({ isEdit: true });
        if (editComment) editComment(value);
    };

    removeComment = () => {
        const { removeComment } = this.props;
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            if (removeComment) removeComment();
        });
        this.setState({ isTooltip: false });
    };

    onEnterPress = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            this.editComment(e.target.value);
        }
    };

    render() {
        const { data, idEdit } = this.props;
        const { isEdit, isTooltip } = this.state;
        if (!data.SalesPersonNameU && !data.URL) {
            const user = Config.getUser({ UserID: data.UserID });
            if (user) {
                data.URL = user ? user.URL : null;
                data.SalesPersonNameU = user ? user.SalesPersonNameU : null;
            }
        }
        return (
            <div className={"display_row"}
                style={{ marginBottom: 15 }}>
                <Image src={data?.URL || require('../../images/user3.jpg')}
                    style={{ width: 40, height: 40, borderRadius: 100, marginRight: 10 }} />
                {(!isEdit || idEdit !== _.get(data, "CommentID", "")) &&
                    <div style={{
                        position: 'relative',
                        background: 'rgb(248, 249, 251)',
                        borderRadius: 10,
                        padding: '10px 20px'
                    }}>
                        <b style={{ marginRight: 5, color: '#1D4C72' }}>{_.get(data, "SalesPersonNameU", "")}</b>
                        <i style={{
                            fontSize: 10,
                            color: 'grey',
                            lineHeight: '21px'
                        }}>{moment(_.get(data, "createdAt", "")).format("DD/MM/YYYY[,]HH:mm:ss")}</i>
                        <div style={{ fontSize: 13, whiteSpace: 'pre-line' }}>{_.get(data, "Message", "")}</div>
                        {isTooltip &&
                            <div className={"tooltipComment"} >
                                <div className={"func-tooltip"} style={{ cursor: 'pointer' }} onClick={this.showEditComment}>
                                    <i className={"fa fa-pencil"} /> {Config.lang("Sua")}
                                </div>
                                <div className={"func-tooltip"} style={{ cursor: 'pointer' }} onClick={this.removeComment}>
                                    <i className={"fa fa-trash"} /> {Config.lang("Xoa")}
                                </div>
                            </div>
                        }
                        {Config.profile.UserID === _.get(data, "UserID", "") &&
                            <i style={{ position: 'absolute', right: -20, top: 22, color: 'grey', cursor: 'pointer' }}
                                className={"fa fa-ellipsis-h"} onClick={() => {
                                    this.setState({ isTooltip: !isTooltip }, () => {
                                        document.addEventListener("click", this.hideDropdownMenu);
                                    });
                                }}
                            />
                        }
                    </div>
                }
                {isEdit && _.get(data, "CommentID", "") === idEdit &&
                    <div style={{ position: 'relative', width: '100%' }}>
                        <TextareaAutosize
                            className={"form-control"}
                            style={{ width: '100%', borderRadius: 4, minHeight: 35 }}
                            defaultValue={_.get(data, "Message", "")}
                            onKeyDown={this.onEnterPress}
                            placeholder={Config.lang("Viet_binh_luan")} />
                        <span style={{ position: 'absolute', bottom: 5, right: 20, zIndex: 1, fontSize: 12, color: '#1D4C72', cursor: 'pointer' }}
                            onClick={this.cancelEdit}>{Config.lang("Huy")}</span>
                    </div>
                }
            </div>
        )
    }
}

W18F1061_ItemComment.propTypes = {
    FormID: PropTypes.string,
    CodeID: PropTypes.string
};

export default connect(state => ({
    cboSalePerson: state.templateDropdown.cboSalePerson,
}), (dispatch) => ({
    w18f1061Actions: bindActionCreators(w18f1061Actions, dispatch),
    templateDropdownActions: bindActionCreators(templateDropdownActions, dispatch),
}), null, { withRef: true }
)(W18F1061_ItemComment);