/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2019/10/02 14:26
 * @update 2019/10/02 14:26
 */

import React from 'react';
import { FormGroup, Form } from 'react-bootstrap';
import notify from 'devextreme/ui/notify';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ButtonSave from "../common/button/button-save";
import Config from '../../config/index';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {Col, Row, Input} from "reactstrap";
import * as w18f1061Actions from "../../actions/w18F1061-actions";
import * as userActions from "../../actions/user-actions";
import FileUploadAsync from "../../actions/file-upload-new-async";
import FileRemoveAsync from "../../actions/file-remove-async";
import FileDeleteAsync from "../../actions/file-delete-async";
import ButtonCustom from "../common/button/button-custom";
import {browserHistory} from "react-router";

import {DateBox} from "devextreme-react";
import PageContainer from "../common/page-container/page-container";
import moment from "moment";
import W18F1015PopUp from "./W18F1015";
import ActivityComponent from "../common/activity/activity-component";
import DropDownSalePerson from "../common/dropdown/template/dropdown-sale-person";
import ButtonSaveNext from "../common/button/button-savenext";
import {Loading} from "../common/loading/loading";
import ItemComment from './W18F1061_ItemComment';
import _ from "lodash";
import * as templateDropdownActions from "../../actions/template-dropdown-actions";
import W18F1014 from './W18F1014';
import * as w18F1014Actions from "../../actions/w18F1014-actions"; 

class W18F1061Page extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!this.getInfo()) return;
        this.state = {
            iPermission: 0,
            isSave: false,
            isDisabled: false,
            loading: false,
            Disabled: false,
            history: false,
            showPopup: false,
            dataAttach: [],
            attached: [],

            defaultStatus: '',
            companyName: '',
            address: null,
            telephone: null,
            email: null,
            IsCustomer: 0,
            IsPartner: 0,
            IsCompetitor: 0,
            Permission: 0,
            createPerson: null,

            CaseID: '',
            CaseCode: '',
            CaseName: '',
            CompanyID: '',
            CaseTypeID: '',
            SalesPersonID: Config.profile.UserID,
            MediaID: '',
            CaseStatus: '',
            CaseDate: moment().format('YYYY-MM-DD'),
            Description: '',
            InternalComment: '',
            Solution: '',
            HandlerID: '',
            HandlerName: '',

            CreateUserID: Config.profile.UserID,
            CreateDate: '',
            LastModifyUserID: Config.profile.UserID,
            LastModifyDate: '',
            FinishDate: moment().format('YYYY-MM-DD'), // default current date
            Deadline: moment().format('YYYY-MM-DD'), // default current date
            showW18F1014: false,
        };

        this.oldData = null;
        this.timer = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 3000;
        this.OldAttachData = [];
        this.resetForm = this.resetForm.bind(this);
        this.showHistory = this.showHistory.bind(this);
        this.dataW18F1014 = { rowData: {}, mode: 'add', unRedirect: true };
    };

    componentDidMount = async () => {
        const {mode, permission, companyID, companyName, telephone, email, address} = this.getInfo();
        if (companyName) {
            this.setState({
                CompanyID: companyID,
                companyName: companyName,
                telephone: telephone,
                email: email,
                address: address
            })
        }
        await this.loadPermission(permission);
        if(this.state.iPermission <= 0) return;
        if (!this.getInfo()) return;
        this.loadCaseTypes();
        this.loadMedia();
        this.loadStatus();
        this.loadCreatePerson();
        if (mode === 'edit') {
            const newCompany = companyName ? {CompanyID: companyID, companyName, telephone, email, address} : null;
            this.loadDataMaster(newCompany);
        } else if (mode === 'view') {
            this.loadDataMaster();
            this.setState({
                isDisabled: true
            });
        }
    };

    async loadPermission(permission) {

        await this.props.userActions.getPermission(Config.profile.UserID, permission ? permission : "D17F1860",(iPer) => {
            this.setState({iPermission: iPer});
        });
    };

    resetForm() {
        let status = '';
        if (this.props.cbStatus && this.props.cbStatus.length > 0) {
            const cbStatus = this.props.cbStatus.filter((e) => {
                return e.IsDefault === 1;
            });
            status = {
                value: cbStatus && cbStatus.length > 0 ? cbStatus[0].ID : this.props.cbStatus[0].ID,
                label: cbStatus && cbStatus.length > 0 ? cbStatus[0].Name : this.props.cbStatus[0].Name
            };
        }
        this.setState({
            isDisabled: false,
            isSave: false,

            CaseCode: '',
            CaseName: '',
            CompanyID: this.props.location.state.companyID || '',
            CaseTypeID: null,
            SalesPersonID: '',
            MediaID: null,
            CaseStatus: status,
            CaseDate: moment().format('YYYY-MM-DD'),
            Description: '',
            InternalComment: '',
            Solution: '',
            HandlerID: '',

            companyName: this.props.location.state.companyName || '',
            telephone: '',
            email: '',
            address: ''
        });

    }

    getInfo = (flag) => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if ((url && url.get('CaseID'))) {
            return { CaseID: url.get('CaseID'), mode: 'view' };
        } else if (location && location.state) {
            return {
                CaseID: location.state.CaseID,
                CaseCode: location.state.CaseCode,
                mode: location.state.mode,
                permission: location.state.permission,
                companyID: location.state.companyID,
                companyName: location.state.companyName,
                telephone: location.state.telephone,
                email: location.state.email,
                address: location.state.address,
                FormID: location.state.FormID,
                mainMode: location.state.mainMode,
                companyTypeID: location.state.companyTypeID,
                currentTabKey: location.state.currentTabKey,
                formCallState: location.state.formCallState,
            }
        } else {
            if (flag) {
                browserHistory.push(Config.env.url + '/W18F1060');
                return null;
            }
            return false;
        }
    };

    loadCaseTypes() {
        this.props.w18f1061Actions.cbCaseTypesW18F1061({}, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', '__caseType: ' + message);
                return false;
            }
        });
    }

    loadCreatePerson() {
        this.props.w18f1061Actions.cbCreatePersonW18F1061({}, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', '__createPerson: ' + message);
                return false;
            } else if (data) {
                this.setState({
                    createPerson: data
                });
            }
        });
    }

    loadMedia() {
        const param = {
            Language: Config.language || '84'
        };
        this.props.w18f1061Actions.cbMediaW18F1061(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', '__media: ' +  message);
                return false;
            }
        });
    }

    loadStatus() {
        const param = {
            sLanguage: Config.language,
            listType: 'W18F1060_CaseStatus'
        };
        this.props.w18f1061Actions.cbStatusW18F1061(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', '__status: ' +  message);
                return false;
            } else if (data && data.length > 0) {
                const itemDefault = data.filter((item) => {
                    return item.IsDefault === 1;
                });
                if (itemDefault && itemDefault.length > 0) {
                    this.setState({
                        CaseStatus: {label: itemDefault[0].Name, value: itemDefault[0].ID}
                    });
                }
            }
        });
    }

    loadDataMaster(newCompany = null) {
        const { CaseID } = this.getInfo();
        const param = {
            UserID: Config.profile.UserID,
            ReportID: "",
            Language: Config.language || "84",
            StrSearch: "",
            CompanyID: "",
            CompanyNameU: "",
            SalesPersonID: "",
            HandlerID: "",
            CaseTypeID: "",
            CaseStatus: "",
            MediaID: "",
            DateFrom: null,
            DateTo: null,
            ReportTypeID: "",
            CaseID: CaseID ? CaseID : this.state.CaseID,
            TypeID: "LoadForm",
        };

        this.props.w18f1061Actions.loadEditFormW18F1061(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            const createPerson = {
                SalesPersonID: data.SalesPersonID,
                SalesPersonNameU: data.SalesPersonNameU,
                URL: data.URL
            };
            this.oldData = data;
            this.oldData.CaseDate = moment(data.CaseDate).format('YYYY-MM-DD');
            this.oldData.Deadline = data.Deadline ? moment(data.Deadline).format('YYYY-MM-DD') : data.Deadline;
            this.oldData.FinishDate = data.FinishDate ? moment(data.FinishDate).format('YYYY-MM-DD') : data.FinishDate;
            if (newCompany) {
                data.CompanyID      = newCompany.CompanyID;
                data.CompanyNameU   = newCompany.companyName;
                data.Telephone      = newCompany.telephone;
                data.Email          = newCompany.email;
                data.AddressLineU   = newCompany.address;
            }
            this.setState({
                CaseID: data.CaseID,
                CaseCode: data.CaseCode,
                CaseName: data.CaseName,
                CompanyID: data.CompanyID,
                CaseTypeID: {value: data.CaseTypeID, label: data.CaseTypeName},
                SalesPersonID: data.SalesPersonID,
                MediaID: {value: data.MediaID, label: data.MediaNameU},
                CaseStatus: {value: data.CaseStatus, label: data.CaseStatusName},
                CaseDate: moment(data.CaseDate).format('YYYY-MM-DD'),
                Description: data.Description,
                InternalComment: data.InternalComment,
                Solution: data.Solution,
                HandlerID: data.HandlerID,
                createPerson: createPerson,

                companyName: data.CompanyNameU,
                IsCustomer: data.IsCustomer,
                IsPartner: data.IsPartner,
                IsCompetitor: data.IsCompetitor,
                Permission: data.Permission,
                telephone: data.Telephone,
                email: data.Email,
                address: data.AddressLineU,
                Deadline: _.get(data, "Deadline", null),
                FinishDate: _.get(data, "FinishDate", null),
            });
            this.getAttach(data.CaseID);
        });
    }

    showHistory() {
        this.setState({
            history: !this.state.history,
        })

    }

    getCodeLang(ID) {
        const listValues = {
            CompanyNameU: 'CRM_Ten_khach_hang',
            CaseName: 'CRM_Ten_tinh_huong',
            CaseTypeName: 'CRM_Loai_tinh_huong',
            CaseDate: 'CRM_Ngay_phat_sinh',
            MediaNameU: 'CRM_Kenh_gop_y',
            CaseStatusName: 'CRM_Trang_thai',
            Description: 'CRM_Dien_giai',
            InternalComment: 'CRM_Ghi_chu_noi_bo',
            Solution: 'CRM_Giai_phap',
            HandlerID: 'CRM_Nguoi_giai_quyet',
        };
        return listValues[ID] ? listValues[ID] : '';
    }

    saveHistory = async (newData) => {
        if (!this.getInfo()) return false;
        const {mode} = this.getInfo();
        let status = 200;
        let data = []; //Chứa giá trị bị thay đổi
        if (mode === 'edit') {
            const oldData = this.oldData;
            const key = Object.keys(newData);
            key.filter((value) => {
                return value !== 'CaseID' && value !== 'CaseCode';
            }).forEach((value) => {
                if (newData[value] !== oldData[value]) {
                    const description = this.getCodeLang(value);
                    let type = '';
                    switch (value) {
                        case "CaseStatusName":
                        case "CaseTypeName":
                            type = 'highlight';
                            break;
                        case "HandlerID":
                            type = 'image';
                            break;
                        default:
                            type = 'text';
                            break;
                    }
                    let el = {}; //truyền param -tham khảo api history add
                    el.codeID = newData.CaseID;
                    el.formID = 'W18F1061';
                    el.type = type;
                    el.linkedTrans = newData.CompanyID;
                    el.oldValue = oldData[value];
                    el.newValue = newData[value];
                    el.description84 = Config.lang(description, 'vi');
                    el.description01 = Config.lang(description, 'en');
                    el.action = 1;
                    data.push(el);
                }
                return false;

            });
            this.oldData = newData;
        } else {
            let el = {}; //truyền param -tham khảo api history add
            el.codeID = newData.CaseID;
            el.formID = 'W18F1061';
            el.linkedTrans = newData.CompanyID;
            el.oldValue = '';
            el.newValue = newData.CaseName;
            el.description84 = Config.lang("CRM_Cap_nhat_tinh_huong", 'vi');
            el.description01 = Config.lang("CRM_Cap_nhat_tinh_huong", 'en');
            el.action = 0;
            data.push(el);
        }
        if (data.length > 0) {
            const param = {
                attributes: JSON.stringify(data)
            };
            await this.props.w18f1061Actions.addHistoryW18F1061(param, (error) => {
                if (error) status = 400;
            });
        }
        return status
    };

    hideW18F1015 = async (data) => {
        if (!data) {
            this.setState({
                showW18F1015: false,
            });
            return false;
        }
        this.setState({
            CompanyID: data.CompanyID,
            showW18F1015: false,
            companyName: data.CompanyNameU,
            address: data.AddressLineU,
            telephone: data.Telephone ? data.Telephone : null,
            email: data.Email ? data.Email : null,
            IsCompetitor: data.IsCompetitor,
            IsCustomer: data.IsCustomer,
            IsPartner: data.IsPartner,
            Permission: data.Permission
        });
    };

    changeCaseType = (e) => {
        this.setState({
            CaseTypeID: e
        });
    };

    changeMedia = (e) => {
        this.setState({
            MediaID: e
        });
    };

    changeStatus = (e) => {
        this.setState({
            CaseStatus: e
        });
    };

    changeInput = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    onChangeComboSP(data) {
        if (data) {
            this.setState({
                HandlerID: data.SalesPersonID,
                HandlerName: data.SalesPersonNameU,
            });
        } else {
            this.setState({
                HandlerID: '',
                HandlerName: '',
            });
        }
    }

    getDataSave = () => {
        const { CaseCode, CaseName, CompanyID, CaseTypeID, MediaID, CaseStatus, Description, InternalComment, Solution, HandlerID, createPerson,
            CaseDate, companyName, HandlerName, Deadline, FinishDate } = this.state;
        return {
            CaseCode: CaseCode,
            CaseName: CaseName,
            CompanyID: CompanyID,
            CompanyName: companyName,
            CaseTypeID: CaseTypeID ? CaseTypeID.value : '',
            CaseTypeName: CaseTypeID ? CaseTypeID.label : '',
            SalesPersonID: createPerson ? createPerson.SalesPersonID : '',
            MediaID: MediaID ? MediaID.value : '',
            MediaName: MediaID ? MediaID.label : '',
            CaseStatus: CaseStatus ? CaseStatus.value : '',
            CaseStatusName: CaseStatus ? CaseStatus.label : '',
            CaseDate: CaseDate,
            Description: Description,
            InternalComment: InternalComment,
            Solution: Solution,
            HandlerID: HandlerID,
            HandlerName: HandlerName,

            CreateUserID: Config.profile.UserID,
            CreateDate: moment().format('YYYY-MM-DD'),
            LastModifyUserID: Config.profile.UserID,
            LastModifyDate: moment().format('YYYY-MM-DD'),
            Deadline,
            FinishDate,
        }
    };

    saveData() {
        const param = this.getDataSave();
        const {mode} = this.getInfo();
        let paramAttach = '';
        const dataAttach = [...this.state.dataAttach];
        const OldAttachData = this.OldAttachData;
        const validations = [
            {
                name: 'txtCaseName',
                title: Config.lang("CRM_Ten_tinh_huong"),
                rules: 'required'
            },
            {
                name: 'txtDescription',
                title: Config.lang("CRM_Dien_giai"),
                rules: 'length:2000'
            },
            {
                name: 'txtSolution',
                title: Config.lang("CRM_Giai_phap"),
                rules: 'length:2000'
            },
            {
                name: 'txtInternalComment',
                title: Config.lang("CRM_Ghi_chu_noi_bo"),
                rules: 'length:2000'
            }
        ];
        if (!Config.checkValidationWithRefs(this, validations)) {
            return false;
        }

        this.setState({
            loading: true
        });

        if (mode === 'add') {
            param.CreateDate = moment().format('YYYY-MM-DD');
            this.props.w18f1061Actions.addW18F1061(param, async (error, data) => {
                this.setState({
                    loading: false
                });
                if (error) {
                    let errorCode = error.code || null;
                    let message = "";
                    switch (errorCode) {
                        case "F1061E003":
                            message = Config.lang("CRM_Them_khong_thanh_cong");
                            Config.popup.show('INFO', message);
                            break;
                        case "F1061E005":
                            message = Config.lang("CRM_Date_input_format_invalid");
                            Config.popup.show('INFO', message);
                            break;
                        default:
                            message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                            Config.popup.show('INFO', message);
                            break;
                    }
                    return false;
                }

                const paramsCheckTask = {
                    SalesPersonID: param.SalesPersonID,
                    HandlerID: param.HandlerID,
                    CaseTypeID: param.CaseTypeID,
                    MediaID: param.MediaID,
                };
                this.props.w18f1061Actions.checkTask(paramsCheckTask, (er, cb) => {
                    if (cb.Status) {
                        const paramsGetForm = {
                            KeyID: data.CaseID,
                            TaskID: '',
                            FFormID: 'W18F1061',
                        };
                        this.props.w18F1014Actions.getForm(paramsGetForm, (error, data) => {
                            if (!Config.isEmpty(data)) {
                                this.dataW18F1014.rowData = data;
                                this.dataW18F1014.params = paramsGetForm;
                                this.setState({ showW18F1014: true });
                            }
                        });
                    }
                });

                let uploadError = {is: false, message: Config.lang("CRM_Co_loi_khi_dinh_kem")};
                if (dataAttach.length > 0) {
                    let attached = [...this.state.attached];
                    let isAttachmentUpdate = false;
                    for (let i = 0; i < dataAttach.length; i++) {
                        const exp = dataAttach[i].file.name.split('.');
                        paramAttach = {
                            'KeyID': data.CaseID,
                            'FileName': dataAttach[i].file.name,
                            'FileSize': dataAttach[i].file.size,
                            'FileExp': exp[exp.length - 1],
                            'TableName': 'D17T1860'
                        };

                        const upload = await FileUploadAsync(dataAttach[i].file, paramAttach);
                        if (upload) {
                            dataAttach[i].AttachmentID = upload.KeyID;
                            attached.push(dataAttach[i]);
                            isAttachmentUpdate = true;
                        }
                        if (upload.code !== 200) {
                            uploadError.is = true;
                        }

                    }
                    if (isAttachmentUpdate) {
                        this.setState({
                            dataAttach: [],
                            attached: attached
                        });
                    }
                }

                const paramHistory = {
                    CaseID: data.CaseID,
                    CompanyName: param.CompanyName,
                    CaseName: param.CaseName,
                };
                const historyStatus = await this.saveHistory(paramHistory);
                if (historyStatus === 200) {
                    if (this.refs['activityRef']) {
                        this.refs['activityRef'].getWrappedInstance().resetHistory();
                    }
                }
                this.setState({
                    isDisabled: true,
                    isSave: true
                });
                if (uploadError.is) {
                    Config.popup.show('INFO', uploadError.message);
                } else {
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                }
            });
        } else if (mode === 'edit') {
            param.LastModifyDate = moment().format('YYYY-MM-DD');
            this.props.w18f1061Actions.editW18F1061(param, async (error, data) => {
                this.setState({
                    loading: false
                });
                if (error) {

                    let errorCode = error.code || null;
                    let message = "";
                    switch (errorCode) {
                        case "F1061E002":
                            message = Config.lang("CRM_Ma_tinh_huong_khong_duoc_de_trong");
                            Config.popup.show('INFO', message);
                            break;
                        case "F1061E004":
                            message = Config.lang("CRM_Chinh_sua_khong_thanh_cong");
                            Config.popup.show('INFO', message);
                            break;
                        case "F1061E005":
                            message = Config.lang("CRM_Date_input_format_invalid");
                            Config.popup.show('INFO', message);
                            break;
                        default:
                            message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                            Config.popup.show('INFO', message);
                            break;
                    }
                    return false;
                }

                let uploadError = {is: false, message: Config.lang("CRM_Co_loi_khi_dinh_kem")};
                if (dataAttach.length > 0) {
                    let attached = [...this.state.attached];
                    let isAttachmentUpdate = false;
                    for (let i = 0; i < dataAttach.length; i++) {
                        const exp = dataAttach[i].file.name.split('.');
                        paramAttach = {
                            'KeyID': data.CaseID,
                            'FileName': dataAttach[i].file.name,
                            'FileSize': dataAttach[i].file.size,
                            'FileExp': exp[exp.length - 1],
                            'TableName': 'D17T1860'
                        };
                        const upload = await FileUploadAsync(dataAttach[i].file, paramAttach);
                        if (upload) {
                            dataAttach[i].AttachmentID = upload.KeyID;
                            attached.push(dataAttach[i]);
                            isAttachmentUpdate = true;
                        }
                        if (upload.code !== 200) {
                            uploadError.is = true;
                        }
                    }
                    if (isAttachmentUpdate) {
                        this.setState({
                            dataAttach: [],
                            attached: attached,
                            disabled: false
                        });
                    }
                }

                if (OldAttachData.length > 0) {
                    let paramDeleteAttach;
                    let paramRemoveAttach;
                    for (let i = 0; i < OldAttachData.length; i++) {
                        paramDeleteAttach = {
                            'AttachmentID': OldAttachData[i].AttachmentID
                        };
                        paramRemoveAttach = {
                            'path': OldAttachData[i].URL
                        };
                        await FileDeleteAsync(paramDeleteAttach);

                        await FileRemoveAsync(paramRemoveAttach);
                    }
                    OldAttachData.length = 0;
                }

                const paramHistory = {
                    CaseID: data.CaseID,
                    CompanyNameU: param.CompanyName,
                    CaseName: param.CaseName,
                    CaseTypeName: param.CaseTypeName,
                    CaseDate: param.CaseDate,
                    MediaNameU: param.MediaName,
                    CaseStatusName: param.CaseStatusName,
                    Description: param.Description,
                    InternalComment: param.InternalComment,
                    Solution: param.Solution,
                    HandlerID: param.HandlerID,
                };
                const historyStatus = await this.saveHistory(paramHistory);
                if (historyStatus === 200) {
                    if (this.refs['activityRef']) {
                        this.refs['activityRef'].getWrappedInstance().resetHistory();
                    }
                }
                // this.setState({
                    // isDisabled: true,
                //     isSave: false
                // });
                if (uploadError.is) {
                    Config.popup.show('INFO', uploadError.message);
                } else {
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                }
            });
        }
    }

    checkFileType(filetype) {
        const allowedExtensions = [
            'image/jpeg',
            'image/png',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/pdf'
        ];
        if (filetype.length > 0) {
            if (allowedExtensions.indexOf(filetype) === -1) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    getAttach(id) {
        const param = {
            KeyID: id
        };

        this.props.w18f1061Actions.getAttach(param, (error, data) => {
            if (error) return false;
            if (data) {
                this.setState({
                    attached: data
                })
            }
        })
    }

    upLoadAttach = (e) => {
        if (!e) return;
        const files = e?.target?.files || [];
        const sizeLimit = 20;
        if (files.length === 0) {
            return null;
        } else if (this.checkFileType(files[0].type) === false) {
            Config.popup.show("INFO", `${Config.lang("CRM_Dinh_dang_file_khong_hop_le")}`);
        } else if ((files[0].size / 1024 / 1024) > Number(sizeLimit)) {
            Config.popup.show("INFO", `${Config.lang("CRM_Dung_luong_File_khong_duoc_lon_hon")}` + sizeLimit + ' MB');
        } else {
            let dataAttach = [...this.state.dataAttach];
            for (let file of files) {
                file.uploading = true;
                dataAttach.push({file: file, FileName: file.name, URL: window.URL.createObjectURL(file)});
            }
            this.setState({
                dataAttach: dataAttach,
            });
        }
    }

    async deleteAttach(file) {
        const {dataAttach} = this.state;
        let arr = dataAttach.filter((value) => {
            return value.FileName !== file.FileName;
        });

        this.setState({
            dataAttach: arr
        });
    }

    deleteOldAttach(attachment) {
        this.OldAttachData.push(attachment);
        const attached = this.state.attached;
        let arr;
        arr = attached.filter(function (obj) {
            return obj.AttachmentID !== attachment.AttachmentID;
        });
        this.setState({
            attached: arr
        })
    }

    renderIcon = (file, url) => {
        let ext = file ? file.split('.') : '';
        ext = ext[ext.length - 1];
        switch (ext) {
            case 'doc':
                return (<img alt="" src={require("../../images/file/icon-doc.png")}/>);
            case 'docx':
                return (<img alt="" src={require("../../images/file/icon-docx.png")}/>);

            case 'xls':
                return (<img alt="" src={require("../../images/file/icon-xls.png")}/>);
            case 'xlsx':
                return (<img alt="" src={require("../../images/file/icon-xlsx.png")}/>);

            case 'ppt':
                return (<img alt="" src={require("../../images/file/icon-ppt.png")}/>);
            case 'pptx':
                return (<img alt="" src={require("../../images/file/icon-pptx.png")}/>);

            case 'pdf':
                return (<img alt="" src={require("../../images/file/icon-pdf.png")}/>);

            case 'txt':
                return (<img alt="" src={require("../../images/file/icon-txt.png")}/>);

            default:
                return (<img alt="" src={url} className="img-background"/>);
        }
    };

    onAddNew = () => {
        Config.popup.show("YES_NO", Config.lang("CRM_Them_moi_khach_hang"), () => {
            // Exist FormID as oldFormID if come from W18F1012
            const {mode, CaseCode, FormID, permission} = this.getInfo();
            browserHistory.replace({
                pathname: Config.getRootPath() + 'W18F1011',
                state: {type: 'Customers', mode: 'add', modeW18F1061: mode, FormID: 'W18F1061', CaseCode: CaseCode, oldFormID: FormID, permission: permission}
            })
        });
    };

    comeBack = () => {
        const { FormID,currentTabKey } = this.getInfo();
        if (!FormID) {
            browserHistory.goBack();
            return;
        }
        let state = this.getInfo();
        state = {
            ...state,
            ...state.formCallState,
            currentTabKey,
            type: 'Customers',
            permission: 4
        }
        browserHistory.push({
            pathname: Config.getRootPath() + FormID,
            state: state
        });
    };

    render() {
        if (!this.getInfo(true)) return null;
        const {mode, permission} = this.getInfo();
        const { companyName, telephone, email, address, CaseStatus, IsCustomer, IsPartner, IsCompetitor, Permission, CompanyID, dataAttach, attached, iPermission,
            CaseID, CaseCode, CaseName, CaseTypeID, MediaID, CaseDate, Description, InternalComment, Solution, HandlerID, history, createPerson,
            FinishDate, Deadline, showW18F1014 } = this.state;

            let {cbCaseTypes, cbMedia, cbStatus} = this.props;
        const userImage = createPerson && createPerson.URL ? createPerson.URL : require('../../images/icon-user-default.png');
        const listCaseTypes = [], listMedia = [], listStatus = [];

        cbCaseTypes && cbCaseTypes.map((item) => {
            const obj = {label: item.CaseTypeName, value: item.CaseTypeID};
            listCaseTypes.push(obj);
            return listCaseTypes;
        });
        cbMedia && cbMedia.map((item) => {
            const obj = {label: item.Name, value: item.ID};
            listMedia.push(obj);
            return listMedia;
        });
        cbStatus && cbStatus.map((item) => {
            const obj = {label: item.Name, value: item.ID};
            listStatus.push(obj);
            return listStatus;
        });

        return (
            <PageContainer
                className={"page-container"}
                title={Config.lang("CRM_Cap_nhat_tinh_huong")}
                tracking={'W18F1061'}
            >
                {this.state.showW18F1015 && <W18F1015PopUp mode={0} onHide={(data) => {
                    this.hideW18F1015(data);

                }} companyID={''}
                />}
                {this.state.loading && <Loading/>}
                {showW18F1014 &&
                    <W18F1014
                        loadData={(cb) => cb(this.dataW18F1014)}
                        data={this.dataW18F1014}
                        formID={'W18F1061'}
                        state={this.getInfo()}
                        onHide={() => this.setState({ showW18F1014: false })}

                    />}
                <Form horizontal={true} id={"W18F1061"} className={'mgt5'} style={{position: 'relative'}}>
                    <FormGroup>
                        <Col id={'masterW18F1061'} lg={history ? 8 : 12} md={history ? 7 : 12} sm={history ? 7 : 12} style={{transition: 'width 0.2s'}}>
                            <FormGroup className={"jus-end"}>
                                <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                                    <ButtonCustom color={'text-orange'} icon={'fas fa-arrow-alt-left'} isAwesome={true}
                                                  name={Config.lang("CRM_Quay_lai")}
                                                  onClick={this.comeBack}/>
                                    {mode !== 'view' && this.state.isSave && <ButtonSaveNext name={Config.lang("CRM_Nhap_tiep")} onClick={this.resetForm}
                                                disabled={!this.state.isDisabled}/>}
                                    {mode !== 'view' && !this.state.isSave && <ButtonSave id={'btnSaveW18F1061'} disabled={this.state.isDisabled}
                                                className={'mgl5'}
                                                name={Config.lang("CRM_Luu")}
                                                onClick={() => this.saveData()}/>}
                                    {mode !== 'view' &&
                                        <ButtonCustom className={'mgr5'} icon={'fas fa-cloud-upload'} isAwesome={true}
                                            color={'text-green'} name={Config.lang("CRM_Dinh_kem")}
                                            onClick={() => {
                                                document.getElementById('attachmentW18F1061').click();
                                            }}
                                            disabled={mode === 'add' && this.state.isDisabled}
                                    />}
                                    <span id={'buttonHistoryW18F1061'} onClick={() => this.showHistory()}
                                       style={{float: 'right'}}
                                       className={'mgt5 text-muted cursor-pointer'}>{Config.lang('CRM_Lich_su')}
                                        <i className={`mgl5 fas fa-chevron-${!history ? 'right' : 'left'}`}/></span>
                                </Col>
                            </FormGroup>

                            <FormGroup>
                                <Col md={2}  xl={2} lg={2} sm={history ? 12 : 2} xs={history ? 12 : 2} >
                                    <label className={'control-label'}>{Config.lang("CRM_Ma_tinh_huong")}<i
                                        className="error">*</i></label>
                                </Col>
                                <Col md={4} xl={4} lg={4} sm={history ? 12 : 4} xs={history ? 12 : 4}>
                                    <Input id={'CaseCode'}
                                           disabled={true} value={CaseCode}
                                    />
                                </Col>
                                <Col md={2}  xl={2} lg={2} sm={history ? 12 : 2} xs={history ? 12 : 2} >
                                    <label className={'control-label'}>{Config.lang("CRM_Ten_tinh_huong")}<i
                                        className="error">*</i></label>
                                </Col>
                                <Col md={4} xl={4} lg={4} sm={history ? 12 : 4} xs={history ? 12 : 4}>
                                    <input ref={'txtCaseName'} className={'form-control'} id={'CaseName'} onChange={this.changeInput} disabled={this.state.isDisabled} value={CaseName} />
                                </Col>
                            </FormGroup>

                            <FormGroup>
                                <Col md={2}  xl={2} lg={2} sm={history ? 12 : 2} xs={history ? 12 : 2} >
                                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                        <label className={'control-label'}>{Config.lang("CRM_Khach_hang")}</label>
                                        {
                                            (mode === 'add' || (permission !== 'D17F1861' && mode === 'edit')) && iPermission >= 2 &&
                                                <i style={{
                                                        color: '#015f97',
                                                        marginTop: '9px',
                                                        marginLeft: '7px',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={this.onAddNew}
                                                    className={'fa-md fas fa-plus-circle'}
                                                />
                                        }
                                    </div>
                                </Col>
                                {
                                    /* eslint-disable */
                                    <Col md={4} xl={4} lg={4} sm={history ? 12 : 4} xs={history ? 12 : 4} style={{position: 'relative'}}>

                                        <Input id={'customersW18F1061'}
                                               defaultValue={companyName}
                                               disabled={this.state.isDisabled}
                                               onClick={() => {
                                                   this.setState({showW18F1015: true})
                                               }} className={'form-control'}/>

                                        <i className={'fa fa-sort-down'} style={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 26,
                                            pointerEvents: 'none'
                                        }}/>
                                    </Col>
                                    /* eslint-enable */
                                }

                                <Col md={2}  xl={2} lg={2} sm={history ? 12 : 2} xs={history ? 12 : 2} >
                                    <label className={'control-label'}>{Config.lang("CRM_Loai_tinh_huong")}</label>
                                </Col>
                                <Col md={4} xl={4} lg={4} sm={history ? 12 : 4} xs={history ? 12 : 4}>
                                    <Select
                                        value={CaseTypeID}
                                        options={listCaseTypes}
                                        placeholder={Config.lang("CRM_Chon_loai_tinh_huong")}
                                        displayExpr={'label'}
                                        valueExpr={'value'}
                                        isDisabled={this.state.isDisabled}
                                        onChange={this.changeCaseType}
                                        isMulti={false}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={2}  xl={2} lg={2} sm={history ? 12 : 2} xs={history ? 12 : 2} >
                                    <label className={'control-label'}>{Config.lang("CRM_Nguoi_lap")}</label>
                                </Col>
                                <Col md={4} xl={4} lg={4} sm={history ? 12 : 4} xs={history ? 12 : 4}>
                                    <div>
                                        <img alt={''} className={'img-circle'} src={userImage} width={48} height={48} />&nbsp;
                                        <label className={'control-label'}>{createPerson ? createPerson.SalesPersonNameU : ''}</label>
                                    </div>
                                </Col>
                                <Col md={2}  xl={2} lg={2} sm={history ? 12 : 2} xs={history ? 12 : 2} >
                                    <label className={'control-label'}>{Config.lang("CRM_Kenh_gop_y")}</label>
                                </Col>
                                <Col md={4} xl={4} lg={4} sm={history ? 12 : 4} xs={history ? 12 : 4}>
                                    <Select
                                        value={MediaID}
                                        options={listMedia}
                                        placeholder={Config.lang("CRM_Chon_kenh")}
                                        onChange={this.changeMedia}
                                        isDisabled={this.state.isDisabled}
                                        displayExpr={'label'}
                                        valueExpr={'value'}
                                        isMulti={false}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={2}  xl={2} lg={2} sm={history ? 12 : 2} xs={history ? 12 : 2} >
                                    <label className={'control-label'}>{Config.lang("CRM_Trang_thai")}</label>
                                </Col>
                                <Col md={4} xl={4} lg={4} sm={history ? 12 : 4} xs={history ? 12 : 4}>
                                    <Select
                                        value={CaseStatus}
                                        options={listStatus}
                                        placeholder={Config.lang("CRM_Chon_trang_thai")}
                                        onChange={this.changeStatus}
                                        isDisabled={this.state.isDisabled}
                                        displayExpr={'label'}
                                        valueExpr={'value'}
                                        isMulti={false}
                                    />
                                </Col>
                                <Col md={2}  xl={2} lg={2} sm={history ? 12 : 2} xs={history ? 12 : 2} >
                                    <label className={'control-label'}>{Config.lang("CRM_Ngay_phat_sinh")}</label>
                                </Col>
                                <Col md={4} xl={4} lg={4} sm={history ? 12 : 4} xs={history ? 12 : 4}>
                                    <DateBox
                                        width={"100%"}
                                        id={'CaseDate'}
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        value={CaseDate}
                                        type={'date'}
                                        disabled={this.state.isDisabled}
                                        pickerType={"calendar"}
                                        showAnalogClock={false}
                                        displayFormat={'dd/MM/y'}
                                        dateSerializationFormat={'yyyy-MM-dd'}
                                        onValueChanged={(e) => {
                                            this.setState({
                                                CaseDate: e.value
                                            });
                                        }}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={2} xl={2} lg={2} sm={history ? 12 : 2} xs={history ? 12 : 2} >
                                    <label className={'control-label'}>{Config.lang("Ngay_hoan_thanh")}</label>
                                </Col>
                                <Col md={4} xl={4} lg={4} sm={history ? 12 : 4} xs={history ? 12 : 4}>
                                    <DateBox
                                        width={"100%"}
                                        id={'FinishDate'}
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        value={FinishDate}
                                        type={'date'}
                                        disabled={this.state.isDisabled}
                                        pickerType={"calendar"}
                                        showAnalogClock={false}
                                        displayFormat={'dd/MM/y'}
                                        dateSerializationFormat={'yyyy-MM-dd'}
                                        onValueChanged={(e) => {
                                            this.setState({
                                                FinishDate: e.value
                                            });
                                        }}
                                    />
                                </Col>
                                <Col md={2} xl={2} lg={2} sm={history ? 12 : 2} xs={history ? 12 : 2} >
                                    <label className={'control-label'}>{Config.lang("Han_xu_ly")}</label>
                                </Col>
                                <Col md={4} xl={4} lg={4} sm={history ? 12 : 4} xs={history ? 12 : 4}>
                                    <DateBox
                                        width={"100%"}
                                        id={'Deadline'}
                                        openOnFieldClick={true}
                                        useMaskBehavior={true}
                                        value={Deadline}
                                        type={'date'}
                                        disabled={this.state.isDisabled}
                                        pickerType={"calendar"}
                                        showAnalogClock={false}
                                        displayFormat={'dd/MM/y'}
                                        dateSerializationFormat={'yyyy-MM-dd'}
                                        onValueChanged={(e) => {
                                            this.setState({
                                                Deadline: e.value
                                            });
                                        }}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={8} xl={8} lg={8} sm={history ? 12 : 8} xs={history ? 12 : 8}>
                                    <FormGroup>
                                        <Col md={3} xl={3} lg={3} sm={history ? 12 : 3} xs={history ? 12 : 3}>
                                            <label className={'control-label'}>{Config.lang("CRM_Dien_giai")}</label>
                                        </Col>
                                        <Col md={9} xl={9} lg={9} sm={history ? 12 : 9} xs={history ? 12 : 9}>
                                            <Input id={'Description'} type={'textarea'} rows={4} disabled={this.state.isDisabled}
                                                   ref={'txtDescription'}
                                                   value={Description} onChange={this.changeInput} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col md={3} xl={3} lg={3} sm={history ? 12 : 3} xs={history ? 12 : 3}>
                                            <label className={'control-label'}>{Config.lang("CRM_Ghi_chu_noi_bo")}</label>
                                        </Col>
                                        <Col md={9} xl={9} lg={9} sm={history ? 12 : 9} xs={history ? 12 : 9}>
                                            <Input id={'InternalComment'} type={'textarea'} rows={4} disabled={this.state.isDisabled}
                                                   ref={'txtInternalComment'}
                                                   value={InternalComment} onChange={this.changeInput} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col md={3} xl={3} lg={3} sm={history ? 12 : 3} xs={history ? 12 : 3}>
                                            <label className={'control-label'}>{Config.lang("CRM_Giai_phap")}</label>
                                        </Col>
                                        <Col md={9} xl={9} lg={9} sm={history ? 12 : 9} xs={history ? 12 : 9}>
                                            <Input id={'Solution'} type={'textarea'} rows={4} disabled={this.state.isDisabled}
                                                   ref={'txtSolution'}
                                                   value={Solution} onChange={this.changeInput} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col md={3} xl={3} lg={3} sm={history ? 12 : 3} xs={history ? 12 : 3}>
                                            <label className={'control-label'}>{Config.lang("CRM_Nguoi_giai_quyet")}</label>
                                        </Col>
                                        <Col md={9} xl={9} lg={9} sm={history ? 12 : 9} xs={history ? 12 : 9}>
                                            <DropDownSalePerson showClearButton={true}
                                                                groupSalesID={''}
                                                                placeholder={Config.lang("CRM_Chon_nguoi_giai_quyet")}
                                                                value={HandlerID}
                                                                disabled={this.state.isDisabled}
                                                                reset={!HandlerID} onChange={(e) => {
                                                this.onChangeComboSP(e)
                                            }}/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col md={3} xl={3} lg={3} sm={history ? 12 : 3} xs={history ? 12 : 3}>
                                            <label className={'control-label'}>{Config.lang("CRM_Tai_lieu_dinh_kem")}</label>
                                        </Col>
                                        <Col md={9} xl={9} lg={9} sm={history ? 12 : 9} xs={history ? 12 : 9}>
                                            <Row md={12} sm={12} xs={12} lg={12} className={'mg0 display_row list-attach'}>
                                                {(dataAttach.length === 0 && attached.length === 0) && <span
                                                    className={'text-no-data'}>{Config.lang('CRM_Khong_co_du_lieu')}</span>}

                                                {attached && attached.map((value, index) => {
                                                    return (
                                                        <label className={'view-attachment mgr5 control-label small '}
                                                            key={'view-attachment' + index}>
                                                            <a href={Config.env.api + '/file/get?AttachmentID=' + value.AttachmentID}
                                                            download>
                                                                {this.renderIcon(value.FileName, value.URL)}
                                                            </a>
                                                            {value.FileName}
                                                            <i className={this.state.isDisabled === true ? 'fas fa-trash-alt text-red deleteAttach hide' : 'fas fa-trash-alt text-red deleteAttach'}
                                                            onClick={() => {
                                                                this.deleteOldAttach(value)
                                                            }}
                                                            />
                                                        </label>
                                                    )
                                                })}

                                                {dataAttach.map((value, index) => {
                                                    if (value.uploading) {
                                                        return (<div className={'view-loading'}>
                                                            <i key={index}
                                                            className="fas fa-spinner fa-spin"/>
                                                        </div>)
                                                    } else return (
                                                        <label className={'view-attachment mgr5 control-label small '}
                                                            key={'view-attachment1' + index}>
                                                            {this.renderIcon(value.FileName, value.URL)}
                                                            {value.FileName}
                                                            <i className={this.state.isDisabled === true ? 'fas fa-trash-alt text-red deleteAttach hide' : 'fas fa-trash-alt text-red deleteAttach'}
                                                            onClick={() => {
                                                                this.deleteAttach(value)
                                                            }}/>
                                                        </label>
                                                    );
                                                })}
                                            </Row>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        {mode !== "add" &&
                                            <Col md={8} sm={8} xs={12} lg={8}>
                                                <ItemComment
                                                    CodeID={CaseCode}
                                                    FormID={"W18F1061"}
                                                />
                                            </Col>
                                        }
                                    </FormGroup>
                                </Col>
                                <Col md={4} xl={4} lg={4} sm={history ? 12 : 4} xs={history ? 12 : 4}>
                                    <CustomerInformation
                                        companyID={CompanyID}
                                        customerName={companyName}
                                        telephone={telephone}
                                        email={email}
                                        address={address}
                                        isCustomer={IsCustomer}
                                        isPartner={IsPartner}
                                        isCompetitor={IsCompetitor}
                                        permission={Permission}
                                        history={history}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                        {history && CaseID &&
                        <ActivityComponent
                            condition={{codeID: CaseID}}
                            ref={'activityRef'}
                            onClose={this.showHistory}
                        >
                        </ActivityComponent>
                        }
                    </FormGroup>
                </Form>
                <input
                    id={'attachmentW18F1061'}
                    multiple={'multiple'}
                    type="file"
                    className="media_upload_input hide"
                    onChange={this.upLoadAttach}
                />
            </PageContainer>
        )
    }
}

const textSm = {
    fontSize: '12px'
};
const sIcon = {
    width: '16px',
    textAlign: 'center'
};
const whiteSpace = {
    whiteSpace: 'nowrap'
};
class CustomerInformation extends React.Component {

    showW18F1012 = () => {
        const {isCustomer, isPartner, isCompetitor, permission, companyID} = this.props;
        let type = 'Partner';
        if (isCustomer) {
            type = 'Customers';
        } else if (isCompetitor) {
            type = 'Competitor';
        } else if (isPartner) {
            type = 'Partner'
        }
        browserHistory.push({
            pathname: Config.getRootPath() + 'W18F1012',
            state: {type: type, Permission: permission, CompanyID: companyID}
        });
    };

    render() {
        const {customerName, telephone, email, address, history} = this.props;
        return (
            <React.Fragment>
                <FormGroup>
                    <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                        <label className={'control-label'}>{Config.lang("CRM_Thong_tin_khach_hang")}</label>
                    </Col>
                </FormGroup>
                <div style={textSm}>
                    <FormGroup>
                        <Col md={history ? 12 : 4} sm={4} xl={4} xs={4} lg={history ? 12 : 4}>
                            <span className={'control-label text-bold'} style={whiteSpace}><i style={sIcon} className={'fas fa-clipboard-list'}/>&nbsp;{Config.lang("CRM_Khach_hang")}</span>
                        </Col>
                        <Col md={history ? 12 : 8} sm={8} xl={8} xs={8} lg={history ? 12 : 8}>
                            {
                                /* eslint-disable */
                                <a className={'control-label'} title={ customerName } onClick={this.showW18F1012}>
                                    { customerName }
                                </a>
                                /* eslint-enable */
                            }
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={history ? 12 : 4} sm={4} xl={4} xs={4} lg={history ? 12 : 4}>
                            <span className={'control-label text-bold'} style={whiteSpace}><i style={sIcon} className={'fa fa-phone'} />&nbsp;{ Config.lang("CRM_Dien_thoai") }</span>
                        </Col>
                        <Col md={history ? 12 : 8} sm={8} xl={8} xs={8} lg={history ? 12 : 8}>
                            <span>{ telephone }</span>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={history ? 12 : 4} sm={4} xl={4} xs={4} lg={history ? 12 : 4}>
                            <span className={'control-label text-bold'} style={whiteSpace}><i style={sIcon} className={'fas fa-envelope'}/>&nbsp;{ 'Email' }</span>
                        </Col>
                        <Col md={history ? 12 : 8} sm={8} xl={8} xs={8} lg={history ? 12 : 8}>
                            <span>{ email }</span>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={history ? 12 : 4} sm={4} xl={4} xs={4} lg={history ? 12 : 4}>
                            <span className={'control-label text-bold'} style={whiteSpace}><i style={sIcon} className={'fas fa-map-marker-alt'}/>&nbsp;{ Config.lang("CRM_Dia_chi") }</span>
                        </Col>
                        <Col md={history ? 12 : 8} sm={8} xl={8} xs={8} lg={history ? 12 : 8}>
                            <span>{ address }</span>
                        </Col>
                    </FormGroup>
                </div>
            </React.Fragment>
        )
    }
}

CustomerInformation.propTypes = {
    customerName: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    history: PropTypes.bool
};

export default connect(state => ({
        cbCustomers: state.w18f1061.cbCustomers,
        cbCaseTypes: state.w18f1061.cbCaseTypes,
        cbMedia: state.w18f1061.cbMedia,
        cbStatus: state.w18f1061.cbStatus,
        createPerson: state.w18f1061.createPerson,
        cbSalePersons: state.w18f1061.cbSalePersons,
        loadEditW18F1061: state.w18f1061.loadEditW18F1061,
        addW18F1061: state.w18f1061.addW18F1061,
        getUser: state.user.getUser,
    }),
    (dispatch) => ({
        w18f1061Actions: bindActionCreators(w18f1061Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
        templateDropdownActions: bindActionCreators(templateDropdownActions, dispatch),
        w18F1014Actions: bindActionCreators(w18F1014Actions, dispatch),
    }), null, { withRef: true }
)(W18F1061Page);
