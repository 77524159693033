/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/07/24 10:37
 * @update 2019/07/24 10:37
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w18f1023Actions from "../../actions/w18F1023-actions";

import {Column} from 'devextreme-react/data-grid';
import PopupView from "../popup/popup-view";
import {FormGroup, Col} from 'react-bootstrap';
import Config from "../../config";
import TreeList, {HeaderFilter, Selection} from "devextreme-react/tree-list";
import {Loading} from "../common/loading/loading";
import EventTracking from "../common/tracking/event";


class W18F1024Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.ID = '';
        this.groupSalesID = {};
        this.defaultRowKeys = [];
        this.state = {
            loading: false,
        };
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 2000;

    }


    onHide() {
        if (this.props.onHide) {
            this.props.onHide(this.groupSalesID);
        }
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    loadDataTreeList() {
        this.setState({loading: true});
        this.timer = setTimeout(() => {
            this.setState({loading: false});
        }, this.timeDeplay);
        const uName = Config.profile.UserID || '';
        const params = {
            "UserID": uName
        };
        this.props.w18f1023Actions.loadTree(params, (error, data) => {
            if (error) {
                this.setState({loading: false});
                Config.popup.show('INFO', `${Config.lang("CRM_Loi_chua_xac_dinh")}`);
            }
            if (data.length > 0) {
                const defaultExpandedRowKeys = data.filter((row) => {
                    return row.GroupSalesParentID === '0';
                });
                if (defaultExpandedRowKeys.length > 0) {
                    this.defaultRowKeys.push(defaultExpandedRowKeys[0].GroupSalesID);
                }
                this.setState({loading: false});
                return false;
            } else {
                return false;
            }
        });
    }

    componentDidMount() {
        this.loadDataTreeList()
    }

    render() {
        const {treeData} = this.props;
        return (
            <PopupView
                title={`${Config.lang("CRM_Co_cau_kinh_doanh")}`}
                show={true}
                animation={true}
                onHide={() => this.onHide()}>
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W18F1023"} label={Config.profile.UserID}/>}

                <div className="page-container" id={'frmW18F1024'}>
                    {this.state.loading && <Loading/>}
                    <FormGroup className={'mgt5'}>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            {treeData && <TreeList
                                showBorders={true}
                                columnAutoWidth={true}
                                wordWrapEnabled={true}
                                keyExpr={'GroupSalesID'}
                                defaultExpandedRowKeys={this.defaultRowKeys}
                                dataSource={treeData}
                                parentIdExpr={'GroupSalesParentID'}
                                // autoExpandAll={true}
                                showRowLines={false}
                                showColumnHeaders={false}
                                height={500}
                                width={'100%'}
                                onRowDblClick={(e) => {
                                    const {data} = this.props;
                                    const arr = data.find(value => value.SalesPersonID === e.data.GroupSalesID);
                                    if (arr) {
                                        Config.popup.show('INFO', `${Config.lang("CRM_Nhan_vien_nay_da_duoc_su_dung")}`);
                                        return false;
                                    }
                                    this.groupSalesID = e.data;
                                    this.onHide();
                                }}
                            >
                                <HeaderFilter visible={true}/>
                                <Selection mode={'single'}/>
                                <Column dataField={'GroupSalesNameU'}
                                        cellTemplate={(container, options) => {
                                            const data = options.data
                                            if (data.IsSalesPerson === 1) {
                                                container.innerHTML = `<span class="groupSalesChildW18F1023"><img class="avatarW18F1023 img-circle" src='${data.URL ? data.URL : require('../.././images/icon-user-default.png')}' alt="Smiley face" height="42" width="42"><span>${data.GroupSalesNameU ? data.GroupSalesNameU : ''} ${data.JobPositionU ? `(${data.JobPositionU})` : ''}</span></span>`
                                            } else {
                                                container.innerHTML = `<span class="groupSalesParentW18F1023">${data.GroupSalesNameU}</span>`;
                                            }
                                        }}
                                        caption={'Status'} width={300}/>
                            </TreeList>}
                        </Col>
                    </FormGroup>
                </div>
            </PopupView>

        )
    }
}

W18F1024Page.propTypes = {

    // onHide: PropTypes.func,
    // onReloadGrid: PropTypes.func
};

export default connect(state => ({
        treeData: state.w18f1023.treeData,
    }),
    (dispatch) => ({
        w18f1023Actions: bindActionCreators(w18f1023Actions, dispatch)
    })
)(W18F1024Page);