/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/02/27 17:00
 * @update 2019/02/27 17:00
 * @file src/components/header/header.js
 */

import React from 'react';
import {Row, FormGroup, Form} from 'react-bootstrap';
import notify from 'devextreme/ui/notify';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ButtonSave from "../common/button/button-save";
import ButtonNotSave from "../common/button/button-notsave";
import Config from '../../config/index';
import GridContainer from "../common/grid-container/grid-container";
import {Column, Paging, Pager, RequiredRule} from 'devextreme-react/data-grid';

import {Col, Input} from "reactstrap";
import {CheckBox} from 'devextreme-react';
import PropTypes from 'prop-types';
import EventTracking from "../common/tracking/event";
import * as w18f1040Actions from "../../actions/w18f1040-actions";

class W18F1041Page extends React.PureComponent {
    constructor(props) {
        super(props);

        const data = this.props.data;
        let Customer = '';
        let controlType = '';
        if (data && data.mode === 'add') {
            Customer = true;
            controlType = "C";
        } else {
            Customer = !!this.props.data.IsCustomer;
            controlType = this.props.data.ControlType;
        }
        this.state = {
            loading: true,
            selectedTab: 1,
            NormID: this.props.data.NormID,
            NormNameU: this.props.data.NormNameU,
            ControlType: controlType,
            Disabled: this.props.data.Disabled === 1,
            Description: this.props.data.Description,
            NormType: this.props.data.NormType,
            IsCustomer: Customer,
            IsRegion: this.props.data.IsRegion === 1,
            isSubmit: false,
            events: [],
            gridAddRowKeys: [],
            Disable: 0
        };
        this.dataSourceW18F1041 = [];
        this.timer = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 3000;
        this.logEvent = this.logEvent.bind(this);
        this.onEditingStart = this.logEvent.bind(this, 'EditingStart');
        this.onInitNewRow = this.logEvent.bind(this, 'InitNewRow');
        this.onRowInserting = this.logEvent.bind(this, 'RowInserting');
        this.onRowInserted = this.logEvent.bind(this, 'RowInserted');
        this.onRowUpdating = this.logEvent.bind(this, 'RowUpdating');
        this.onRowUpdated = this.logEvent.bind(this, 'RowUpdated');
        this.onRowRemoving = this.logEvent.bind(this, 'RowRemoving');
        this.onRowRemoved = this.logEvent.bind(this, 'RowRemoved');

        this.clearEvents = this.clearEvents.bind(this);

        this.timer = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 2000;

    };

    logEvent(eventName) {
        this.setState((state) => {
            return {events: [eventName].concat(state.events)};
        });
    }

    clearEvents() {
        this.setState({events: []});
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    componentDidMount() {
        this.loadDataGrid();
        this.setState({loading: true});
        this.timer = setTimeout(() => {
            this.setState({loading: false});
        }, this.timeDeplay);

    }

    loadDataGrid() {
        const normID = document.getElementById("NormID").value;
        let param = {
            "normID": normID
        };
        this.props.w18f1040Actions.loadW18F1041(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data && data.data && data.data.length > 0) {
                this.dataSourceW18F1041 = data.data.map(o => {
                    const cmbPerson = {
                        NormID: o.NormID,
                        ValueID: o.ValueID,
                        Disable: o.Disable,
                        DisplayOrder: o.DisplayOrder,
                        DescriptionU: o.DescriptionU,
                    };
                    return cmbPerson;
                });
                this.NormID = data.data.NormID;
            } else {
                this.dataSourceW18F1041 = [];
            }
        });
    }

    onDisabledChanged(e) {
        this.setState({
            Disabled: e.value
        });
    }

    onCustomerChanged(e) {
        this.setState({
            IsCustomer: e.value
        });
    }

    onIsRegionChanged(e) {
        this.setState({
            IsRegion: e.value
        });
    }


    onHide() {
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    onReloadGrid() {
        if (this.props.onReloadGrid) {
            this.props.onReloadGrid();
        }
    }

    editSelectionChanged(e) {
        e.component.columnOption("ValueID", "allowEditing", false);
    }

    newSelectionChanged(e) {
        e.component.columnOption("ValueID", "allowEditing", true);
    }

    deleteHideChanged(e) {
        e.component.columnOption("allowDeleting", false);
    }

    grid_itemSelectionChanged(e) {
        const normID = document.getElementById("NormID").value;
        const disable = !e.data.Disable ? 0 : 1;
        const paramValue = {
            NormID: normID,
            Disable: disable,
            DescriptionU: e.data.DescriptionU,
            DisplayOrder: e.data.DisplayOrder,
            ValueID: e.data.ValueID,
        };
        this.setState({isSubmit: true});
        this.props.w18f1040Actions.addW18F1041(paramValue, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                this.setState({isSubmit: false});
                Config.popup.show("INFO", message);
                return false;
            }
            if (data && data.code === 200) {
                // this.onReloadGrid();
                this.loadDataGrid();
            }
        });
    }

    grid_itemUpdateChanged(e) {
        const normID = document.getElementById("NormID").value;
        const disable = !e.data.Disable ? 0 : 1;
        const paramValue = {
            NormID: normID,
            Disable: disable,
            DescriptionU: e.key.DescriptionU,
            DisplayOrder: e.key.DisplayOrder,
            ValueID: e.key.ValueID,
        };
        this.props.w18f1040Actions.editW18F1041(paramValue, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                this.setState({isSubmit: false});
                Config.popup.show("INFO", message);
                return false;
            }
            if (data && data.code === 200) {
                this.loadDataGrid();
            }
        });
    }

    onCheckDelete(query, cb) {
        this.props.w18f1040Actions.checkDeleteW18F1041(query, (error, data) => {
            let message = "";
            if (error) {
                message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
            } else if (data) {
                Config.popup.show('INFO', `${Config.lang("CRM_Ma_nay_da_duoc_su_dung_ban_khong_phep_xoa")}`);
            } else {
                cb && cb();
            }
        });
    };

    onDelete = (row) => {
        const valueID = row.data.ValueID;
        const normID = row.data.NormID;
        const query = {
            "valueID": valueID
        };
        this.onCheckDelete(query, () => {
            const param = {
                "ValueID": valueID,
                "NormID": normID,
            };
            this.props.w18f1040Actions.deleteW18F1041(param, (error, data) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                } else {
                    notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                    this.loadDataGrid();
                }
            });
        });
    };

    onSaveW18F1041() {
        const {data} = this.props;
        const normID = document.getElementById("NormID").value;
        const normNameU = document.getElementById("NormNameU").value;
        const controlType = document.getElementById("ControlType").value;
        const description = document.getElementById("Description").value;
        const disabled = this.state.Disabled === true ? '1' : '0';
        const isCustomer = this.state.IsCustomer === true ? '1' : '0';
        const isRegion = this.state.IsRegion === true ? '1' : '0';
        if (data.mode === 'add') {
            if (normID === "" || normID === 'undefined') {
                Config.popup.show('INFO', `${Config.lang("CRM_Ma_loai_khong_duoc_de_trong")}`);
                document.getElementById("NormID").focus();
                return false;
            }
            if (normNameU === "" || normNameU === 'undefined') {
                Config.popup.show('INFO', `${Config.lang("CRM_Ten_loai_khong_duoc_de_trong")}`);
                document.getElementById("NormNameU").focus();
                return false;
            }
            if (controlType === "" || controlType === 'undefined') {
                Config.popup.show('INFO', `${Config.lang("CRM_Kieu_nhap_lieu_khong_duoc_de_trong")}`);
                document.getElementById("ControlType").focus();
                return false;
            } else {
                const param = {
                    "NormID": normID,
                    "NormNameU": normNameU,
                    "ControlType": controlType,
                    "Description": description,
                    "Disabled": disabled,
                    "IsCustomer": isCustomer,
                    "IsRegion": isRegion,
                };
                this.props.w18f1040Actions.addW18F1040(param, (error, data) => {
                    if (error) {
                        let errorCode = error.code || null;
                        let message = "";
                        switch (errorCode) {
                            case "F1040E006":
                                message = Config.lang("CRM_Ma_nay_da_ton_tai");
                                Config.popup.show('INFO', message);
                                break;
                            default:
                                message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                                Config.popup.show('INFO', message);
                                break;
                        }
                        this.setState({isSubmit: false, message: message, loading: false});
                        return false;
                    }
                    if (data && data.code === 200) {
                        // Show message success and close popup
                        this.setState({isSubmit: false});
                        notify(Config.lang("CRM_Luu_thanh_cong"), "success", 200);
                        // Auto close when nothing to do
                        clearTimeout(this.timer);
                        this.timer = setTimeout(() => {
                            this.setState({isSubmit: false});
                            this.onHide();
                            this.onReloadGrid();
                        }, this.timeDeplay);
                    }

                });
            }
        } else if (data.mode === 'edit') {
            const param = {
                "NormID": normID,
                "NormNameU": normNameU,
                "ControlType": controlType,
                "Description": description,
                "Disabled": disabled,
                "IsCustomer": isCustomer,
                "IsRegion": isRegion,
            };
            this.setState({isSubmit: true});
            this.props.w18f1040Actions.editW18F1040(param, (error, data) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    this.setState({isSubmit: false});
                    Config.popup.show("INFO", message);
                    return false;
                }
                if (data && data.code === 200) {
                    // Show message success and close popup
                    this.setState({isSubmit: false});
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                    // Auto close when nothing to do
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        this.setState({isSubmit: false});
                        this.onHide();
                        this.onReloadGrid();
                    }, this.timeDeplay);
                }

            });
        }
    }

    updateGrid(e) {
        const element = e.editorElement;
        element.onkeyup = (arg) => {
            if (e.dataField === "ValueID") {
                arg.target.value = Config.replaceSymbol(arg.target.value);
            }
        }
    }

    render() {
        const {data} = this.props;
        return (
            <div className="page-container">
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W18F1041"} label={Config.profile.UserID}/>}

                <Form horizontal={true} className={'mgt5'}>
                    <FormGroup>
                        <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                            <label className={'control-label'}>{Config.lang("CRM_Ma_loai_thong_tin")}<i
                                className="error">*</i></label>
                        </Col>
                        <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                            <Input id={'NormID'}
                                   onPaste={(e) => {
                                       document.getElementById('NormID').value = Config.replaceSymbol(e.target.value);
                                   }}

                                   onChange={(e) => {
                                       document.getElementById('NormID').value = Config.replaceSymbol(e.target.value);
                                   }}
                                   disabled={data && (data.mode === 'detail' || data.mode === 'edit')}
                                   defaultValue={this.state.NormID}/>
                        </Col>
                        <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                            <label className={'control-label'}>{Config.lang("CRM_Ten_loai_thong_tin")}<i
                                className="error">*</i></label>
                        </Col>
                        <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                            <Input id={'NormNameU'}
                                   disabled={data.mode === 'detail'}
                                   defaultValue={this.state.NormNameU}/>
                        </Col>

                    </FormGroup>

                    <Row className="form-group">
                        <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                            <label className={'control-label'}>{Config.lang("CRM_Kieu_nhap_lieu")}<i
                                className="error">*</i></label>
                        </Col>
                        <Col md={4} sm={4} xl={4} xs={4} lg={4}>
                            <Input type={'select'}
                                   id={'ControlType'}
                                   disabled={data.mode === 'detail' || data.mode === 'edit'}
                                   onChange={(e) => {
                                       this.setState({ControlType: e.target.value});
                                   }}
                                   defaultValue={this.state.ControlType}>
                                <option value="C">{Config.lang("CRM_Chon_gia_tri")}</option>
                                <option value="T">{Config.lang("CRM_Nhap_chuoi")}</option>
                            </Input>
                        </Col>
                        <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                            <CheckBox
                                elementAttr={{
                                    class: "control-label text-bold"
                                }}
                                width={200}
                                text={Config.lang("CRM_Khong_su_dung")}
                                id={"Disabled"}
                                disabled={data.mode === 'detail'}
                                onValueChanged={(e) => this.onDisabledChanged(e)}
                                value={this.state.Disabled}
                            />
                        </Col>
                    </Row>

                    <Row className="form-group">
                        <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                            <label className={'control-label'}>{Config.lang("CRM_Su_dung_tai")}</label>
                        </Col>
                        <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                            <CheckBox
                                elementAttr={{
                                    class: "control-label text-bold"
                                }}
                                width={200}
                                text={Config.lang("CRM_Khach_hang")}
                                id={"IsCustomer"}
                                disabled={data.mode === 'detail'}
                                onValueChanged={(e) => this.onCustomerChanged(e)}
                                value={this.state.IsCustomer}
                            />
                        </Col>
                        <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                            <CheckBox
                                elementAttr={{
                                    class: "control-label text-bold"
                                }}
                                width={200}
                                text={Config.lang("CRM_Dia_ban")}
                                id={"IsRegion"}
                                disabled={data.mode === 'detail'}
                                onValueChanged={(e) => this.onIsRegionChanged(e)}
                                value={this.state.IsRegion}
                            />
                        </Col>
                    </Row>

                    <FormGroup>
                        <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                            <label className={'control-label'}>{Config.lang("CRM_Mo_ta")}</label>
                        </Col>
                        <Col md={10} sm={10} xl={10} xs={10} lg={10}>
                            <Input id={'Description'} type={'textarea'} rows={4}
                                   disabled={data.mode === 'detail'}
                                   defaultValue={this.state.Description}/>
                        </Col>
                    </FormGroup>


                    <FormGroup className="form-group">
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <div className="page-container">
                                {this.dataSourceW18F1041 && <GridContainer
                                    dataSource={this.dataSourceW18F1041}
                                    showColumnLines={true}
                                    allowColumnReordering={true}
                                    showBorders={false}
                                    onEditingStart={(e) => this.editSelectionChanged(e)}
                                    onRowUpdated={(e) => this.grid_itemUpdateChanged(e)}
                                    onRowRemoved={(e) => this.onDelete(e)}
                                    onRowInserted={(e) => this.grid_itemSelectionChanged(e)}
                                    onInitNewRow={(e) => this.newSelectionChanged(e)}
                                    onEditorPrepared={(e) => {
                                        this.updateGrid(e);
                                    }}
                                    editing={{
                                        mode: 'row',
                                        allowAdding: !((data && data.mode === 'detail') || this.state.ControlType === 'T'),
                                        allowDeleting: !((data && data.mode === 'detail') || this.state.ControlType === 'T'),
                                        allowUpdating: !((data && data.mode === 'detail') || this.state.ControlType === 'T'),
                                    }}
                                >
                                    <Paging enabled={false}/>
                                    <Paging defaultPageSize={15}/>
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={[10, 15, 20]}
                                        showInfo={true}/>
                                    <Column dataField={'ValueID'} caption={Config.lang("CRM_Ma")} visible={true}
                                            width={240}
                                            dataType={'string'} fixed={true}>
                                        <RequiredRule message={'Email is reqưeqrwqerwequired'}/>
                                    </Column>
                                    <Column dataField={'DescriptionU'} caption={Config.lang("CRM_Ten")} minWidth={300}
                                            width={"100%"}/>
                                    <Column dataField={'DisplayOrder'} caption={Config.lang("CRM_Thu_tu_hien_thi")}
                                            width={200}
                                            dataType={'string'}/>
                                    <Column dataField={'Disable'} caption={Config.lang("CRM_Khong_su_dung")} width={160}
                                            dataType={'boolean'}
                                            filterType={!!this.state.Disable}/>
                                </GridContainer>}
                            </div>
                        </Col>
                    </FormGroup>

                    <FormGroup className={"jus-end"}>
                        <div>
                            <ButtonSave disabled={data && data.mode === 'detail'}
                                        onClick={() => this.onSaveW18F1041()} className={"mgr5"}
                                        name={Config.lang("CRM_Luu")}/>
                            <ButtonNotSave className={'mgr20'} disabled={data && data.mode === 'detail'}
                                           name={Config.lang("CRM_Khong_luu")}
                                           onClick={() => {
                                               this.props.onHide()
                                           }}/>
                        </div>
                    </FormGroup>
                </Form>
            </div>
        )
    }
}

W18F1041Page.propTypes = {

    onHide: PropTypes.func,
    onReloadGrid: PropTypes.func
};


export default connect(state => ({
        loadW18F1041: state.w18f1040.loadW18F1041,
        editW18F1041: state.w18f1040.editW18F1041,
        addW18F1041: state.w18f1040.addW18F1041,
        deleteW18F1041: state.w18f1040.deleteW18F1041,
        checkDeleteW18F1041: state.w18f1040.checkDeleteW18F1041,
    }),
    (dispatch) => ({
        w18f1040Actions: bindActionCreators(w18f1040Actions, dispatch)
    })
)(W18F1041Page);