import React, {Fragment} from "react";
import GridContainer from "../common/grid-container/grid-container";
import {Col, FormGroup, Input, Row} from "reactstrap"
import {DateBox, Validator} from "devextreme-react"
import {Column} from "devextreme-react/data-grid";
import Config from "../../config";
import {connect} from "react-redux";
import * as w18f1060actions from "../../actions/w18f1060-actions"
import {bindActionCreators} from "redux";
import DropDownSalePerson from "../common/dropdown/template/dropdown-sale-person";
import GridActionBar from "../common/action-bar/grid-action-bar";
import DropDownTypeTime from "../common/dropdown/template/dropdown-type-time";
import moment from "moment";
import {browserHistory} from "react-router";
import notify from "devextreme/ui/notify";
import ButtonAdd from "../common/button/button-add";
import Select from "react-select";
import ButtonExport from "../common/button/button-export";
import {CustomRule} from "devextreme-react/validator";
import * as userActions from "../../actions/user-actions";
import {Collapse} from "react-bootstrap";
import MultipleSwitch from '../common/button/button-switch'

class W18F1060 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: null,
            showFilter: false,
            getList: "",
            getComboStatus: "",
            getComboTime: "",
            getCaseType: "",
            taskDateFrom: null,
            taskDateTo: null,
            iPermission: 0,
            reportTypeID: 'W18F1061',
            caseID: "",
            typeID: "LoadGrid",
        };
        this.filter = {
            limit: 10,
            skip: 0,
            keySearch: "",
            dateFrom: null,
            dateTo: null,
            companyID: "",
            companyName: "",
            salesPersonID: "",
            handlerID: "",
            caseTypeID: "",
            mediaID: "",
            caseStatus: ""
        }
    }

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1860", (iPer) => {
            this.setState({iPermission: iPer});
        });
    };

    loadGetComboStatus = () => {
        const param = {};
        this.props.w18f1060action.getComboStatus(param, (error) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    };

    loadGetComboFeedback = () => {
        const param = {};
        this.props.w18f1060action.getComboFeedback(param, (error) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    };

    loadGetCaseType = () => {
        const param = {};
        this.props.w18f1060action.getCaseType(param, (error) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        this.loadGetComboStatus();
        this.loadGetComboFeedback();
        this.loadGetCaseType();
    };

    onShowFilter = () => {
        // if (condition === "hide") {
        //     this.setState({
        //         filter: "show"
        //     });
        // } else {
        //     this.setState({
        //         filter: "hide",
        //         taskDateFrom: null,
        //         taskDateTo: null
        //     }, () => {
        //         this.loadGetList();
        //     });
        // }

        this.filter.companyID = "";
        this.filter.salesPersonID = "";
        this.filter.handlerID = "";
        this.filter.caseTypeID = "";
        this.filter.mediaID = "";
        this.filter.caseStatus = "";
        this.filter.dateFrom = "";
        this.filter.dateTo = "";
        const {showFilter} = this.state;
        this.setState({
            showFilter: !showFilter
        })
    };

    deleteRow = (data) => {

        let param = {
            caseID: data.CaseID
        };
        if (data.CaseStatus === "FINISHED") {
            Config.popup.show("INFO", Config.lang("CRM_Du_lieu_da_duoc_su_dung"))
        } else {
            Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
                this.props.w18f1060action.deleteRowData(param, async (error) => {
                    if (error) {
                        return false;
                    } else {
                        const historyStatus = await this.saveHistory(data.CaseID);
                        if (historyStatus !== 200) {
                            Config.popup.show("INFO", Config.lang('CRM_Co_loi_khi_luu'));
                            return false;
                        }
                        notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 400);
                        this.loadGrid();
                    }
                })
            })
        }
    };

    avatarUrl = (rowData) => {
        const userImage = rowData.data.URL ? rowData.data.URL : require('../../images/icon-user-default.png');
        return (
            <div>
                <img className="img-circle mgr5" height={50} width={50} src={userImage} alt=""/>
                {rowData.data.SalesPersonNameU}
            </div>

        )
    };

    actionRender = (e) => {
        if (!e) return null;
        const {iPermission} = this.state;
        return (
            <GridActionBar
                isPer={iPermission}
                tooltipEdit={Config.lang('CRM_Sua')}
                onEditAction={() => {
                    this.onEdit(e.row.data);
                }}
                tooltipDelete={Config.lang('CRM_Xoa')}
                onDeleteAction={() => {
                    this.deleteRow(e.row.data)
                }}
            />
        )
    };

    companyRender = (e) => {
        if (!e) return null;
        /* eslint-disable */
        return (
            <a title={e.value} onClick={() => {
                this.showW18F1012(e.data)
            }}>
                {e.value}
            </a>
        )
        /* eslint-enable */
    };

    caseNameRender = (e) => {
        if (!e) return null;
        const data = e.data;
        /* eslint-disable */
        return (
            <a title={e.value} onClick={() => {
                this.onView(data)
            }}>
                {e.value}
            </a>
        )
        /* eslint-enable */
    };

    loadGrid = () => {
        const refF = this.refs['txtDateFromW18F1060'] ? this.refs['txtDateFromW18F1060'].instance.option('isValid') : true;
        const refT = this.refs['txtDateFromW18F1060'] ? this.refs['txtDateToW18F1060'].instance.option('isValid') : true;
        if (refF !== true || refT !== true) {
            return false;
        }
        const param = {
            UserID: Config.profile.UserID,
            ReportID: this.state.reportID,
            Language: Config.language || "84",
            StrSearch: this.filter.keySearch,
            CompanyID: this.filter.companyID,
            CompanyNameU: this.filter.companyName,
            SalesPersonID: this.filter.salesPersonID,
            HandlerID: this.filter.handlerID,
            CaseTypeID: this.filter.caseTypeID,
            CaseStatus: this.filter.caseStatus,
            MediaID: this.filter.mediaID,
            DateFrom: this.state.taskDateFrom,
            DateTo: this.state.taskDateTo,
            ReportTypeID: "",
            CaseID: this.state.caseID,
            TypeID: this.state.typeID,
            limit: this.filter.limit,
            skip: this.filter.skip,
        };
        this.props.w18f1060action.getListNew(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "F1000E004":
                        message = Config.lang("CRM_Ngay_tu/Ngay_den_bat_buoc_nhap");
                        Config.popup.show('INFO', message);
                        break;
                    case "F1000E005":
                        message = Config.lang("CRM_Ngay_den_phai_lon_hon_ngay_tu");
                        Config.popup.show('INFO', message);
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                return false;
            } else {
                let result = data.rows;
                let total = data.total;
                this.setState({
                    getList: result,
                    total: total
                })
            }
        });
    };

    saveHistory = async (CaseID) => {
        let el = {};
        let data = [];
        let status = '';
        el.codeID = CaseID;
        el.formID = 'W18F1060';
        el.linkedTrans = '';
        el.oldValue = CaseID;
        el.newValue = '';
        el.description84 = Config.lang("Danh_sach_tinh_huong", 'vi');
        el.description01 = Config.lang("Danh_sach_tinh_huong", 'en');
        el.action = 2;
        data.push(el);
        const param = {
            attributes: JSON.stringify(data)
        };
        await this.props.w18f1060action.addHistory(param, (error) => {
            status = error ? 400 : 200
        });
        return status
    };

    //Search
    onSearch = () => {
        const param = {
            UserID: Config.profile.UserID,
            ReportID: this.state.reportID,
            Language: Config.language || "84",
            StrSearch: this.filter.keySearch,
            CompanyID: this.filter.companyID,
            CompanyNameU: this.filter.companyName,
            SalesPersonID: this.filter.salesPersonID,
            HandlerID: this.filter.handlerID,
            CaseTypeID: this.filter.caseTypeID,
            CaseStatus: this.filter.caseStatus,
            MediaID: this.filter.mediaID,
            DateFrom: this.state.taskDateFrom,
            DateTo: this.state.taskDateTo,
            ReportTypeID: "",
            CaseID: this.state.caseID,
            TypeID: this.state.typeID,
            limit: this.filter.limit,
            skip: this.filter.skip,
        };
        this.props.w18f1060action.getListNew(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            } else {
                let result = data.rows;
                let total = data.total;
                this.setState({
                    getList: result,
                    total: total
                })
            }
        });
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    };

    onChangePerPage = (per) => {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    };

    //Start Filter
    onChangeTime = (data) => {
        if (!data) return false;
        const {DateFrom, DateTo} = data;
        let dateF = DateFrom ? moment(DateFrom).format('YYYY-MM-DD') : null;
        let dateT = DateTo ? moment(DateTo).format('YYYY-MM-DD') : null;
        this.setState({
            taskDateFrom: dateF,
            taskDateTo: dateT,
        }, () => {
            this.filter.skip = 0;
            this.filter.dateFrom = dateF;
            this.filter.dateTo = dateT;
            this.loadGrid()
        });

    };

    onCaseTypeChange = (e) => {
        this.filter.skip = 0;
        this.filter.caseTypeID = e ? e.value : '';
        this.loadGrid();
    };

    onFeedbackChange = (e) => {
        this.filter.skip = 0;
        this.filter.mediaID = e ? e.value : '';
        this.loadGrid();
    };

    onCustomerChange = (keyWord) => {
        if (this.timer) {
            clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
            this.filter.skip = 0;
            this.filter.companyID = keyWord;
            this.filter.companyName = keyWord;
            this.loadGrid()
        }, 700)
    };

    onSearchChange = (keyWord) => {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.filter.skip = 0;
            this.filter.keySearch = keyWord;
            this.onSearch();
        }, 700);
    };

    onSalesPersonIDChange = (e) => {
        if (e === null) {
            this.filter.skip = 0;
            this.filter.salesPersonID = "";
            this.loadGrid();
        } else {
            this.filter.skip = 0;
            this.filter.salesPersonID = e.SalesPersonID;
            this.loadGrid();
        }
    };

    onHandlerIDChange = (e) => {
        if (e === null) {
            this.filter.skip = 0;
            this.filter.handlerID = "";
            this.loadGrid();
        } else {
            this.filter.skip = 0;
            this.filter.handlerID = e.SalesPersonID;
            this.loadGrid();
        }
    };

    onStatusChange = (e) => {
        this.filter.skip = 0;
        this.filter.caseStatus = e ? e.value : '';
        this.loadGrid();
    };

    onDateFromChange = (e) => {
        this.filter.skip = 0;
        this.filter.dateForm = e.value;
        this.setState({
            taskDateFrom: e.value
        });
        this.loadGrid();
    };

    onDateToChange = (e) => {
        this.filter.skip = 0;
        this.filter.dateTo = e.value;
        this.setState({
            taskDateTo: e.value
        });
        this.loadGrid();
    };
    //End Filter

    showW18F1012 = (data) => {
        const {IsCustomer, IsPartner, IsCompetitor, Permission, CompanyID} = data;
        let type = 'Partner';
        if (IsCustomer) {
            type = 'Customers';
        } else if (IsCompetitor) {
            type = 'Competitor';
        } else if (IsPartner) {
            type = 'Partner'
        }
        browserHistory.push({
            pathname: Config.getRootPath() + 'W18F1012',
            state: {type: type, Permission: Permission, CompanyID: CompanyID}
        });
    };

    onAddNew = () => {
        browserHistory.push({
            pathname: Config.getRootPath() + 'W18F1061',
            state: {mode: 'add', permission: 'D17F1860'}
        })
    };

    onEdit = (data) => {
        const { CaseCode = "", CaseID = "" } = data;
        browserHistory.push({
            pathname: Config.getRootPath() + 'W18F1061',
            state: {mode: 'edit', CaseCode, CaseID, permission: 'D17F1860'}
        });
    };

    onView = (data) => {
        const { CaseCode = "", CaseID = "" } = data;
        browserHistory.push({
            pathname: Config.getRootPath() + 'W18F1061',
            state: {mode: 'view', CaseCode, CaseID}
        });
    };

    validateData = (e, compareValue, mode) => {
        e.rule.message = '';
        if (!e.value && !compareValue) {
            return true
        } else {
            if (!e.value) {
                e.rule.message = Config.lang('CRM_Khong_duoc_de_trong');
                return false;
            }
            if (mode === 1) {
                e.rule.message = Config.lang('CRM_Khong_duoc_lon_hon_ngay_den');
                return e.value <= compareValue;
            } else {
                e.rule.message = Config.lang('CRM_Khong_duoc_nho_hon_ngay_tu');
                return e.value >= compareValue;
            }

        }
    };
    onSwitch = (e) => {
        const value = e ? 'W18F1060' : 'W18F1061';
        this.setState({
            reportTypeID: value
        })
    };

    render() {
        const {getList, iPermission, showFilter, total, reportTypeID} = this.state;
        if (iPermission <= 0) return null;
        let {cbStatus, cbFeedback, cbCaseType} = this.props;
        let listStatus = [], listFeedback = [], listCaseType = [];

        cbStatus && cbStatus.map((item) => {
            const Name = 'Name' + Config.language;
            const obj = {label: item[Name], value: item.ID};
            listStatus.push(obj);
            return listStatus;
        });

        cbFeedback && cbFeedback.map((item) => {
            const Name = 'Name' + Config.language;
            const obj = {label: item[Name], value: item.ID};
            listFeedback.push(obj);
            return listFeedback;
        });
        cbCaseType && cbCaseType.map((item) => {
            const obj = {label: item.CaseTypeName, value: item.CaseTypeID};
            listCaseType.push(obj);
            return listCaseType;
        });

        const paramPrint = [
            {type: "Varchar", id: "UserID", value: Config.profile.UserID},
            {type: "Varchar", id: "Language", value: Config.language || '84'},
            {type: "NVarChar", id: "StrSearch", value: this.filter.keySearch},
            {type: "VarChar", id: "CompanyID", value: this.filter.companyID},
            {type: "NVarChar", id: "CompanyNameU", value: this.filter.companyName},
            {type: "VarChar", id: "SalesPersonID", value: this.filter.salesPersonID},
            {type: "VarChar", id: "HandlerID", value: this.filter.handlerID},
            {type: "VarChar", id: "CaseTypeID", value: this.filter.caseTypeID},
            {type: "VarChar", id: "CaseStatus", value: this.filter.caseStatus},
            {type: "VarChar", id: "MediaID", value: this.filter.mediaID},
            {type: "DATETIME", id: "DateFrom", value: this.state.taskDateFrom},
            {type: "DATETIME", id: "DateTo", value: this.state.taskDateTo},
            {type: "VARCHAR", id: "ReportTypeID", value: reportTypeID},
        ];

        return (
            <Fragment>
                <div id={'W18F1060'} className="page-container" style={{margin: 10}}>
                    <div className="grid-title">{Config.lang("CRM_Danh_sach_tinh_huong")}</div>
                    <FormGroup row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                <ButtonAdd name={Config.lang("CRM_Them_moi1")}
                                           className={`${iPermission < 2 ? 'isDisabled' : ''} pull-left mgr5`}
                                           onClick={() => {
                                               if (iPermission >= 2)
                                                   this.onAddNew();
                                           }}/>

                                <Input onKeyUp={(e) => this.onSearchChange(e.target.value)}
                                       style={{width: '50%', marginRight: 5, minWidth: '300px'}}
                                       className={'pull-left'}/>
                                <div className={'mgr5'} style={{marginTop: '0', cursor: 'pointer'}}>
                                    <i style={{
                                        transition: 'transform .5s',
                                        color: `${showFilter ? 'rgb(66, 148, 251)' : ''}`,
                                        transform: `${showFilter ? 'rotate(177deg)' : ''}`
                                    }} onClick={() => this.onShowFilter()}
                                       className={'fa-lg fas fa-chevron-circle-down mgt10'}
                                        // className={showFilter ? " fa-lg fas fa-chevron-circle-up text-red" : "fa-lg fas fa-chevron-circle-down"}
                                    />
                                </div>
                                <MultipleSwitch switchedOnText={Config.lang('CRM_Tong_hop')}
                                                switchedOffText={Config.lang('CRM_Chi_tiet')}
                                                onValueChange={this.onSwitch}
                                />
                                <ButtonExport name={Config.lang('CRM_Xuat_du_lieu')}
                                              transTypeID={'ExportQuotation'}
                                              reportTypeID={reportTypeID}
                                              param={paramPrint}
                                              className={'mgl5'}/>

                            </div>
                        </Col>
                    </FormGroup>
                    <Collapse in={showFilter}>
                        <div>
                            <Row className="page-container" style={{margin: 0}}>
                                <FormGroup row className={'mgb0'}>
                                    <Col xs={3} sm={4} md={4} lg={4} className={'mgb15'}>
                                        <Input placeholder={Config.lang("CRM_Khach_hang")}
                                               onKeyUp={(e) => this.onCustomerChange(e.target.value)}/>
                                    </Col>
                                    <Col xs={3} sm={4} md={4} lg={4} className={'mgb15'}>
                                        <DropDownSalePerson showClearButton={true}
                                                            placeholder={Config.lang("CRM_Nguoi_lap")}
                                                            value={""}
                                                            onChange={(e) => {
                                                                this.onSalesPersonIDChange(e)
                                                            }}
                                        />
                                    </Col>
                                    <Col xs={3} sm={4} md={4} lg={4} className={'mgb15'}>
                                        <DropDownSalePerson showClearButton={true}
                                                            placeholder={Config.lang("CRM_Nguoi_giai_quyet")}
                                                            value={""}
                                                            onChange={(e) => {
                                                                this.onHandlerIDChange(e)
                                                            }}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className={'mgb0'}>
                                    <Col xs={3} sm={4} md={4} lg={4} className={'mgb15'}>
                                        <Select
                                            styles={{
                                                menu: provided => ({ ...provided, zIndex: 101 })
                                            }}
                                            options={listCaseType}
                                            placeholder={Config.lang("CRM_Loai_tinh_huong")}
                                            displayExpr={'label'}
                                            valueExpr={'value'}
                                            onChange={this.onCaseTypeChange}
                                            isMulti={false}
                                            isClearable={true}
                                        />
                                    </Col>
                                    <Col xs={3} sm={4} md={4} lg={4} className={'mgb15'}>
                                        <Select
                                            styles={{
                                                menu: provided => ({ ...provided, zIndex: 101 })
                                            }}
                                            options={listStatus}
                                            placeholder={Config.lang("CRM_Trang_thai")}
                                            displayExpr={'label'}
                                            valueExpr={'value'}
                                            onChange={this.onStatusChange}
                                            isMulti={false}
                                            isClearable={true}
                                        />
                                    </Col>
                                    <Col xs={3} sm={4} md={4} lg={4} className={'mgb15'}>
                                        <Select
                                            styles={{
                                                menu: provided => ({ ...provided, zIndex: 101 })
                                            }}
                                            options={listFeedback}
                                            placeholder={Config.lang("CRM_Kenh_gop_y")}
                                            displayExpr={'label'}
                                            valueExpr={'value'}
                                            onChange={this.onFeedbackChange}
                                            isMulti={false}
                                            isClearable={true}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className={'mgb0'}>
                                    <Col xs={3} sm={4} md={4} lg={4} className={'mgb15'}>
                                        <DropDownTypeTime
                                            listType={"W18F1060_TypeTime"}
                                            placeholder={""}
                                            onChange={(data) => this.onChangeTime(data)}
                                            onLoad={(data) => this.onChangeTime(data)}
                                        >
                                        </DropDownTypeTime>
                                    </Col>
                                    <Col xs={3} sm={4} md={4} lg={4} className={'mgb15'}>
                                        <DateBox
                                            width={"100%"}
                                            ref={'txtDateFromW18F1060'}
                                            dateSerializationFormat={'yyyy-MM-dd'}
                                            openOnFieldClick={true}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            type={'date'}
                                            displayFormat={'dd/MM/y'}
                                            value={this.state.taskDateFrom}
                                            onValueChanged={this.onDateFromChange}
                                        >
                                            <Validator>
                                                <CustomRule
                                                    validationCallback={(e) => this.validateData(e, this.state.taskDateTo, 1)}/>
                                            </Validator>
                                        </DateBox>
                                    </Col>
                                    <Col xs={3} sm={4} md={4} lg={4} className={'mgb15'}>
                                        <DateBox
                                            width={"100%"}
                                            ref={'txtDateToW18F1060'}
                                            dateSerializationFormat={'yyyy-MM-dd'}
                                            openOnFieldClick={true}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            type={'date'}
                                            displayFormat={'dd/MM/y'}
                                            value={this.state.taskDateTo}
                                            onValueChanged={this.onDateToChange}
                                        >
                                            <Validator>
                                                <CustomRule
                                                    validationCallback={(e) => this.validateData(e, this.state.taskDateFrom, 2)}/>
                                            </Validator>
                                        </DateBox>
                                    </Col>
                                </FormGroup>
                            </Row>

                        </div>
                    </Collapse>
                    <GridContainer
                        totalItems={total}
                        itemPerPage={this.filter.limit}
                        skipPerPage={this.filter.skip}
                        dataSource={getList}
                        height={Config.getHeightGrid() - 55}
                        onChangePage={(page) => this.onChangePage(page)}
                        onChangePerPage={(per) => this.onChangePerPage(per)}
                    >
                        <Column
                            cellRender={(data) => this.actionRender(data)}
                            caption={Config.lang("CRM_Hanh_dong")}
                            width={100}
                            fixed={true}
                        />
                        <Column
                            cellRender={(rowData) => this.avatarUrl(rowData)}
                            caption={Config.lang("CRM_Nguoi_lap")}
                            width={'200'}
                        />
                        <Column
                            cellRender={this.caseNameRender}
                            dataField={'CaseName'}
                            caption={Config.lang("CRM_Ten_tinh_huong")}
                        />
                        <Column
                            dataField={'CaseTypeName'}
                            caption={Config.lang("CRM_Loai_tinh_huong")}
                        />
                        <Column
                            cellRender={this.companyRender}
                            dataField={'CompanyNameU'}
                            width={200}
                            caption={Config.lang("CRM_Khach_hang")}
                        />
                        <Column
                            dataField={'CaseDate'}
                            caption={Config.lang("CRM_Ngay_phat_sinh")}
                            dataType={'date'}
                            format={'dd/MM/yyyy'}
                            alignment={'center'}
                        />
                        <Column
                            dataField={'Description'}
                            caption={Config.lang("CRM_Dien_giai")}
                        />
                        <Column
                            dataField={'CaseID'}
                            visible={false}
                        />
                        <Column
                            dataField={'CaseStatus'}
                            visible={false}
                        />
                        <Column
                            dataField={'CaseCode'}
                            caption={Config.lang("CRM_Ma_tinh_huong")}
                        />
                    </GridContainer>
                </div>
            </Fragment>
        )
    }
}

export default connect((state) => ({
    cbStatus: state.w18f1060.getComboStatus,
    cbFeedback: state.w18f1060.getComboFeedback,
    cbCaseType: state.w18f1060.getCaseType
}), (dispatch) => ({
    w18f1060action: bindActionCreators(w18f1060actions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
}))(W18F1060)
